@use "../mixins/mq";

.images {
  --image-width: 100%;

  &__figure {
    --media-grid-column: var(--media-grid-column-small);
    grid-column: var(--media-grid-column, span 6);

    &--auto:nth-child(3),
    &--auto:nth-child(3n + 3) {
      --media-grid-column: var(--media-grid-column-medium);
    }

    &--auto:nth-child(4n + 5) {
      --media-grid-column: var(--media-grid-column-large);
    }

    &--auto:nth-child(4),
    &--auto:nth-child(5n + 5) {
      --media-grid-column: var(--media-grid-column-small);

      @include mq.mq($from: large) {
        --media-grid-column: var(--media-grid-column-large);
      }
    }

    &--half {
      --media-grid-column: var(--media-grid-column-large);
    }

    &--third {
      --media-grid-column: var(--media-grid-column-small);


    }
  }
}