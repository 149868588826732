/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: inherit;
  font-family: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
  text-align: inherit;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, iframe {
  display: block;
}

iframe {
  border: 0;
}

body {
  line-height: 1;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
  font-family: inherit;
  line-height: inherit;
  font-weight: inherit;
}

* {
  box-sizing: border-box;
}

address {
  font-style: normal;
}

select {
  font-family: inherit;
}
/*
select, textarea, input, a, button {
  outline-color: var(--outline-color,currentColor);
  outline-offset: 0.3rem;

}
*/


:focus-visible {
  outline-style: solid;
  outline-color: var(--outline-color,currentColor);
  outline-offset: var(--outline-offset,.5rem);
  outline-width: var(--outline-width,.2rem);
  //box-shadow: 0 0 1rem 0 var(--color-black);
}

img {
  user-select: none;
}

legend {
  display: block;
}



input,
select,
textarea,
button {
  padding: 0;
  margin: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  border: none;
  border-radius: 0;
  background: none;
  
}

select {
  -webkit-appearance: none;
}

select:not([multiple]):not([size]) {
  background-position: right center;
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  background-image: var(--input-select-arrow-bg);
  padding-right: 1.3rem;
  appearance: none;
}


legend {
  display: block;
}

::-webkit-search-cancel-button {
  -webkit-appearance: none;
}
::-webkit-search-results-button {
  -webkit-appearance: none;
}

::-webkit-inner-spin-button, 
::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] {
  -moz-appearance:textfield;
}

dialog {
  border: none;
  background-color: none;
  padding: 0;
  margin: 0;
}

*, *:before, *:after {
  box-sizing: inherit;
}

//not for html
body {
  * {
    scrollbar-width: auto;
    scrollbar-color: var(--scrollbar-color);
  }
  
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: .5rem;
    height: .5rem;
  }
  
  *::-webkit-scrollbar-track {
    background: var(--scrollbar-track);
    border-radius: 5rem;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-thumb);
    border-radius: 5rem;
  }
}

