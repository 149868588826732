.card {

	display: flex;
    flex-direction: column;
	gap: var(--card-gap);
	border-radius: var(--card-border-radius, 1.5rem);
	background-color: var(--card-bg-color);
	padding: var(--card-padding, 2.5rem);
    height: var(--card-height, 100%);
    isolation: isolate;

    align-items: var(--card-align-items, stretch);
    transition: box-shadow var(--animation-duration) var(--animation-ease);

    &__icon {
        --icon-width: var(--card-icon-size);
        --icon-height: var(--card-icon-size);
    }

    &__index {
        color: var(--card-index-color);
        font-family: var(--heading-font-family);
        font-size: var(--font-size-h3);
        line-height: var(--line-height-h3);
        font-weight: var(--heading-font-weight);
        letter-spacing: var(--letter-spacing-h3);
    }

    &__body {

    }
}

