@use "../mixins/mq";

.flow {
  --flow-media-max-width: 19.6rem;
  --flow-icon-width: 9.8rem;
  --flow-media-radius: var(--rounded-s);

  @include mq.mq($from: large) {
    --flow-media-max-width: 32rem;
    --flow-icon-width: 16rem;
    --flow-media-radius: var(--rounded-m);
  }

  &-section {
    --section-inner-spacing: 7rem;

    @include mq.mq($from: medium) {
      --section-inner-spacing: 5rem;
    }

    @include mq.mq($from: large) {
      --section-inner-spacing: 10rem;
    }


  }


  &__content {
    --row-gap: 10rem;
    --border-width: 0.3rem;
    --border-left-position: 17%;

    position: relative;
    display: grid;
    counter-reset: listCounter;
    gap: var(--row-gap);

    @include mq.mq($from: medium) {
      --border-left-position: 18.5%;
    }

    @include mq.mq($from: large) {
      --border-width: 0.5rem;
      --border-left-position: 35.7%;
      --row-gap: 15rem;
    }


  }

  &__figure {
    --figure-position: relative;

  }

  &__image {
    max-width: var(--flow-media-max-width);

    &--icon {
      width: var(--flow-icon-width);

    }
  }



  &__media {
    display: inline-grid !important;

    &--rounded {
      border-radius: var(--flow-media-radius);
    }
  }

  &__item {
    --item-opacity: 0.5;
    --number-font-size: 3rem;
    --number-width: 5rem;
    --number-top-position: 0;
    --number-left-position: 0;
    transition: opacity var(--animation-duration) var(--animation-ease);

    &--active {
      --item-opacity: 1;
    }

    position: relative;
    opacity: var(--item-opacity);

    @include mq.mq($from: medium) {
      --number-font-size: 5rem;
      --number-width: 8rem;
      --number-left-position: 5.5%;
      --number-left-position: 5.5%;
    }

    @include mq.mq($from: large) {
      --number-font-size: 15rem;
      --number-width: 20rem;
      --number-top-position: 50%;
      --number-grid-column-end: span 5;
      --number-margin: auto 0;
      //--number-transform: translateY(-50%);

    }

    &:before {
      text-align: center;
      counter-increment: listCounter;
      content: "0" counter(listCounter);
      font-size: var(--number-font-size);
      font-weight: 700;
      line-height: 1.1;
      transform: var(--number-transform);
      font-family: var(--font-family-secondary);
      color: var(--color-accent-01);
      grid-column-end: var(--number-grid-column-end, span 2);
      width: auto;
      margin: var(--number-margin, 0);
    }
  }


  &__container {
    position: relative;
    padding: var(--container-padding-vertical) 0;
    background: var(--container-bg-color);
    z-index: 1;

    @include mq.mq($from: medium) {
      padding-left: 4rem;
    }

    @include mq.mq($from: large) {
      padding-left: 0;
    }

  }

  &__scrollbar {
    position: absolute;
    left: var(--border-left-position);
    display: block;
    width: var(--border-width);
    height: 100%;
    content: '';
    border-radius: var(--border-width);
    background-color: var(--color-grey-100);
  }

  &__scroll-level {
    position: sticky;
    left: 0;
    top: 50vh;
    display: block;
    width: var(--border-width);
    height: var(--flow-scroll-level-height, 20rem);
    top: calc(calc(var(--flow-scroll-level-height, 20rem) * -.5) + 50vh);
    max-height: 50vh;
    content: '';
    border-radius: var(--border-width);
    background-color: var(--color-accent-01);
    // transition: height var(--animation-duration) var(--animation-ease), top var(--animation-duration) var(--animation-ease);
    //will-change: height;
    z-index: 1;
  }

  &__wrapper {
    z-index: 1;
    overflow-x: clip;
    padding-block: 6.4rem;
    margin-top: 2.5rem;


    .spade {
      --container-width: 97rem;
    }

    @include mq.mq($from: medium) {

      margin-top: var(--section-inner-spacing);
      --spade-inset: 0 0 0 4rem;
    }


    &--decoration {
      @include mq.mq($from: medium) {
        padding-block: 11rem;

      }

      @include mq.mq($from: large) {
        padding-block: 20rem;

      }
    }


  }


}