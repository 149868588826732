.block-style {
    &,
    &-1,
    &-2 {
        --spacing: var(--spacing-h4-top);
        --spacing-text-top: var(--spacing);
        --spacing-text-bottom: var(--spacing);
    
       
        font-size: var(--font-size-h4);
        line-height: var(--line-height-h4);
        letter-spacing: var(--letter-spacing-h4);
        //color: var(--color-accent-01);
    }
   
    &-1,
    &-2  {
        padding: var(--spacing) calc( var(--spacing) / 1.5);
        border-radius: var(--rounded-m);
    }

    &-1 {
        background-color: var(--color-accent-05);
    }
    &-2 {
        background-color: var(--color-accent-06);
    }
   
   
    //padding: 2rem;
}