@use "../mixins/mq";

.boxed {

    --boxed-padding: calc(var(--wrapper-gap) * 2) var(--wrapper-gap);

    border-radius: var(--rounded-m);
    padding: var(--boxed-padding);
    background-color: var(--boxed-background-color, var(--color-grey-100));

    &--style-1 {
        --boxed-background-color: var(--color-accent-05);
    }

    &--style-2 {
        --boxed-background-color: var(--color-accent-06);
    }


    @include mq.mq($from: large) {
        --boxed-padding: var(--wrapper-gap);
        margin-inline: calc( var(--boxed-padding) * -1 );
    }


}