.tooltip {
    display: inline-block;
    vertical-align: middle;
    position: relative;
    line-height: 1;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    z-index: 1;
    aspect-ratio: 1/1;
    color: currentColor;
    cursor: pointer;


    &[aria-expanded="true"]{
        color: var(--link-color-focus);
    }

    &__icon {
        --icon-width: 2rem;
        --icon-height: 2rem;
    }

    &__body {

        --tooltip-body-bg-color: var(--color-accent-01);
        --tooltip-body-color: var(--color-white);

        --link-color: var(--inverted-link-color);
        --link-color-focus: var(--inverted-link-color-focus);

        --tooltip-arrow-right: 50%;
       

        z-index: 1;
        font-size: 1.4rem;
        line-height: 1.5;
        position: relative;
        display: block;
        color: var(--tooltip-body-color);
        background-color: var(--tooltip-body-bg-color);
        //min-width: 10rem;

        border-radius: var(--rounded-s);
        filter: drop-shadow(#{var(--shadow-300)});

        padding: 1rem;

        &:after {
            position: absolute;
            content: "";
            background-color: var(--tooltip-body-bg-color);
            width: 2.3rem;
            aspect-ratio: 1/1;
            right: var(--tooltip-arrow-right);
            left: var(--tooltip-arrow-left);
            bottom: var(--tooltip-arrow-bottom,0);
            top: var(--tooltip-arrow-top, auto);
            border-radius: 0.2rem;
            transform: translateX(50%) scaleY(1.6) rotate(45deg);
            //margin: 0 -2rem;
        }

        &[data-placement$=end] {
            --tooltip-arrow-right: 2rem;
        }
        &[data-placement$=start] {
            --tooltip-arrow-right: auto;
            --tooltip-arrow-left: 0;
        }

  
        &[data-placement^=bottom] {
            --tooltip-arrow-bottom: auto;
            --tooltip-arrow-top: 0;
        }
        /*
        &[data-placement^=left] {
            --tooltip-arrow-right: 5rem;
        }

        &[data-placement^=start] {
            --tooltip-arrow-right: 10rem;
        }
        
 
        &[data-placement^=right] {
            left: 0;
        }
        */

        
    }

}


.tippy-box {
    @extend .tooltip__body;
}