.clamp {
  &__text {
    display: var(--display);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }

  &__toggle {
    font-size: 1.6rem;
    color: var(--color-primary-900);
    text-decoration: underline;
  }
}