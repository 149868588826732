@use "../mixins/mq";
@use "../_config/colors";

.form-simple {
  --form-simple-rounded: var(--rounded-s);
  --form-simple-bg-color: var(--color-primary-700);


  // Saved space for box-shadow bottom
  padding-bottom: var(--space-for-bottom-shadow);
  max-width: var(--form-simple-max-width, none);

  &__content {
    margin: 0 var(--wrapper-gap-negative);

    border-radius: var(--form-simple-rounded);

    background-color: var(--color-white);
    position: relative;

    &:before {
      content: "";
      display: block;
      position: absolute;
      inset: 0;
      border-radius: var(--form-simple-rounded);
      filter: drop-shadow(var(--shadow-300));
      background: #f00;
      z-index: -1;

    }
  }


  &__contact {
    //--contact-box-max-width: 45rem;
    padding: 5rem var(--wrapper-gap);
    border-radius: var(--form-simple-rounded);
    background-color: var(--color-primary-700);
    display: flex;
    flex-direction: column;
    gap: 2.5rem 5rem;
  }

  &__text {
    width: 100%;
    color: var(--color-primary-900);
  }

  &__form {
    padding: 5rem var(--wrapper-gap);

  }

  &__entries {
    display: grid;
    gap: 2.5rem;

  }


  @include mq.mq($from: medium) {
    --form-simple-max-width: 90rem;

    margin-left: auto;

 
    &__content {




      &,
      &:before {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }


    }

    &__contact {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &__entries {

      &--half {
        grid-template-columns: 1fr 1fr;

        &:has(.contact-box--has-symbol) {
          --contact-box-symbol-shift: calc(var(--symbol-size) / 2);
          --contact-box-height: calc(100% - var(--contact-box-symbol-shift));
          --contact-box-padding-top: calc(var(--symbol-size) / 1.5);
        }
      }

    }
  }

  @include mq.mq($from: large) {
    --form-simple-max-width: none;
    --form-simple-rounded: var(--rounded-l);
    display: grid;

    &__content {
      margin: 0 var(--wrapper-gap-negative) 0 auto;
      display: inline-grid;

   
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 100%;
        bottom: 0;
        background: var(--form-simple-bg-color);
        width: 50vw;
        z-index: -1;
      }

      &--has-form {
        --form-simple-bg-color: var(--color-white);
        --form-simple-contact-flex-direction: column;
        background-color: var(--color-primary-700);
        grid-template-columns: 45rem 55rem;
      }

      &--has-form-only {
        grid-template-columns: 75rem;
      }
    }

    &__form {
      border-radius: var(--form-simple-rounded) 0 0 var(--form-simple-rounded);
      background-color: var(--color-white);
    }

    &__text {
      min-width: 25rem;
      max-width: 45rem;
    }

    &__contact {
      --contact-box-min-width: 35rem;
      --contact-box-max-width: 45rem;
      flex-direction: var(--form-simple-contact-flex-direction, row);
      gap: 5rem;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

    }

  }

  @include mq.mq($from: offersMedium) {
    &__content--has-form {
      grid-template-columns: 55rem 75rem;
    }

    &__contact {
      --contact-box-min-width: 44rem;
    }
  }
}