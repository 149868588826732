@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";

.nav {
	display: block;
	--link-text-decoration-active: none;

	&__wrap {
		list-style: none;
		padding: 0;
		margin: 0;


		&--child {
			
		}


	}

	&__item {
		padding: 0;
		margin: 0;
	}

	&__link {

		&--parent {
			//font-size: var(--nav-link-parent-font-size, inherit);
		}
	}

	&__toggle {}

	&__text {}

}