.rounded {
    border-radius: var(--rounded, var(--rounded-s));

    &-s {
        border-radius: var(--rounded-s);
    }

    &-m {
        border-radius: var(--rounded-m);
    }

    &-l {
        border-radius: var(--rounded-l);
    }

}

@container (min-width: 20rem) {
    .rounded {
        --rounded: var(--rounded-m);
    }
}

@container (min-width: 60rem) {
    .rounded {
        --rounded: var(--rounded-l);
    }
}