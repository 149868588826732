@use "../mixins/mq";


.infoboxes {
    display: grid;
    gap: 2rem;
    margin: 0;
    list-style: none;
    padding: 0;

    &__item {
        margin: 0;
        padding: 0;
    }

    @include mq.mq($from: medium) {
        gap: var(--grid-gap);
        grid-template-columns: repeat(2, 1fr);

        &__item {
            &--span {
                grid-row: span 2;
            }
        }
    }
}