@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_config/colors";
@use "../_base/svg";


//group for label and input
.field {
    flex: 1;
    margin-bottom: var(--field-margin-bottom);
    margin-top: var(--field-margin-top);
    color: var(--field-color, currentColor);
    min-width: 0;

    /*
    position: relative;
    &:before {
        content: "";
        display: block;
        width: 100%;
        height: var(--field-margin-top);
        position: absolute;
        background: #1fb7464a;
        bottom: 100%;
    }

    .label {
        background: #9fb7464a;
    }
    */

    &--group {
        //margin-top: 0;
        margin-bottom: 0;
    }

    &--new-segment {
        //margin-bottom: calc( 5rem - var(--field-margin-bottom));
        // margin: 0;

        --field-margin-top: 3rem;
        --field-margin-bottom: -.5rem;

    }

    &__errors,
    &__error {
        list-style: none;
        font-size: var(--field-error-font-size);
        line-height: var(--field-error-line-height);
        letter-spacing: var(--field-error-letter-spacing);
        color: var(--field-error-color, currentColor);
        margin: var(--field-error-margin);
        font-weight: 500;
    }

    &__option-group {
        display: grid;
        gap: 1.5rem;


        &--horizontal {
            grid-template-columns: repeat(auto-fit, minmax(27rem, 1fr));

            &:has(> div:only-child) {
                grid-template-columns: 1fr;
            }
        }

        &--inline {
            grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));

            &:has(> div:only-child) {
                grid-template-columns: 1fr;
            }
        }

        &--grid {
            grid-template-columns: var(--field-option-group-grid-template-columns, 1fr);
        }

    }

    &__option,
    &__group,
    &__wrap {
        position: relative;
    }

    &__option {
        //margin-top: 1.5rem;
        display: flex;
        gap: 1.5rem;
        align-items: center;

        &--hidden {
            display: none;
        }

    }

    &__label {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__tooltip {
        padding-right: 1rem;
        display: grid;
    }

    &__instructions {
        padding: var(--field-instructions-padding);
        color: var(--color-grey-300);
        font-size: var(--field-instructions-font-size, 1.4rem);
        line-height: var(--field-instructions-line-height, 1.5);
        font-weight: var(--field-instructions-font-weight, 500);

        &--no-indent {
            padding: 0;
            margin-bottom: 1.6rem;

        }
    }


    &__append {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 7rem;
        right: 0;
        display: grid;
        align-items: center;
        border-radius: var(--input-border-radius);
    }

    &--hidden {
        display: none;
    }

    &__icon {
        position: absolute;
        top: 0;
        left: 1.5rem;
        align-items: center;
        justify-content: center;
        bottom: 0;
        display: grid;
        width: 1.6rem;

        ~.input {
            padding-left: 4.5rem;
        }
    }

    &__option {
        /*
        display: grid;
        gap: 1.5rem;
        grid-template-columns: 3.5rem auto;
        position: relative;
        isolation: isolate;
        */
    }

    &[data-field-handle="prefix"] {
        width: calc(50% - 1.5rem);
    }

    &[data-field-handle="day"],
    &[data-field-handle="month"],
    &[data-field-handle="year"] {
        --input-text-align: center;
    }

    &__upload-helper {
        margin: 1rem 0;
        padding: var(--label-padding);
        list-style: none;
        font-size: var(--subtitle-small-font-size);
        line-height: var(--subtitle-small-line-height);
        letter-spacing: var(--subtitle-small-letter-spacing);
        display: flex;
        gap: 0.5rem 1.2rem;
        flex-wrap: wrap;

        &:empty {
            display: none;
        }

        li {
            position: static;
            margin: 0;
            padding: .5rem 1rem;
            background-color: var(--color-grey-100-transparent);
            border-radius: var(--rounded-xs);
        }
    }

    &__upload-helper-box {
        position: relative;
    }


    &--half {
        display: grid;
        gap: 1.5rem;
        grid-template-columns: var(--field-half);
    }

    @include mq.mq($from: medium) {
        --field-half: 1fr 1fr;
        --field-option-group-grid-template-columns: var(--field-half);
    }



}


.input {
    --outline-offset: .3rem;

    // Resets
    font-family: inherit;
    font-size: var(--input-font-size);
    line-height: var(--input-line-height);
    margin: 0;
    appearance: none;
    color: var(--input-color, currentColor);
    width: 100%;
    height: var(--input-height);
    display: block;
    padding-inline: var(--input-padding-inline);
    padding-block: var(--input-padding-block);
    caret-color: var(--input-caret-color, auto);
    border: 1px var(--input-border-style, solid) var(--input-border-color);
    border-width: var(--input-border-width, 1rem);
    border-radius: var(--input-border-radius);
    background-color: var(--input-background-color);
    background-clip: padding-box;
    transition: var(--input-transition);
    text-align: var(--input-text-align, inherit);

    &:focus-visible {
        --input-border-color: var(--input-focus-border-color);
    }

    &[disabled] {
        --input-border-color: var(--color-disabled);
        --input-background-color: var(--color-grey-100);
        --input-file-button-bg-color: var(--color-disabled);
    }

    &--freeze {
        user-select: none;
        pointer-events: none;
    }

    /*
    &[type="number"] {
        text-align: center;
    }
*/


    &--big {
        height: var(--input-big-height, 8rem);
        font-size: var(--input-big-font-size, inherit);
        line-height: var(--input-big-line-height, inherit);
        padding-block: var(--input-big-padding-block, 2.4rem);
        padding-inline: var(--input-big-padding-inline, 2.2rem);
    }

    &--search {
        --input-big-padding-inline: 2.2rem 5.5rem;
    }

    &--file {
        --input-file-button-bg-color: var(--color-primary-900);
        --input-padding-block: 1.1rem;
        overflow: hidden;
        cursor: pointer;

        &::file-selector-button {
            color: var(--color-white);

            margin-left: calc(var(--input-padding-inline) * -1);
            margin-right: 1.8rem;
            padding-inline: var(--input-padding-inline);
            isolation: isolate;
            border: none;
            appearance: none;
            box-sizing: content-box;
            text-align: center;
            font-size: var(--input-font-size);
            background-color: var(--input-file-button-bg-color);
            box-shadow: 0 -1.8rem 0 var(--input-file-button-bg-color), 0 1.8rem 0 var(--input-file-button-bg-color);
            transform: translateY(-.1rem);
            border-radius: 0;
            transition: background-color var(--animation-duration) var(--animation-ease), box-shadow var(--animation-duration) var(--animation-ease);

            &:hover {
                --input-file-button-bg-color: var(--color-tertiary-900);
            }

            &:active {
                --input-file-button-bg-color: var(--color-accent-01);
            }

        }

        &::-webkit-textfield-decoration-container {
            display: none;
        }
    }

    &--drop-zone {
        --input-height: 10rem;

        --input-border-style: dashed;
        --input-border-color: var(--color-grey-300);
        --input-padding-inline: 0;
        --input-padding-block: 0;
        --input-color: var(--color-grey-300);
        --input-background-color: var(--color-grey-100-transparent);


        line-height: var(--input-height);
        transition: background-color var(--animation-duration) var(--animation-ease);


        &:hover {
            --input-border-color: var(--color-grey-400);
            --input-background-color: var(--color-grey-100);
        }

        &::file-selector-button {

            color: currentColor;
            margin: 0;
            padding: 0;
            isolation: isolate;
            border: none;
            appearance: none;

            text-align: center;
            font-size: var(--input-font-size);
            background: none;
            width: 100%;
        }

        &::-webkit-textfield-decoration-container {
            display: none;
        }
    }

    &--center {
        text-align: center;
    }


    /* don't hide it, needed for screen reader */
    &--radio,
    &--checkbox {
        --input-border-radius: .1rem;

        flex-shrink: 0;
        margin: 0 0 auto;
        padding: 0;
        background: none;
        appearance: none;
        cursor: pointer;
        display: block;
        position: relative;
        width: var(--check-size);
        height: var(--check-size);
        border: var(--check-color) var(--check-border-width) solid;
        color: var(--check-color);
        background-color: var(--check-bg-color, currentColor);
        background-image: var(--check-bg-image, none);
        transition: var(--check-transition);
        border-radius: .2rem;
        background-size: 100% 100%;
        background-repeat: no-repeat;

        &[type=radio] {
            border-radius: 50%;
            background-image: none;
            background-image: var(--check-radio-bg-image, none);
        }

        &:checked {
            --check-color: var(--check-checked-color);
            --check-bg-color: var(--check-checked-bg-color);
        }

        &[disabled],
        &:disabled {
            --check-color: var(--color-disabled);
            --check-bg-color: var(--color-disabled);
        }



        &:has(+ .label--topic-option) {

            background: none;
            border-color: transparent;
            position: absolute;
            right: 1.6rem;
            bottom: 50%;
            transform: translateY(50%);
            cursor: pointer;
        }


        &:has(+ .product-selection) {
            z-index: 1;
            background: none;
            border-color: transparent;
            z-index: 1;
            position: absolute;
            right: 2.6rem;
            bottom: 1.6rem;
            cursor: pointer;
        }



    }

    &--radio {
        border-radius: 50%;
    }

    &--textarea {
        height: auto;
        min-height: unset;
        resize: vertical;

        /*
        background-image: var(--input-textarea-resize-handle);
        background-repeat: no-repeat;
        background-position: bottom .7rem right .7rem;
        background-size: 1rem;
        */
    }


    &--dropdown,
    &--select {
        //icon

        &:not([multiple]):not([size]) {
            padding-right: var(--input-select-padding-right);
            background-position: var(--input-select-arrow-bg-position, right center);
            background-repeat: no-repeat;
            background-size: 1.6rem 1.6rem;
            background-image: var(--input-select-arrow-bg);
        }

        &[size],
        &[multiple] {
            height: auto;
        }


    }

    &--valid,
    &--input-aside-icon,
    &--date-picker,
    &--time {
        padding-right: var(--input-aside-icon-padding-right);
        background-position: var(--input-aside-icon-arrow-bg-position);
        background-repeat: no-repeat;
        background-size: var(--input-aside-icon-bg-bg-size);
        background-image: var(--input-aside-icon-bg-image, none);
    }

    &--date-picker {
        --input-aside-icon-bg-image: var(--input-aside-date-picker);
    }

    &--time {
        --input-aside-icon-bg-image: var(--input-aside-time-picker);
    }

    &--valid {
        --input-aside-icon-bg-image: var(--check-list);
    }


    &::-webkit-calendar-picker-indicator {
        background-size: contain;
        background: var(--input-aside-time-picker);
    }


}


.label {
    font-size: var(--label-font-size);
    line-height: var(--label-line-height);
    letter-spacing: var(--label-letter-spacing);
    color: var(--label-color, currentColor);
    padding: var(--label-padding);
    font-weight: var(--label-font-weight, 500);
    display: inline-block;

    &--legend {
        margin-block: 2rem 2rem;
        padding: var(--label-legend-padding);
        font-size: var(--label-legend-font-size);
        line-height: var(--label-legend-line-height);
        letter-spacing: var(--label-legend-letter-spacing);
        font-weight: var(--label-legend-font-weight);
        font-family: var(--label-legend-family);
    }

    &--sub-header {
        --label-font-size: var(--font-size-h5);
        --label-line-height: var(--line-height-h5);
        --label-color: var(--color-primary-900);
        //--label-padding: 0;
        --label-font-weight: bold;

    }

    /* default option */
    &--option {
        display: block;
        flex: 1;
        padding: 0;

        //padding-left: var(--label-option-padding-left);
        font-size: var(--label-option-font-size, inherit);

        min-height: var(--check-size);
        line-height: inherit;
        user-select: none;
        cursor: pointer;
        padding-top: .4rem;

    }

    /* boxed topic option */
    &--topic-option {
        min-height: 8.2rem;
        padding: 1.5rem;
        flex: 1;
        align-items: center;
        display: grid;
        grid-template-columns: 1fr var(--check-size);
        background-color: var(--label-topic-option-bg-color);
        border-color: var(--label-topic-option-border-color, transform);
        border-width: .1rem;
        border-style: solid;
        color: var(--label-topic-option-color, currentColor);
        margin: 0;
        cursor: var(--label-topic-option-cursor, pointer);
        gap: 1.5rem;
        border-radius: var(--rounded-s);
        transition: background-color var(--animation-duration) var(--animation-ease), color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease);
        height: 100%;

        //selected

        :checked+& {
            --label-topic-option-color: var(--color-white);
            --label-topic-option-bg-color: var(--color-accent-01);
            --label-topic-option-border-color: var(--color-primary-900);

            --check-bg-image: var(--check-topic-bg-image);
            --check-radio-bg-image: var(--check-topic-radio-bg-image);

            --check-topic-active-bg-image: var(--check-topic-bg-image);
            --check-topic-active-radio-bg-image: var(--check-topic-radio-bg-image);

        }

        //hover selected
        //hover unselected
        &.hover,
        &:hover {
            --label-topic-option-bg-color: var(--color-tertiary-900);
            --label-topic-option-border-color: var(--color-tertiary-900);
            --label-topic-option-color: var(--color-white);
        }

        //disabled unselected
        [disabled]+&,
        :disabled+& {
            --label-topic-option-cursor: auto;
            --label-topic-option-color: var(--color-accent-03);
            --label-topic-option-border-color: currentColor;
            --label-topic-option-bg-color: var(--color-white);
        }


        //selected
        :checked[disabled]+&,
        :checked:disabled+& {
            --label-topic-option-color: var(--color-white);
            --label-topic-option-bg-color: var(--color-disabled);

        }

        &::after {
            content: '';
            cursor: pointer;
            display: block;
            width: var(--check-topic-size);
            height: var(--check-topic-size);
            border: var(--check-topic-color) var(--check-border-width) solid;
            color: var(--check-topic-color);
            background-image: var(--check-topic-active-bg-image, none);
            transition: var(--check-transition);
            border-radius: .2rem;
            background-size: 100% 100%;
            background-repeat: no-repeat;

            [type=radio]+& {
                border-radius: 50%;
                background-image: none;
                background-image: var(--check-topic-active-radio-bg-image, none);
            }

        }

    }

}



/* error */

.error,
.fui-error,
.form--client-side-validation :user-invalid {
    //--label-color: var(--color-error);
    --input-color: var(--color-error);
    --input-select-arrow-bg: var(--input-select-arrow-error);
    --input-border-color: var(--color-error);
    --input-background-color: var(--color-error-transparent);
    --input-focus-border-color: var(--color-error);
    --input-caret-color: auto;
    --check-color: var(--color-error);
    --check-topic-color: var(--color-error);
    --label-topic-option-border-color: var(--color-error);
}



//look like  topic-option
.field-box {
    padding: .5rem;
    display: grid;
    gap: .5rem;
    background-color: var(--label-topic-option-bg-color);
    border-color: var(--label-topic-option-border-color, transform);
    border-width: 0.1rem;
    border-style: solid;
    font-weight: 500;
    margin: 0;
    border-radius: var(--rounded-s);
    transition: background-color var(--animation-duration) var(--animation-ease), color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease);
    height: 100%;



    &__body {
        padding: 0 3rem 3rem;
    }

    &__preview {
        border-radius: var(--rounded-s);
        max-width: 10rem;
        width: 100%;
    }


    &__label {
        color: var(--color-primary-900);
    }

    @include mq.mq($from: medium) {
        grid-template-columns: auto 1fr;

        &__body {
            padding: 1.5rem 3rem;
            gap: 3rem;
            display: grid;
            align-items: center;
            grid-template-columns: 1fr 17rem;
        }

        &__preview {

            max-width: 17rem;
        }
    }

}

.drop-zone-reset {
    font-size: var(--subtitle-small-font-size);
    line-height: var(--subtitle-small-line-height);
    letter-spacing: var(--subtitle-small-letter-spacing);
    color: var(--color-grey-300);
    position: absolute;
    position: absolute;
    top: -4rem;
    right: 2rem;
}


[data-conditionally-hidden],
[data-fui-page-hidden],
[data-conditionally-hidden] {
    display: none;
}
