.media {
  &-section {
    --section-spacing: var(--media-spacing-top);

    &--large {
      --section-spacing-bottom: var(--section-spacing-m);
    }
  }

  &--lightbox {
    --image-width: 100%;
  }

  &-grid {
    --img-border-radius: var(--rounded-s);

    display: grid;
    gap: var(--media-grid, 2.5rem);
    grid-template-columns: repeat(6, 1fr);
  }
}