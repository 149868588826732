@use "../mixins/mq";

.chart {
    max-width: var(--chart-max-width);

    &--column {
        margin-inline: auto;
    }

    &-grid {
        display: grid;
        gap: 10rem var(--grid-gap);
    } 

    &__image {
        width: 100%;
    }

    &__picture {
        display: block;
        width: auto;
        max-width: 100%;
        margin-inline: auto;
    }

    &__figure {
        display: grid;
        gap: 5rem 10%;

    }

    &__list {
        font-size: var(--subtitle-font-size);
        line-height: var(--subtitle-line-height);
        letter-spacing: var(--subtitle-letter-spacing);
        margin: 0;
        padding: 0;
        list-style: none;
        font-weight: 500;
    }

    &__item {
        --list-item-margin-top: 0;

        border-bottom: dashed .1rem var(--color-accent-03);
        padding: 1rem 0 1rem 0;


    }

    &__entry {
        &--with-point {
            display: flex;
            align-items: center;
            gap: 1.5rem;
            transition: color var(--animation-duration) var(--animation-ease);

            &::before {
                content: "";
                min-width: 2rem;
                width: 2rem;
                display: block;
                aspect-ratio: 1/1;
                border-radius: 50%;
                background: var(--marker-color, #eee);
                background-position: center center;
            }
        }
    }



    @include mq.mq($from: medium) {

        &--column {
            --chart-max-width: 44rem;
        }

        &-grid {

            &--3,
            &--2 {
                grid-template-columns: 1fr 1fr;
            }
           
    
        } 

        &__figure {

            &--aside {
                grid-template-columns: 1fr 1.1fr;
                align-items: center;
            }


        }


    }

    @include mq.mq($from: large) {
        &-grid {
            &--3 {
                grid-template-columns: 1fr 1fr 1fr;
            }
        } 
    }

}