@use "../mixins/mq";

.infobox {
    --infobox-bg-color: var(--color-accent-06); 
    --link-color: currentColor;
	text-decoration: none;
	color: var(--infobox-color, currentColor);
	border-radius: var(--infobox-border-radius, 1.5rem);
	background-color: var(--infobox-bg-color);
	padding: var(--infobox-padding, 2.5rem);
    height: var(--infobox-height, 100%);
    display: block;
    box-shadow: var(--shadow-000);
    transition: box-shadow var(--animation-duration) var(--animation-ease);


    &--small {
        --infobox-padding: 1.8rem 2rem;
        --infobox-icon-size: 4rem;
    }

    &--link {
        &:hover,
        &:focus {
            box-shadow: var(--shadow-300);
        }
    }

    &__icon {
        --icon-color: var(--color-primary-900);
        --icon-width: var(--infobox-icon-size);
        --icon-height: var(--infobox-icon-size);
    }

    &__header {
        --spacing-top: 1.5rem;
        --spacing-bottom: .5rem;
        color: var(--infobox-header-color, currentColor);
    }

   
    @include mq.mq($from: large) {
        --infobox-padding: 5rem;

        &--small {
            --infobox-padding: 2rem;
        }
    }
}