@use "../mixins/mq";

.charging-station {
  --chargin-station-margion: var(--section-spacing-m);
  margin-top: var(--chargin-station-margion);

  &:first-child {
    --chargin-station-margion: 0;
  }

  &__image {
    border-radius: var(--rounded-m);
  }
}

.charging-station {

  --link-color: var(--color-black);

  @include mq.mq($from: medium) {
    --spacing-text-top: 1.5rem;
  }

  &__price {
    display: block;
    margin-bottom: 0.3rem;
  }

  &__table {
    margin-top: 4rem;
    --spacing-text-top: 0;
    --spacing-text-bottom: 0;

    & + * {
      margin-top: 5rem;
    }

    dt,
    dd {
      @include mq.mq($from: medium) {
        margin-top: 1.5rem;
        margin-bottom: 1.5rem
      }
    }
  }

  &__row {
    display: grid;
    padding: 1.5rem 0;

    border-bottom: 1px solid var(--color-black);

    @include mq.mq($from: medium) {
      grid-template-columns: 19rem 1fr;
      gap: 0 2rem;
      padding: 0;
    }
  }
}