@use "../mixins/mq";

.teaser-mosaik {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: var(--teaser-mosaik-grid-template-columns, 1fr);

    gap: 2.5rem var(--grid-gap);

    &__item {
        margin: 0;
        padding: 0;
        display: block;


         
            &.spaced-before {
                margin-top: 3.8rem;
                &:first-child {
                    margin-top: 0;
                  }
            }
            &.spaced-after {
                margin-bottom: 3.8rem;
                &:last-child {
                    margin-bottom: 0;
                  }
            }

    }


    @include mq.mq($from: medium) {
        --teaser-mosaik-grid-template-columns: repeat( 12, 1fr);

        gap: var(--grid-gap);
   
        &__item {
            grid-column-end: span 6;
            height: 100%;

            &.spaced-before {
                margin: 0;
            }
            &.spaced-after {
                margin: 0;
            }

            &--small {
           
            }

            &--medium {
                grid-column-end: span 4;
            }
            &--large {
                

            }

            //for mix only
            &--mix {
                order: var(--teaser-mosaik-item-order,0);
            }

            &--mix-big {
                grid-row-end: span 2;
                grid-column-end: span 7;
                &.reserved {
                    order: calc( var(--teaser-mosaik-item-order) - 2);
                }
            }

            &--mix-small-end,
            &--mix-small-start {
                height: auto;
                grid-column-end: span 5;
                margin: 0;
            }
            &--mix-small-start {
                align-self: start;
            }
            &--mix-small-end {
                align-self: end;
            }
           
        }


   
    }
}