@use "../mixins/mq";

.lang-chooser {
	--link-color: currentColor;
	--link-text-decoration: none;


	font-size: var(--subtitle-font-size);
	line-height: var(--subtitle-line-height);
	letter-spacing: var(--subtitle-letter-spacing);
	font-weight: 500;

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
		display: flex;
		gap: 2.5rem;
		text-transform: uppercase;
	}

	&__item {
		margin: 0;
		padding: 0;

		&--is-active {
			font-weight: 700;
		}
	}


	&--select {
		font-size: var(--subtitle-font-size);
		line-height: var(--subtitle-line-height);
		font-weight: 700;
		letter-spacing: var(--subtitle-letter-spacing);
	}

}