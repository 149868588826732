@use "../mixins/mq";

.channel-header {
    --channel-header-section-bg-color: var(--channel-accent-color);
    --channel-header-section-top-bg-color: var(--channel-header-section-bg-color);
    --channel-header-section-bottom-bg-color: none;

    --spacing-h2-top: 2.5rem;
    position: relative;

    &--secondary {
        --spade-color: var(--color-secondary-900);
        --channel-accent-color: var(--color-secondary-900);
    }
    //has a spade
    &--decoration {
        --channel-header-section-padding-bottom: 5.3rem;
    }

    &__section {
        padding-left: var(--wrapper-gap);
        padding-right: var(--wrapper-gap);
        padding-block: var(--channel-header-section-padding-block,5rem);

        &--top {
            padding-bottom: var(--channel-header-section-padding-bottom,5rem);
           
        }
        &--bottom {
            background-color: var(--channel-header-section-bottom-bg-color, transparent);
            padding-bottom: var(--channel-header-section-bottom-padding-bottom,0);
        }
    }


    &__section-decoration {
        
        width: 100%;
        height: auto;
        max-height: 25rem;
    }


    &__row {
        display: grid;
        gap: 2.5rem var(--grid-gap);

        max-width: var(--container-skinny-width);
       
        margin: auto;
    }

    &__infos {
        --spacing-text-top: 1rem;

        @include mq.mq($from: large) {
            --spacing-text-top: 1.5rem;
        }
    }

    &__info {
        white-space: nowrap;
    }

    &__breadcrumb {
        min-width: 1px;
    }

    &__figure {
        --img-border-radius: var(--rounded-m);
        --img-bg-color: var(--color-skeleton);
    }

    &__contributor-box {
        width: 100%;
        margin-right: auto;
    }

    &__figure {
        --image-width: 100%;

        max-width: 50rem;
        margin-right: auto;
        width: 100%;
    }

    &__sharing_buttons {
        --share-button-size: 4rem;
   
    }

    &__title {
        --spacing-top: 1rem;

        @include mq.mq($from: large) {
            --spacing-top: 0.5rem;
        }
    }


    @include mq.mq($from: medium) {
        &__row {
        
        }
        &__contributor-box {
         
        }
        &--decoration {
            --channel-header-section-padding-bottom: 12rem;
    
        }
        &__figure {
            --img-border-radius: var(--rounded-l);
    
        }
    
    }

    @include mq.mq($from: large) {

        --channel-header-section-bottom-bg-color: var(--channel-header-section-bg-color);
        --channel-header-section-padding-block: 0;
        --channel-header-section-padding-bottom: 0;
    
        &--decoration {
            --channel-header-section-bottom-bg-color: transparent;
            --channel-header-contributor-box-margin-top: -16rem;
            --channel-header-section-padding-bottom: 15rem;
            --channel-header-section-bottom-padding-bottom: 0;
        }

        &__section {
          
            &--top {
                padding-top: 2rem;
         
            }
            &--bottom {
                padding-bottom: var(--channel-header-section-bottom-padding-bottom,5rem);
           
            }
        }

        &__row {
            max-width: var(--container-width);
            grid-template-rows: none;
            grid-template-columns: repeat(12, 1fr);
        }


        &__breadcrumb {
            grid-column: 3;
            grid-column-end: span 8;
       
        }

        &__text {
            order: 1;
            grid-column: 8;
            grid-column-end: span 5;
            margin-block: auto;
            padding-bottom: 5rem;
        }

        &__figure {
            grid-column-end: span 7;
            max-width: 68.7rem;
         

        }

        &__contributor-box {
            order: 1;
            grid-column: 8;
            grid-column-end: span 5;
            margin-top: var(--channel-header-contributor-box-margin-top);
            max-width: 50rem;
       
        }

        &__sharing_buttons {
            --share-flex-direction: column;
            --share-button-size: 3rem;

            position: absolute;
            transform: translate(-4.5rem, 20rem);
            margin: auto;
            top: 11.5rem;
            z-index: 1;
            height: 0;
            max-width: calc( 100vw - (var(--grid-gap) * 2) );
            width: var(--container-width);   
    
        
        }

    }   

    @include mq.mq($from: xxLarge) {
        &__sharing_buttons {
            transform: translate(-7rem, 20rem);
        }
    }
}