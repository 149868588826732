@use "../mixins/mq";

.teaser {
    --teaser-body-text-color: var(--body-color);
    --teaser-border-radius: var(--rounded-m);
    --teaser-gradient-placeholder-aspect-ratio: 5/4;
    --img-bg-color: var(--color-skeleton);
    --img-border-radius:  var(--rounded-m);
    --teaser-media-width: 100%;
    --teaser-tag-inset: 1.5rem;

    position: relative;
	overflow: var(--teaser-overflow, none);
	text-decoration: none;
	border-radius: var(--teaser-border-radius, none);
	background-color: var(--teaser-bg-color, none);
	padding: var(--teaser-padding, 0);
	min-height: var(--teaser-min-height, none);
    gap: var(--teaser-gap, 1rem);
	z-index: 1;
	flex-shrink: 0;
    display: flex;
    flex-direction: var(--teaser-flex-direction, column);
    margin: var(--teaser-margin, 0);
    height: var(--teaser-height, auto);
    
    &:hover,
    &:focus {
        --teaser-arrow-transform: translateX(.5rem);
        --teaser-media-transform: scale(1.025);
    }

    //styles
    &--secondary {
        --teaser-tag-bg-color: var(--color-secondary-900);
    }

    //sizes
    //&--medium {}
    //medium is default

    &--medium {
        margin: var(--teaser-margin, 0 0 1.5rem);
    }

    &--medium-inline {
        --img-border-radius:  var(--rounded-s);
        --teaser-flex-direction: row;
        //--teaser-body-margin: auto 3rem auto 0;
        --teaser-gap: 1.5rem;
        --teaser-body-margin: auto 0 auto;
        --teaser-footer-link-display: none;
        --teaser-arrow-position: static;
        --teaser-media-width: 11rem;
        --teaser-tag-display: none;
    }

    &--small {
        --img-border-radius:  var(--rounded-s);
        --teaser-flex-direction: row;
        --teaser-gradient-placeholder-aspect-ratio: 1/1;
        --teaser-body-margin: auto 3rem auto 0;
        --teaser-border-bottom: .2rem solid var(--color-grey-100);
        --teaser-media-width: 7.8rem;
        --teaser-header-margin: 0;
 
        &:after {
            content: "";
            display: block;
            border-bottom: var(--teaser-border-bottom, none);
            position: absolute;
            bottom: -1.25rem;
            left: 0;
            right: 0;

        }
    }

    &--large {
        --teaser-body-text-color: currentColor;
        --teaser-bg-color: var(--color-skeleton);
        --teaser-min-height: 46rem;
        --teaser-border-radius: var(--rounded-m);
        --teaser-padding: 3.5rem;
        --teaser-gap: 0;
        --teaser-media-height: 100%;
        --teaser-tag-position: static;
        --teaser-body-margin: auto 0 0;
        --teaser-overflow: hidden;
        --teaser-lead-font-weight: 500;
        --img-position: absolute;
        --img-border-radius: var(--teaser-border-radius);
       
        //pull to the edge
        --teaser-margin: 0 -.9rem;

        color: var(--color-white);

        &:after {
            border-radius: var(--teaser-border-radius, none);
            content: "";
            display: block;
            position: absolute;
            inset: 0;

            //border-radius: 20px;
            background-color: rgba(0, 0, 0, 0.1);
            background-image: linear-gradient(179deg, transparent, rgba(0, 0, 0, .8));

          //  background: linear-gradient(0, var(--color-black-transparent),  var(--color-black-transparent)), linear-gradient(181deg, #00000000 7.79%, #000 99.14%);
        }
    }

    &__tags {
        position: var(--teaser-tag-position,absolute);
        inset: var(--teaser-tag-inset) auto auto var(--teaser-tag-inset);
        display: flex;
        flex-wrap: wrap;
        margin-right: auto;
        gap: 1rem;
        z-index: 1;
        max-width: calc( var(--teaser-media-width) - calc( var(--teaser-tag-inset) * 2 ));
    }

    &__tag {
        font-size: 1.4rem;
        font-weight: bold;
        display: var(--teaser-tag-display,block);
        line-height: 1.5;
        letter-spacing: .025rem;
        padding: .4rem 1.2rem;
        border-radius: 1rem;
        background-color: var(--teaser-tag-bg-color, #ccc);
        color: var(--color-black);
    }

    &__figure {
        margin-bottom: auto;
        flex-shrink: 0;
        border-radius: var(--img-border-radius, none);
        overflow: hidden;
        display: block;
        max-width: var(--channel-teasers-max-width);
    }


    &__media {
        will-change: transform;
        transform: var(--teaser-media-transform);
    }


    &__gradient {
        position: absolute;
        z-index: 1;
        inset: 0;
        overflow: hidden;
        transition: transform var(--animation-duration) var(--animation-ease);
        will-change: transform;
        transform: var(--teaser-media-transform);
       
        &--placeholder {
            position: relative;
            width: var(--teaser-media-width, 100%);
            min-width: var(--teaser-media-width, 100%);
            aspect-ratio: var(--teaser-gradient-placeholder-aspect-ratio,1/1);
        }
    }
   

    //Elements

    &__body {
        z-index: 1;
        margin: var(--teaser-body-margin, 0);
    }

    &__media {
        height: var(--teaser-media-height);
        width: var(--teaser-media-width);

    }

    &__sub-header {
        margin-bottom: .5rem;
        font-size: 1.4rem;
        font-weight: 500;
    }
    &__header {
        margin: var(--teaser-header-margin, .5rem 0);
    }

    &__lead {
        color: var(--teaser-body-text-color, currentColor);
        font-weight: var(--teaser-lead-font-weight, inherit);
    }

    &__arrow {
        --icon-width: 1.5rem;
        --icon-height: 1.5rem;

        vertical-align: middle;
        display: var(--teaser-arrow-display,inline);
        line-height: inherit;
        position: var(--teaser-arrow-position, absolute);
        right: 0;
        top: calc(50% - 1rem);

        transform: var(--teaser-arrow-transform, translateX(0) );
     
        
    }


    &__footer-link {
        display: var(--teaser-footer-link-display, block);
    }


    @include mq.mq($from: medium) {
        &--medium-inline {
            --teaser-footer-link-display: block;
            --teaser-gap: 2.5rem;
            --teaser-arrow-display: none;
            --teaser-media-width: 20rem;
            --teaser-tag-display: inline-block;
        }

        &--large {
            --teaser-margin: 0;
        }
        &--small {
            --teaser-height: 100%;
            --teaser-gap: 2.5rem;
        }
    }

    @include mq.mq($from: large) {
        &--small {
            --teaser-media-width: 15rem;
            --teaser-gap: 2.5rem;
            --teaser-arrow-position: static;
            --teaser-body-margin: auto 0;
         
            &:after {
                bottom: -2.5rem;
            }
        }

        &--medium {
            --teaser-gap: 1.5rem;
        }

        &--medium-inline {
            --teaser-media-width: 22rem;
        }
            
        &--large {
            --teaser-border-radius: var(--rounded-l);
            --teaser-height: 100%;
            --teaser-min-height: 52rem;
    

            --teaser-header-margin: 1rem 0 1.5rem;
        }
       
        &__arrow {
            --icon-width: 2rem;
            --icon-height: 2rem;
        }
    }

    @include mq.mq($from: large, $until: xLarge) {
        --font-size-h6: 1.9rem;
    }

    @include mq.mq($from: xLarge) {
        &--medium-inline {
            --teaser-media-width: 24.7rem;
        }
    }
}