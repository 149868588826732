@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_config/colors";
@use "../_base/svg";

.gallery {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;

	&__item {
		margin: 0;
		padding: 0;
	}

	&__link {
		display: block;
		border-radius: var(--rounded-xs);
		overflow: hidden;
		
		&:hover,
		&:focus {
			--gallery-thumbnail-transform: scale(1.05);
		}
	}

	&__thumbnail {
		--img-bg-color: var(--color-skeleton);
		aspect-ratio: 1;
		width: 11rem;
		transform: var(--gallery-thumbnail-transform, scale(1));
		
	}

	@include mq.mq($from: large) {
		gap: 1rem;

		&__link {
			border-radius: var(--rounded-s);
		}
		&__thumbnail {
			width: 19.5rem;
			
		}
	}
}