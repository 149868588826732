//for section with dark background
.inverted {
    --link-color: var(--inverted-link-color);
    --link-color-focus: var(--inverted-link-color-focus);
    //--link-color-focus: currentColor;
    --color-h1: currentColor;
    --check-list: var(--check-list-white);

    --scrollbar-color: var(--color-white);
    --scrollbar-thumb: var(--color-white);
    --scrollbar-track: var(--color-black-transparent);

}

.current-color {
    --link-color: currentColor;
    --link-color-focus: currentColor;
    --color-h1: currentColor;
}