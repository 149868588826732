@use "../mixins/mq";


.nav--meta-nav {
	--link-color: currentColor;
	--link-text-decoration: none;

	font-size: var(--subtitle-small-font-size);
	line-height: var(--subtitle-small-line-height);
	letter-spacing: var(--subtitle-small-letter-spacing);
	font-weight: 700;
	letter-spacing: 0.028rem;

	padding: 0 var(--wrapper-gap) 10rem;

	.is-active {
		--link-color: var(--link-color-focus);
	}

	.nav {
		&__link {}

		&__item {
			display: grid;

			.action {

				margin: .7rem 0  auto auto;
			}
		}

		&__wrap {
			&--parent {
				display: flex;
				gap: 2rem 3.5rem;
				flex-direction: column;
			}
		}
	}


	@include mq.mq($from: large) {

		--link-color-focus: var(--color-primary-900);
		padding: 0;

		.is-active {
			--link-color: var(--color-primary-900);
		}

		.nav {

			&__item {
				display: grid;
				.action {
					
					margin: 0;
				}
			}

			&__wrap {
				&--parent {
					flex-direction: row;
					align-items: center;
				}

			}
		}
	}

}