@use "../mixins/mq";

.result-teaser {
    --icon-width: 2.5rem;
    --icon-height: 2.5rem;

    text-decoration: none;
    border: .1rem solid var(--color-grey-100);
    border-width: .1rem 0;
    padding: 2.1rem .5rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr auto;
    align-items: center;

    &__heading {
        margin: 1rem 0;
    }

    &__text {
        color: var(--body-color);
    }

    &__body {
        min-width: 1px;
    }

	@include mq.mq($from: large) {
        padding: 2.5rem 1.5rem;
    }
}