.search {


   &__result-text {
      margin-bottom: 2rem;
   }

   &__result-list {
    margin: 0;
    padding: 0;
    list-style: none;
   }
   &__result-item {
    //hack to hide double border of child element
    margin: 0 0 -.1rem 0;
    padding: 0;
   }

   
   &__shuffled-links {
      visibility: hidden;

      > :nth-child(n+4) {
         display: none;
      }

      &--done {
         visibility: visible;

      }
   }

}


