@use "../mixins/mq";

.services__grid {
  row-gap: 5rem;
  --grid-align: stretch;

  @include mq.mq($from: medium) {
    grid-auto-rows: 1fr;
  }
}

.service {
  --service-padding: 2.5rem;
  --spacing-h4-bottom: 0.5rem;

  position: relative;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: var(--color-black);
  border-radius: var(--rounded-m);
  overflow: hidden;
  transition: box-shadow var(--animation-duration) var(--animation-ease);

  &:focus,
  &:hover {
    box-shadow: var(--shadow-300);
  }

  @include mq.mq($from: xLarge) {
    --service-padding: 3.5rem;
    flex-direction: row;
    justify-content: flex-end;
    min-height: 40rem;
    border-radius: var(--rounded-l);
  }

  &__image {
    width: 100%;
    border-radius: var(--rounded-m) var(--rounded-m) 0 0;

    @include mq.mq($from: xLarge) {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 24.6rem;
      border-radius: var(--rounded-l) 0 0 var(--rounded-l);
    }
  }


  &__introduction {
    font-size: 1.6rem;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    gap: 2.5rem;
    margin-top: -2.5rem;
    padding: var(--service-padding);
    background-color: var(--color-white);
    border-radius: var(--rounded-m);


    @include mq.mq($from: xLarge) {
      margin-top: 0;
      max-width: calc(100% - 20.5rem);
      border-radius: var(--rounded-l);
    }
  }
}