@use "../mixins/mq";

.portrait {
  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 5rem;
    margin-block: -2.5rem;
    --portrait-item-width: 100%;
    --portrait-item-max-width: 34rem;

    @include mq.mq($from: medium) {
      --portrait-item-width: calc((100% - 10rem) / 2);
    }

    @include mq.mq($from: large) {
      --portrait-item-width: calc((100% - 15rem) / 4);
    }

    &--five {
      @include mq.mq($from: large) {
        --portrait-item-width: calc((100% - 10rem) / 3);
      }
    }
  }

  &__figure {
    width: 100%;
    aspect-ratio: 32/36;
    border-radius: var(--rounded-m);
    // background-color: var(--color-accent-03);

    &--empty {
      background-color: var(--color-accent-03);
    }
  }

  &__item {
    width: var(--portrait-item-width);
    max-width: var(--portrait-item-max-width);
    position: relative;
    margin-block: 2.5rem;

    [aria-expanded="true"] {
      --icon-transform: rotate(-45deg);
    }
  }

  &__content {
    padding-top: 1.5rem;
  }



  &__title {
    font-weight: 600;
  }


  &__section {
    --img-border-radius: var(--rounded-m);
    --animation-duration: .5s;
    --animation-ease: ease-in-out;

    // margin-inline: var(--wrapper-gap-negative);
    overflow: hidden;
    position: relative;

    box-shadow:
      -24.6rem 0px var(--color-accent-06),
      24.6rem 0px var(--color-accent-06);
    background-color: var(--color-accent-06);
    //transform: translateY(0);
    transition:
      opacity var(--animation-duration) var(--animation-ease),
      height var(--animation-duration) var(--animation-ease);

    height: var(--self-height, 0);
    opacity: var(--self-opacity, 0);

  }

  &__container {
    padding-block: 5rem;
    flex: 0 0 100%;

    @include mq.mq($from: medium) {
      padding-block: 7.5rem;
      --portrait-descriptions-grid-template-columns: 4fr 6fr;
      --portrait-descriptions-gap: 5rem 7.5rem;
    }
  }

  &__heading {
    grid-column: 1/-1;
  }

  &__descriptions {
    max-width: 118.2rem;
    display: grid;
    gap: var(--portrait-descriptions-gap, 3.5rem);
    grid-template-columns: var(--portrait-descriptions-grid-template-columns, 1fr);
  }



  &__close-button {
    --icon-width: 2rem;

    color: inherit;
    position: absolute;
    //right: var(--wrapper-gap);
    right: 0;
  }


}


@keyframes show {
  0% {
    opacity: 0;
  }
}

@keyframes hide {
  0% {
    display: block;
  }

  100% {
    // display: block;
    opacity: 0;
    display: none;
  }
}