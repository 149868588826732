@use "../mixins/mq";

.communications {

  &-section {
    --section-inner-spacing: 2.5rem;
    @include mq.mq($from: large) {
      --section-inner-spacing: 5rem;
    }
  }

  /*
  &-section {
    --communications-section-padding-bottom: 5rem;

    padding: 0.2rem 0 var(--communications-section-padding-bottom);

    &:last-child {
      --communications-section-padding-bottom: 7rem;
    }

    @include mq.mq($from: large) {
      padding-top: 0.5rem;

      &:last-child {
        --communications-section-padding-bottom: 10rem;
      }
    }
  }
*/
  &__action {
    text-align: center;
  }

  &__load-more {
    margin-top: 5rem;
  }
}