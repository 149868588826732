.table-of-contents {
    --marker-color: currentColor;
    --list-item-padding-left: 1rem;
    --link-text-decoration: none;
    --list-item-margin-top: 2.5rem;

    color: var(--color-primary-900);

    &__header {
        color: currentColor;
        margin-bottom: 2.5rem;
    }

    &__list {
 
    }

    &__item {
      &:has( a:focus ),
      &:has( a:hover ){
        color: var(--link-color-focus);
      }
    }

    &__anchor {
        display: block;
        position: relative;
        padding-bottom: .5rem;
        --link-text-decoration-active: none;
        --link-text-decoration: none;
        &:after {
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            width: calc( var(--list-margin-left) + var(--list-item-padding-left) + 100% );
            display: block;
            border-bottom: .1rem solid currentColor;
     
            transition: border-color var(--animation-duration) var(--animation-ease);
        }
        
    }
}