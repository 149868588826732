@keyframes loading {
    from {
        transform: rotate(0)
    } 
    to {
        transform: rotate(360deg)
    }
}

.loading {
    position: relative;
    pointer-events: none;
    color: transparent !important;


    &::after {
        content: "";
        position: absolute;
        display: block;
        height: 2rem;
        width: 2rem;
        margin-top: -1rem;
        margin-left: -1rem;
        border-width: .2rem;
        border-style: solid;
        border-radius: 50%;
        border-color: var(--loading-border-color, var(--color-primary-900));
        animation: loading 0.5s infinite linear;
        border-right-color: transparent;
        border-top-color: transparent;
        left: 50%;
        top: 50%;
        z-index: 1;
    }

    &.action {
        color: transparent !important;
        --loading-border-color: var(--color-white);
    }

    &.form {
        --form-fieldset-opacity: .5;
    }
}

.fui-loading {
    @extend .loading;
}