@use "../mixins/mq";
@use "vars";

html {
	font-size: 62.5%;
	text-size-adjust: none;
	font-variant: none;
	font-variant-ligatures: none;
	scroll-behavior: var(--scroll-behavior);
	scroll-padding-top: var(--scroll-padding-top);
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	overflow-x: hidden;
	font-family: var(--body-font-family, vars.$body-font-family);

	font-size: var(--body-font-size);
	line-height: var(--body-line-height);
	color: var(--body-color);
	font-weight: var(--body-font-weight);
	letter-spacing: var(--body-letter-spacing);
	background-color: var(--body-bg-color);

	hyphens: var(--hyphens-style);
	word-break: break-word;
	text-size-adjust: none;
	-webkit-text-size-adjust: none;
	hyphenate-limit-chars: 6 4 4;
}

.content {
    overflow: clip;
    padding-top: .1px;
}