@use "../mixins/mq";

.nav--footer-meta {
	//--link-color-focus: var(--color-tertiary-900);
	--link-text-decoration: none;
	--icon-width: 0.63em;
	--icon-height: 0.63em;
	.nav {
	
		&__link {
			display: flex;
			justify-content: space-between;
			gap: 1rem;
			align-items: center;
			padding: 1.5rem 0;
			border-bottom: 0.1rem solid var(--color-grey-100);
		}

	}

	@include mq.mq($from: large) {
		.nav {
	
			&__link {
			
				padding: 2.1rem 0;
	
			}
	
		}
	
      }
}