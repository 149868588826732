@use "../mixins/mq";

.banner {
    --spacing-h4-top: 1.5rem;

    position: relative;
    padding-block: 2.5rem;
    background-color: var(--color-secondary-900);

    z-index: 11;

    @include mq.mq($from: large) {
        --spacing-h4-top: 0;
        padding-block: 5rem;
    }

    &--hidden {
        display: none;
    }

    &__action {
        flex: none;
        margin: 0;
    }

    &__close {
        position: absolute;
        top: 1.5rem;
        right: 1.5rem;

        @include mq.mq($from: large) {
            top: 2.5rem;
            right: 2.5rem;

        }
    }

    &__icon {
        width: 4rem;

        @include mq.mq($from: large) {
            position: absolute;
            left: 0;
            top: 0;
            width: 5rem;
        }
    }

    &__grid {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        gap: 2.5rem;

        @include mq.mq($from: large) {
            flex-direction: row;
            align-items: self-end;
            gap: 5rem;
        }
    }

    &__text {
        position: relative;
        width: 100%;

        @include mq.mq($from: large) {
            padding-left: 7.5rem;
        }
    }
}