@use "../mixins/mq";

.figcaptions {
    --img-bg-color: var(--color-skeleton);



    &__image {
        width: 100%;
    }



    &__picture {
        display: block;
        margin-inline: var(--wrapper-gap-negative);
        margin-bottom: 2.5rem;
        &--small {
            display: block;
        }
        &--medium-up {
            display: none;
        }
    }


    &__legend {
        margin-bottom: 1.5rem;
        display: inline-block;
        font-weight: bold;
        letter-spacing: .032rem;

        &--desktop-only {
            display: none;

            @include mq.mq($from: large) {
                display: block;
            }
        }

        &--mobile-only {
            @include mq.mq($from: large) {
                display: none;
            }
        }
    }

    &__list {
        font-size: var(--subtitle-font-size);
        line-height: var(--subtitle-line-height);
        letter-spacing: var(--subtitle-letter-spacing);
        margin: 0 0 1.5rem;
        padding: 0;
        list-style: none;
        counter-reset: figcaptionscounter;
        font-weight: 500;

        &:last-child {
            margin-bottom: 0;
        }

        &--desktop-only {
            display: none;

            @include mq.mq($from: medium) {
                display: block;
            }
        }

        &--mobile-only {
            @include mq.mq($from: medium) {
                display: none;
            }
        }
    }
 

    &__item {
        --list-item-margin-top: 0;
        counter-increment: figcaptionscounter;
        border-bottom: dashed .1rem var(--color-accent-03);
        padding: 1rem 0 1rem 0;

        //margin: 1rem 0 0 0;
    
        &:first-child {
            margin-top: 0;
        }
    }

    &__entry {
        display: block;
        padding-left: 3.5rem;
        position: relative;
        &::before {
            color: var(--marker-color);
            min-width: 2rem;
            font-weight: bold;
            display: block;
            position: absolute;
            left: 0;
            content: counter(figcaptionscounter);
        }
    }


    &__list--dots {
          .figcaptions__entry:before {
            content: "";
            top: 0.2rem;
            aspect-ratio: 1/1;
            border-radius: 50%;
            background: var(--marker-color, #eee);
            background-position: center center;
          }  
    }
    @include mq.mq($from: medium) {
        &__picture {
            margin-inline: 0;
            margin-bottom: 5rem;
            &--small {
                display: none;
            }
            &--medium-up {
                display: block;
            }
        }
        &__image {
            border-radius: var(--rounded-l);
        }
    }

    @include mq.mq($from: large) {
 
    
        &__figure {
            margin-inline: 0;
        }
 
       
     
        &__list {
            column-count: 2;
          
            column-gap: var(--grid-gap,0);
            margin-bottom: 5rem;
        }
        &__item {
            break-inside: avoid-column;
        }
    }
}