.spinner {
    --input-height: auto;

    display: grid;
    grid-template-columns: 5rem auto 5rem;
    border: solid .1rem var(--color-black);
    border-radius: .2rem;
    text-align: center;
    height: 5rem;
    font-weight: bold;
    background: none;
    color: var(--color-black);

    &__control {
        text-decoration: none;
        color: inherit;
        font-weight: inherit;
        background: none;
        display: grid;
        justify-content: center;
        align-items: center;

        &:hover,
        &:focus {
            color: var(--color-primary-900);
        }
    }

    &__icon {
        --icon-width: 2.2rem;
        --icon-heigh: 2.2rem;
    }

    &__input {
        background: none;
        text-align: inherit;
        font-weight: inherit;
        border-radius: 0;
        border-block: none;
        border-inline: solid .1rem var(--color-black);
    }
}