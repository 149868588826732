@use "../mixins/mq";
@use "../atoms/field";

.form {

    margin: var(--form-margin, 0);
    padding: var(--form-padding, 0);


    &__autocomplete:empty {
        @extend .input;
    }

    &__row {
        display: flex;
        gap: var(--form-row-gap, 0);
        flex-wrap: wrap;
        margin-top: var(--form-row-margin-top, 0);
        justify-content: center;

        //sub row as column
        &--sub {
            flex-direction: column;
        }


    }

    &__nested-field {

        margin: 1.6rem 0;
    }

    &__nested-field-wrapper {

        &--reverse {
            display: flex;
            flex-direction: column-reverse;
        }
    }

    &__nested-action {
        margin: 3.2rem auto 2.5rem 0;

        &--decrease {
            margin: 1.6rem auto 2rem 0;
        }

        //hide the add btn
        &[disabled] {
            display: none;
        }

    }

    &__page {
        display: var(--form-page-display);
        grid-template-columns: var(--form-page-grid-template-columns);
    }

    &__actions {
        display: flex;
        gap: 1.6rem;
        //flex-direction: row-reverse;
        justify-content: space-between;
        --action-margin-right: 0;
        --action-margin-left: 0;

        margin: var(--form-action-margin, 2.5rem 0 0 0);
        padding: var(--form-action-padding);
        box-shadow: var(--form-action-box-shadow);

    }

    &__progress {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        margin: auto;
        max-width: var(--progress-wrapper-max-width, auto);

        &-indexes {
            white-space: nowrap;
            font-size: 1.4rem;
            color: var(--color-accent-01);
            font-weight: 500;
            line-height: 1.5;
            font-variant-numeric: tabular-nums;
        }

        &-index-current {
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 1.6;
            letter-spacing: .032rem;
        }
    }


    &__legend {
        --spacing-bottom: 0;
        color: var(--color-primary-900);

        &~.form__row:first-of-type {
            --form-row-margin-top: 2rem;
        }
    }

    &__text {
        --spacing-text-bottom: 5rem;
    }

    &--wrapper,
    &__wrapper {
        padding: 5rem var(--wrapper-gap);
        width: 100vw;
        margin-left: calc(-1 * #{var(--wrapper-gap)});
        margin-right: calc(-1 * #{var(--wrapper-gap)});
        border-radius: var(--rounded-l);
        background-color: var(--color-white);

        @include mq.mq($from: medium) {
            margin-left: auto;
            margin-right: auto;
            width: 100%;
        }
    }

    &__fieldset {
        opacity: var(--form-fieldset-opacity, 1);
        transition: opacity var(--animation-duration) var(--animation-ease);

        max-width: 79rem;
        margin: 0 auto;

        &--narrow {
            max-width: 68.8rem;
        }
    }


    &__reset {
        --spacing-text-top: 5rem;

        &+* {
            --spacing-text-top: 5rem;
        }
    }

    &__response strong {
        font-weight: 600;
    }

    &__response-row {
        $self: &;
        --spacing-text-top: 0;
        --summary-sapcing-top: 0;
        display: flex;
        flex-wrap: wrap;
        padding: 5rem 0 2.5rem;

        #{ $self }+& {
            border-top: 1px solid var(--color-focus);
            padding: 2.5rem 0;
        }

        @include mq.mq($from: medium) {
            gap: 0 5rem;
        }

        @include mq.mq($from: large) {
            gap: 0 8.4rem;
        }
    }

    &__header {
        color: var(--color-primary-900);
        font-weight: 600;
    }

    &__summary-title {
        color: var(--color-primary-900);
    }

    &__summary-hr {
        border-color: var(--color-grey-100);
        margin: 2.5rem 0 5rem;
    }

    &__hr {
        border-color: var(--color-grey-100);
        margin: 1.5rem 0;
    }

    &__summary-block {
        margin-block: 1rem;

        // hide block with title if following is empty
        &:has(.form__summary-title):has(+ .form__summary-block--empty) {
            display: none;
        }

        // trick for links
        span a {
            color: currentColor;
            pointer-events: none;
            text-decoration: none;

            &:before {
                content: '• ';
            }
        }

        span:has(a) {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__summary-label+br {
        display: none;
    }

    &__summary-label,
    //for nested fields
    span>strong {
        font-weight: 400;
        letter-spacing: inherit;
        color: var(--color-grey-300);
        display: block;
        margin-top: 1rem;


    }


    &__summary-label {
        &--group {
            color: var(--color-primary-900);
        }

        &--section {
            margin-top: 2rem;
            font-weight: 600;
            color: inherit;
            font-size: 1.8rem;
        }

        &--hidden {
            display: none;
        }
    }


    //tmp solition:
    [data-summary-blocks] {
        font-weight: 500;

    }

    &__message {

        // TODO, check why <p> are stripped
        strong {
            display: block;
            margin-bottom: 2rem;
        }
    }



}


//for adresse, set zip and city and also address on one row

.form {

    &__rows {


        margin-top: -2rem;
        //background-color: rgba(113, 94, 94, 0.152);

        &--address {
            display: grid;
            gap: var(--form-row-gap, 0);
            grid-template-columns: 1fr 1fr 1fr;

            .form__row--address {

                &:has([data-field-handle="zip"]) {
                    display: grid;
                    grid-template-columns: 1fr 2fr;
                }

            }
        }
    }

    &__row {

        &--address {
            grid-column: span 3;

            &:has([data-field-handle="address1"]) {
                grid-column: span 2;
            }

            &:has([data-field-handle="address2"]) {
                grid-column: span 1;
            }


        }

    }

    &__infos {
        margin-top: 2rem;
    }


}

.multi-steps-form {
    --form-content-max-width: 68rem;
    --form-padding: var(--wrapper-gap) var(--wrapper-gap) 0;

    background-color: var(--color-white);
    border-radius: var(--rounded-l);
    box-shadow: var(--shadow-300);
    margin-inline: var(--multi-steps-form-margin-inline, var(--wrapper-gap-negative));
    //padding: var(--wrapper-gap);
    //padding-bottom: 0;

    .form {

        &__progress {
            margin: 2.5rem 0;
        }

        &__page-title {
            color: var(--color-primary-900);
            font-weight: var(--heading-font-weight);
            text-align: var(--form-page-title-text-align);
            //margin: 2.5rem;

            &--full-width {
                --form-content-max-width: none;
            }
        }

        &__container {
            margin-top: 2.5rem;
        }

        &__message {
            padding-block: var(--wrapper-gap);
        }

        &__infos,
        &__message,
        &__alert,
        &__title,
        &__progress,
        &__page-title,
        &__page-content {
            width: 100%;
            max-width: var(--form-content-max-width);
            margin-inline: var(--form-content-margin-inline, auto);
        }



        &__section {
            margin-block: 2.5rem;
        }

        &__page {
            &:has(aside) {
                --form-page-display: grid;
                --form-page-grid-template-columns: 1fr auto;
                --form-page-title-text-align: center;
            }
        }

        &__actions {
            --action-font-size: 1.6rem;

            margin: 5rem 0 0;
            padding: 1.4rem 1.6rem;
            box-shadow: var(--shadow-300);
            flex-direction: row-reverse;
            margin-inline: var(--wrapper-gap-negative);
            position: sticky;
            bottom: 0;
            z-index: 2;
            background-color: var(--color-white);
            flex-wrap: wrap;
            grid-column: span 2;
        }

        &__aside {
            position: sticky;
            top: 20rem;
            //bottom: 10rem;
            margin-left: var(--wrapper-gap);
            margin-right: var(--wrapper-gap-negative);
            z-index: 2;
            min-height: 5rem;
            grid-column: 2;
            align-self: start;
            grid-row: 1;
            margin-bottom: 10rem;
            width: 0;

            &--slide-in {
                --aside-box-transform: translateX(-100%);
            }
        }

    }

}

@include mq.mq($from: medium) {

    .form {
        &__progress {
            &-index-current {
                font-size: 1.8rem;
                line-height: 1.65;
                letter-spacing: .036rem;
            }
        }
    }

    .multi-steps-form {

        .form {
            &__progress {
                margin: 2.5rem auto;
            }

            &__actions {
                --action-font-size: inherit;

                padding: 1.4rem 2.4rem;
                border-radius: 0 0 var(--rounded-l) var(--rounded-l);
                position: static;
            }

            &__section {
                margin-block: 5rem;
            }

        }
    }

}


@include mq.mq($from: large) {

    .form {
        &__page {
            // for aside
            gap: 10rem;
        }

        &__aside {
            .multi-steps-form & {
                --aside-box-transform: none;
                margin-left: 0;
                width: 38rem;
            }

        }

    }


}

/* for repeater, if min 1 is set */
[data-field-type="repeater"] {
    &:has(.action[data-min-rows="1"]) {
        .form__nested-field:only-child {
            .action {
                --action-color: var(--action-disabled-color);
                --action-bg-color: var(--action-disabled-bg-color, none);
                --action-decoration-bg-image-active: var(--action-decoration-base-bg-image-active);
            }
        }
    }
}

.grecaptcha-badge {
    // visibility: hidden; 
    z-index: 2;
}


// todo: clean up
.form--with-aside {
    //--form-content-margin-inline: 0 auto;
    //--form-content-max-width: calc(100% - 38rem);
    --form-content-max-width: 81.2rem;
}
