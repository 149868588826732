@use "../mixins/mq";

.charging-places {
  max-width: 49.3rem;
  padding: 2.5rem;
  margin-top: var(--charging-place-spacing-top, 2.5rem);
  background-color: var(--color-primary-700);
  border-radius: var(--rounded-m);
  line-height: 1.3;

  &__icon {
    display: inline;
    width: 2.3rem;
    margin-left: .6rem;
    height: 5rem;
  }

  &__wrap {
    padding: 0 2.5rem;
    border-radius: var(--rounded-m);
    background-color: var(--color-white);
  }

  &__grid {
    --charging-place-spacing-top: 0;

    display: grid;
    gap: 2rem;
  }

  &__title {
    display: flex;
    gap: 2.5rem;
    margin-bottom: 2.5rem;
    line-height: 1;
    align-items: center;
  }

  @include mq.mq($from: medium) {
    &__grid {
      grid-template-columns: 1fr 1fr;
    }

    &__title {
      line-height: 1;
    }
  }
}

.charging-place {
  --list-item-background: var(--dashed-line) 0 0 repeat-x;
  --spacing-text-bottom: 0;

  background: var(--dashed-line) 0 bottom repeat-x;
  background-size: 6px 1px;
  line-height: 1.3;
  display: flex;
  padding: .5rem 0;
  justify-content: space-between;
  align-items: center;
  min-height: 8.7rem;

  &:last-child {
    background: none;
  }


}