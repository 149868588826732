@use "../_base/svg";
@use "../_config/colors";
@use "../mixins/background-svg";
@use "../mixins/mq";

.faq {
  &-category-section {
    --section-spacing: 5rem;

    @include mq.mq($from: large) {
      --section-spacing: 10rem;
    }
  }

  &-categories__row {
    grid-row-gap: var(--grid-gap);
  }


  &__category {
    --spacing-h3-bottom: 2.5rem
  }


  &__title {
    position: relative;
    padding-right: var(--faq-spacing-horizontal);
    font-weight: 500;
    cursor: pointer;
    color: var(--faq-title-color);
    transition: color var(--animation-duration) var(--animation-ease);


    &::-webkit-details-marker,
    &::marker {
      display: none;
      content: '';
    }

    &:before,
    &:after {
      position: absolute;
      right: 1px;
      top: var(--faq-opener-top);
      display: block;
      width: 2rem;
      height: 0.3rem;
      content: '';
      transition: transform var(--animation-duration) var(--animation-ease);
      background-color: currentColor;
      border-radius: 0.3rem;
      //background: var(--faq-background) center center no-repeat;
      //background-size: 2rem 2rem;
    }

    &:after {
      transform: var(--faq-opener-transform);
    }
  }

  &__wrapper {
    --faq-margin-top: 5rem;
    margin-top: var(--faq-margin-top);

    &:first-child {
      --faq-margin-top: 0;
    }

    @include mq.mq($from: large) {
      //background: linear-gradient(to right, transparent 0, transparent 50%, var(--color-white) 50%, var(--color-white) 100%);
    }
  }

  &__list {
    --faq-list-margin-horizontal: calc(#{var(--wrapper-gap)} * -1);
    --faq-padding: 2.5rem 2rem;

    position: relative;
    padding: var(--faq-padding);
    margin-left: var(--faq-list-margin-horizontal);
    margin-right: var(--faq-list-margin-horizontal);
    border-radius: var(--rounded-m);
    background-color: var(--color-white);

    &--single {
      --faq-list-margin-horizontal: -2rem;
    }


    @include mq.mq($from: large) {
      --faq-list-margin-horizontal: 0;
      --faq-padding: 5rem 7rem;
      border-radius: var(--rounded-l) 0 0 var(--rounded-l);

      &--has-decoration {
        --faq-padding: 5rem 0 5rem 7rem;

        &:after {
          position: absolute;
          left: 100%;
          top: 0;
          content: '';
          display: block;
          height: 100%;
          width: 100vw;
          background-color: var(--color-white);
        }
      }
    }
  }


  &__entry {
    --faq-background: var(--faq-opener);
    --faq-padding-bottom: 2.5rem;
    --faq-margin-top: 2.5rem;
    --faq-opener-transform: rotate(90deg);
    --faq-spacing-horizontal: 7rem;
    --faq-title-color: var(--color-black);
    --faq-border-bottom: 1px solid var(--color-grey-100);
    --faq-opener-top: 1.6rem;
    --faw-answer-opacity: 0;
    --faw-answer-transform: translateY(1rem);

    padding-bottom: var(--faq-padding-bottom);
    margin-top: var(--faq-margin-top);
    border-bottom: var(--faq-border-bottom);

    @include mq.mq($from: large) {
      --faq-opener-top: 1.8rem;
    }

    &:hover {
      --faq-background: var(--faq-opener-hover);
      --faq-title-color: var(--color-tertiary-900)
    }

    &:first-child {
      --faq-margin-top: 0;
    }

    &:last-child {
      --faq-padding-bottom: 0;
      --faq-border-bottom: 0;
    }

    &[open] {
      //--faw-answer-opacity: 1;
      //--faw-answer-transform: translateY(0);
      --faq-opener-transform: rotate(0);
      --faq-title-color: var(--color-primary-900);
    }
  }

  &__global-link {
    display: block;
    margin-bottom: 2rem;
  }

  &__answer {
    opacity: 0;
    transform: translateY(1rem);
    padding-top: 2.5rem;
    transition: opacity var(--animation-duration) var(--animation-ease), transform var(--animation-duration) var(--animation-ease);


    [open] & {
      animation-delay: .1s;
      opacity: 1 !important;
      transform: translateY(0) !important;
    }
  }

  /* &__body {
    opacity: var(--faw-answer-opacity);
    transform: var(--faw-answer-transform);
  }*/
}