@use "../mixins/mq";
@use "../utils/visually-hidden";

.overlay-header {
  --header-color: var(--color-white);
  --header-bg-color-opacity: 0;
  --header-margin-bottom: calc(var(--header-height) * -1);
}


  @include mq.mq($until: large) {
    .open-mobile-menu {
      overflow: hidden;
      --header-nav-overlay-display: block;
    }
    .visible-mobile-menu {
      --header-content-visibility: visible;
    }
  }



.header {
  position: sticky;
  top: 0;
  color: var(--header-color, inherit);
  width: 100%;

  padding: 0;
  z-index: 10;

  transform: var(--header-transform, translateY(0));

  transition:
    transform var(--animation-duration) var(--animation-ease),
    box-shadow var(--animation-duration) var(--animation-ease),
    background-color var(--animation-duration) var(--animation-ease);

    &--with-slogan {
      --header-height: var(--header-with-slogan-height);
      --header-logo-max-height: var(--header-with-slogan-logo-max-height);
      --header-decoration-opacity: 0;
    }

  &--detached {
    --header-bg-color-opacity: 1;
    --header-color: currentColor;
    --header-border-bottom-color-opacity: 1;
    --header-decoration-opacity: 0;
    --header-outer-box-shadow: var(--shadow-300);
  }

  &:has(.nav--main-nav .nav__item--parent:hover),
  &:has(.nav--main-nav .nav__link--parent.open) {
    --header-decoration-opacity: 0;
  }

  &__decoration {

    --icon-width: 100%;
    --icon-height: 100%;

    pointer-events: none;
    position: absolute;
    filter: drop-shadow(var(--shadow-300));
    
    fill: var(--color-white);
    left: 0;
    top: 0;
    width: 16rem;
    height: 10.5rem;
    opacity: var(--header-decoration-opacity, 1);
    transition: opacity var(--animation-duration) var(--animation-ease);
  }


  &__outer {
    padding: 1.5rem 0;
    height: var(--header-height);
    box-shadow: var(--header-outer-box-shadow);
    background-color: rgba(255, 255, 255, var(--header-bg-color-opacity, 1));
  }

  &__wrapper {

    display: flex;
    justify-content: space-between;
    align-items: center;
    position: static;
    gap: 0 1rem;
    height: 100%;
  }

  &__home-link {
    text-decoration: none;
    transform: var(--header-home-link-transform, translateY(0));
    transform-origin: left center;
    transition: transform var(--animation-duration) var(--animation-ease), color var(--animation-duration) var(--animation-ease);
    display: flex;
    gap: 1rem 3rem;
    flex-direction: column;
  }

  &__slogan {
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 1.65;

    &--large {
      display: none;
    }
  }

  &__logo {
    display: block;
    width: auto;
    max-height: var(--header-logo-max-height);
    margin-right: auto;


  }

  &__hamburger {
    position: relative;
    z-index: 3;
  }

  &__nav-meta {
    display: flex;
    flex-direction: column;
    gap: 2.7rem 3.5rem;
  }

  &__lang-nav {
    &--mobile {
      position: absolute;
      left: var(--wrapper-gap);
      top: 2.6rem;
      display: flex;
    }

    &--large {
      display: none;
    }


  }

  &__mobile-actions {
    --action-inline-margin: 0;

    display: flex;
    gap: 3.3rem;
    align-items: center;
  }

  &__nav-backdrop {
    display: none;
  }


  @include mq.mq($until: large) {
    &__content {

      //medium small and medium only

      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      //padding: 9rem var(--wrapper-gap) 5rem;
      //padding: 9rem 0 5rem;
      visibility: var(--header-content-visibility, hidden);
      display: block;
      z-index: 2;
      transform: translate3d(-100%, 0, 0);
      overflow-x: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      transition: transform var(--animation-duration) var(--animation-ease);
      color: var(--color-white);
      background-color: var(--mobile-menu-bg-color);

      .open-mobile-menu & {
        transform: translate3d(0, 0, 0);
      }

    }

    &__nav {
      position: relative;
      min-height: 100vh;
    }


  }

  &__content {
    display: block;
    @include mq.mq($from: medium, $until: large) {
      max-width: 50rem;
    }
  }



  &__nav-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .7);
    display: var(--header-nav-overlay-display, none);
    z-index: 1;
  }

 

}



@include mq.mq($from: large) {
  .header {

    &--scroll {
      --header-transform: translateY(-5rem);
      --header-nav-meta-opacity: 0;
      --header-nav-meta-pointer-events: none;
      --header-home-link-transform: translateY(2.5rem) scale(.9);
    }

    &__nav {
      height: var(--header-height);
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      padding: 0;
      flex-direction: column-reverse;
      //justify-content: space-between;
      justify-content: flex-start;
      gap: 1.6rem;

    }

    &__nav-meta {
      //position: absolute;
      //bottom: 100%;
      //right: 0;
      margin-top: 2rem;
      min-height: 4.5rem;
      align-items: center;
      flex-direction: row;
      opacity: var(--header-nav-meta-opacity, 1);
      pointer-events: var(--header-nav-meta-pointer-events, all);

      transition: opacity var(--animation-duration) var(--animation-ease);
      will-change: opacity;

    }

    &__outer {
      padding: 0;

    }

    &__wrapper {}

    &__lang-nav {
      &--mobile {
        display: none;
      }

      &--large {
        display: inline-flex;
      }
    }

    &__mobile-actions {
      display: none;
    }

    &__hamburger {
      display: none;
    }



    &__nav-backdrop {
      display: none;
      //background-color: var(--color-black-transparent);
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      height: 100vh;
      z-index: -1;

      &--show {
        display: block;
      }
    }

    &__decoration {
      width: 28rem;
      height: 16rem;
    }

    &__home-link {
      flex-direction: row;
    }

    &__slogan {
      font-size: 1.8rem;
      display: none;

      &--large {
        display: block;
      }
    }


  }
}

@include mq.mq($from: menularge) {
  .header {
    &__decoration {
      width: 30rem;
      height: 18rem;
    }
  }
}