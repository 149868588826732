$fontPath: '../../webfonts/';

/* Inter */
@font-face {
	font-family: 'Inter Web';
	font-weight: normal;
	src: url('#{$fontPath}inter/inter-v13-cyrillic_latin-regular.woff2') format('woff2');
}


@font-face {
	font-family: 'Inter Web';
	font-weight: 500;
	src: url('#{$fontPath}inter/inter-v13-cyrillic_latin-500.woff2') format('woff2');
}


@font-face {
	font-family: 'Inter Web';
	font-weight: 600;
	src: url('#{$fontPath}inter/inter-v13-cyrillic_latin-600.woff2') format('woff2');
}

@font-face {
	font-family: 'Inter Web';
	font-weight: bold;
	src: url('#{$fontPath}inter/inter-v13-cyrillic_latin-700.woff2') format('woff2');
}


/* Museo Sans */
@font-face {
	font-family: "Museo Sans Web";
	font-weight: normal;
	src: url('#{$fontPath}museo-sans/museo-sans-500.woff2') format('woff2');
}


@font-face {
	font-family: "Museo Sans Web";
	font-weight: bold;
	src: url('#{$fontPath}museo-sans/museo-sans-700.woff2') format('woff2');
}
