@use "../mixins/mq";

.product-selection {
    --product-selection-border-color: var(--color-accent-01);
    --product-selection-cost-color: var(--color-primary-900);
    --product-selection-bg-color: var(--color-primary-700);
    --product-selection-color: var(--color-accent-01);
    --product-selection-border-radius: var(--rounded-s);
    --product-selection-body-bg-color: var(--color-tertiary-900);
    --product-selection-benefits-color: var(--color-black);
    --product-selection-height-deducted: 5rem;


    margin: var(--product-selection-margin);
    position: relative;
    width: 100%;
    padding: 1.5rem;
    color: var(--product-selection-color, currentColor);
    background-color: var(--product-selection-bg-color);
    border: .1rem solid var(--product-selection-border-color);
    border-radius: var(--product-selection-border-radius);
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    height: var(--product-selection-height, 100%);
    transition:
        color var(--animation-duration) var(--animation-ease),
        background-color var(--animation-duration) var(--animation-ease),
        border-color var(--animation-duration) var(--animation-ease);

    &--no-subtitle {
        --product-selection-height-deducted: 8rem;
    }

    &--offer {
        --product-selection-margin: var(--product-selection-height-deducted) 0 0;
        --product-selection-height: calc(100% - var(--product-selection-height-deducted));
        --product-selection-color: currentColor;
        --product-selection-title-text-align: center;
        --product-selection-border-radius: var(--rounded-xl);
        --product-selection-margin-inline: 1rem;
        --check-margin-inline: auto 0;
        --product-selection-body-height: 100%;
    }

    &--dispaly-tag-if-disabled {
        --product-selection-tag-display: none;
    }

    :checked+&,
    :focus+&,
    &--check:hover,
    &--checked,
    .active &,
    .hover+& {
        --product-selection-bg-color: var(--color-accent-01);
        --product-selection-color: var(--color-white);
        --product-selection-border-color: var(--product-selection-bg-color);
        --product-selection-body-bg-color: var(--color-white);
        --product-selection-cost-color: currentColor;
        --check-color: currentColor;
        --product-selection-benefits-color: currentColor;
        --label-color: currentColor;
        --link-color: currentColor;
        --check-list: var(--check-list-white);
    }

    :checked+& {
        --check-active-bg-image: var(--check-topic-bg-image);
    }

    &--check:hover,
    .hover+& {
        --product-selection-bg-color: var(--color-tertiary-900);
    }



    :disabled+& {
        --product-selection-tag-display: inline-flex;
        --product-selection-bg-color: var(--color-white);
        --product-selection-color: var(--color-accent-03);
        --product-selection-body-bg-color: var(--color-accent-03);
        --product-selection-border-color: currentColor;
        --product-selection-cost-color: currentColor;
        --product-selection-benefits-color: currentColor;
        --check-color: currentColor;
        --link-color: currentColor;
        --check-list: var(--check-list-accent-03);
    }

    .fui-error &,
    :where(.error, .fui-error)+& {
        --product-selection-border-color: var(--color-error);
        --check-color: var(--color-error);
    }

    [type="radio"]+& {
        --check-border-radius: 50%;

    }

    [type="radio"]:checked+& {
        --check-border-radius: 50%;
        --check-active-bg-image: var(--check-topic-radio-bg-image);
    }


    // hack - allow a tag and label
    &__label {
        z-index: 1;
        inset: 0;
        position: absolute;
        font-size: 0;
        // background-color: red;
        // pointer-events: none;
    }

    &__tag {
        --icon-width: 2rem;
        --icon-color: var(--color-primary-900);

        border-radius: 1rem;
        align-self: flex-start;
        display: var(--product-selection-tag-display, inline-flex);
        gap: .5rem;
        padding: .4rem 1rem;
        font-weight: 700;
        color: var(--color-black);
        background-color: var(--color-accent-06);

        &--fix {
            position: absolute;
            margin: 1rem;
        }
    }


    &__link {
        --link-color-focus: currentColor;

        font-weight: 600;
        position: relative;
        z-index: 2;
        margin-bottom: auto;
    }

    &__symbol {
        --icon-width: 6.4rem;
        --icon-height: 6.4rem;

        background-color: var(--color-white);
        border-radius: 50%;
        box-shadow: var(--shadow-300);
        width: 10rem;
        aspect-ratio: 1/1;
        display: grid;
        align-items: center;
        justify-content: center;
        margin-inline: auto;
        margin-top: -6.5rem;
        flex-shrink: 0;
    }


    &__image {
        --image-width: 100%;
        --img-border-radius: var(--product-selection-border-radius);

        max-width: 100%;
        margin-bottom: 1.5rem;
        flex-shrink: 0;
    }

    &__title {
        margin: 1rem 0 .5rem;
        text-align: var(--product-selection-title-text-align);
    }

    &__description {
        --spacing-text-bottom: 1.5rem;
    }

    &__body {
        position: relative;
        border-radius: var(--rounded-m);
        overflow: hidden;
        margin: 1.5rem 0;
        height: var(--product-selection-body-height);
        display: flex;
        flex-direction: column;

        &:before {
            content: "";
            position: absolute;
            pointer-events: none;
            inset: 0;
            opacity: .1;
            background-color: var(--product-selection-body-bg-color);
            transition: background-color var(--animation-duration) var(--animation-ease);

        }
    }

    &__box {
        padding: 0 1rem;

        &--bottom {
            margin-top: auto;
        }
    }

    &__subtitle {
        background-color: var(--color-black);
        display: block;
        margin: 0;
        text-align: center;
        line-height: 1;
        padding: 0.8rem 1.5rem;
        font-weight: 500;
        color: var(--color-white);

    }

    //body content

    &__cost {
        color: var(--product-selection-cost-color);
        text-align: center;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: end;
        justify-content: center;
        vertical-align: baseline;
        gap: 1rem;
        white-space: nowrap;
        margin-block: 1.5rem;

    }

    &__price {
        display: inline;
        margin: 0;
        // font-size: 5rem;
        line-height: .85;
        white-space: nowrap;
    }

    &__price-suffix {
        margin-right: auto;
        font-weight: 500;
    }

    &__price-prefix {
        margin-left: auto;
        font-weight: 500;
    }

    &__benefits {
        --list-margin-left: 0;
        --list-item-min-height: 4em;
        --list-item-padding-vertical: 0;
        --spacing-list-top: 0;
        --spacing-list-bottom: 1.5rem;

        --list-item-before-width: 2rem;
        --list-item-before-height: 2rem;

        color: var(--product-selection-benefits-color, currentColor);
        background: none;

        &-item {
            display: flex;
            line-height: 1.5;
            align-items: center;
            padding-block: .5rem;
            word-break: keep-all;

            &:first-child {
                --list-item-background: none;
            }
        }
    }

    &__disclaimer {
        font-weight: 500;
        margin-top: auto;
        margin-bottom: .7rem;
    }

    &__footer {
        margin-block: auto 0;
        margin-inline: var(--product-selection-margin-inline, 0);
        display: flex;
        justify-content: space-between;
        gap: 1rem;
    }


    &__check {
        display: block;
        margin-inline: var(--check-margin-inline, auto 1rem);
        width: var(--check-size);
        height: var(--check-size);
        border: var(--check-color) var(--check-border-width) solid;
        color: var(--check-color);
        background-image: var(--check-active-bg-image, none);
        transition: var(--check-transition);
        border-radius: var(--check-border-radius, .2rem);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }


}