@use "../mixins/mq";

.breadcrumb {
	--icon-width: 1.5rem;
	--icon-height: 1.5rem;
	--outline-offset: .25rem;

	max-width: calc(100% - 1rem);
	font-size: var(--subtitle-small-font-size);
	line-height: var(--subtitle-small-line-height);
	letter-spacing: var(--subtitle-small-letter-spacing);
	font-weight: 500;
	white-space: nowrap;
	overflow-x: auto;
    overflow-y: hidden;
	padding: .5rem;
    scroll-behavior: smooth;
	margin: 0 -.5rem 0;

	&--light {
		color: var(--color-white);
		--link-color: var(--inverted-link-color);
		--link-color-focus: var(--color-white);
	}

	&--standalone {
		margin-top: 3.5rem;
	}

	&--help-finder {
		margin-block: 2rem;
	}


	&--ellipsis {
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0;
		width: auto;
		margin: 0;
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
		display: inline-flex;
		gap: .2rem;
	}

	&__item {
		padding: 0;
		margin: 0;

		&:first-child {
			margin-right: 0.8rem;
		}
	}

	&__link {
		margin: 0;
		text-decoration: none;
		display: inline;

		&--current {
			font-weight: 700;
		
		}
	}

	&__icon {
		display: inline-block;
	}

}

