@use "../mixins/mq";


.channel-overview {


    &--has-decoration {
       
    }

    &__filter {
        padding: var(--channel-overview-filter-padding,1.5rem 0 5rem);
    }

    &__header {
        margin-bottom: 2.5rem;
    }

    &__results {
        margin-block: var(--section-spacing-s);
    }

    //teaser 
    &__mosaik {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        align-items: start;
        gap: 3.5rem var(--grid-gap);
    }

    &__mosaik-item {
        margin: 0;
        padding: 0;
    }


    @include mq.mq($from: medium) {

        --channel-overview-filter-padding: 2rem 0 7.5rem;

        &__mosaik {
            grid-template-columns: repeat(12, 1fr);
            gap: var(--grid-gap);
        }

        &__mosaik-item {
            grid-column-end: span 4;

            &--custom {
                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    grid-column-end: span 12;
                    height: 100%;
                }
            }
          
        }
    }

    @include mq.mq($from: large) {
        &__mosaik-item {
            grid-column-end: span 4;

            &--custom {
                &:nth-child(1) {
                    grid-row-end: span 3;
                    grid-column-end: span 7;

                    .teaser {
                        --teaser-padding: 5rem;
                    }
                   
                }
    
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    grid-column-end: span 5;
                }
            }
          
        }
        &--has-decoration {
            --channel-overview-filter-padding: 1.5rem 0 15rem;
        }
    }

    @include mq.mq($from: large, $until: xLarge) {
        --grid-gap: 3.5rem;
    }
}