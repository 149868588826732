.faq-category {
	position: relative;
	display: grid;
    grid-template-rows: auto 1fr;
    align-items: center;
    justify-content: center;
    flex-direction: var(--contact-box-flex-direction, column);
	gap: 2.5rem;
	border-radius: var(--rounded-m);
	background-color: var(--color-accent-06);
	padding: 5rem 2.5rem 2.5rem;
    height: 100%;
    color: currentColor;
    text-decoration: none;
    box-shadow: var(--shadow-000);
    transition: box-shadow var(--animation-duration) var(--animation-ease);
    justify-items: center;
    text-align: center;

    &:hover,
    &:focus {
        box-shadow: var(--shadow-300);
        color: var(--color-accent-01);
        text-decoration: none;
    }
 

    &__title {
        min-height: 2lh;
        display: grid;
        align-items: center;
    }


   
}
