@use "../mixins/mq";
@use "../_config/colors";

.construction-projects-disturbances {


  &__container {
    --title-margin-bottom: 2.5rem;
    --icon-width: 3.5rem;
    margin-bottom: var(--container-margin-bottom, 0);
    max-width: 128.2rem;

    @include mq.mq($from: large) {
      --title-margin-bottom: 5rem;
      --icon-width: 5rem;
    }
  }

  &__icon {
    width: var(--icon-width);
    margin-top: 0.1em;
    margin-right: 2.5rem;

  }

  &__title {
    --spacing-h3-top: 0;

    display: flex;
    align-items: flex-start;

    margin-bottom: var(--title-margin-bottom);
  }

  &__fallback {
    font-weight: 400;
  }

  &__legend {
    margin-top: 5rem;
    max-width: 86rem;
  }
}