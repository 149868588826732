@use "../mixins/mq";

.icon {
	position: var(--icon-position, relative);
	display: var(--icon-display, block);
	font-style: normal;
	color: var(--icon-color, currentColor);
	width: var(--icon-width, auto);
	min-width: var(--icon-width, none);
	height: var(--icon-height, auto);
	flex-shrink: 0;
	transform: var(--icon-transform, none);
	max-height: 100%;
	margin: var(--icon-margin, none);
	vertical-align: var(--icon-vertical-align, inherit);


	&--small {
		--icon-width: 3.2rem;
		--icon-height: 3.2rem;
	}

	&--large {
		--icon-width: 7.5rem;
		--icon-height: 7.5rem;
	}

	&,
	path,
	[fill] {
		will-change: fill, color, stroke, transform;
		transition: fill var(--animation-duration) var(--animation-ease), stroke var(--animation-duration) var(--animation-ease), color var(--animation-duration) var(--animation-ease), transform var(--animation-duration) var(--animation-ease);
	}

	//try to set all shape to use the current color
	&--current-color {
		[stroke]:not([stroke=none]) {
			stroke: currentColor;
		}
		[fill]:not([fill=none]) {
			fill: currentColor;
		}
	}

}
