@use "../mixins/mq";

.communication-heading {
  &-section {
    margin-top: 4rem;

    @include mq.mq($from: large) {
      margin-top: 10rem;
    }
  }

  &__figure {
    --img-bg-color: var(--color-skeleton);
    display: block;
    margin-inline: var(--wrapper-gap-negative);
    margin-top: 5rem;

    @include mq.mq($from: medium) {
      --wrapper-gap-negative: 0;
    }


    @include mq.mq($from: large) {
      margin-top: 10rem;
    }
  }

  @include mq.mq($from: medium) {
    &__image {
      border-radius: var(--rounded-l);
      margin-inline: 0;
    }
  }

  &__title {
    --spacing-top: 1rem;
  }
}