@use "../mixins/mq";
@use "../_base/typography";

.nav--main-nav {
	--link-color: currentColor;
	--link-text-decoration: none;
	--icon-width: 1.5rem;
	--icon-height: 1.5rem;
	--nav-toggle-width: 3rem;

	font-weight: 600;
	letter-spacing: 0.04rem;


	.nav__text--nowrap {
		white-space: nowrap;
	}

	.nav {
		&__current-link {
			display: flex;
			flex-wrap: wrap;
			gap: 0 .5rem;
		}

		&__text {

			&--fit {
				min-width: fit-content;
			}
		}
	}

	@include mq.mq($until: large) {

		max-height: var(--nav-height, 100%);
		overflow: hidden;
		padding: 10rem 0 2.3rem;
		font-size: 1.8rem;

		.is-active {
			color: var(--color-tertiary-900);
		}

		.nav {


			&__back-link {
				display: flex;
				gap: 1.4rem;
				align-items: center;
				font-weight: bold;
				font-size: 1.4rem;
				letter-spacing: .028rem;
			}

			&__current-link {
				--icon-display: inline-block;
				--icon-margin: 0 0 0 1rem;

				font-family: var(--heading-font-family);
				font-size: var(--font-size-h5);
				line-height: var(--line-height-h5);
				font-weight: var(--heading-font-weight);
				letter-spacing: var(--letter-spacing-h5);
	

				/*
				display: flex;
				gap: 1.4rem;
				align-items: center;
				*/
				
			}

		

			&__wrap {
				display: none;

				&--parent {
					display: block;
				}

				&--child {

					left: 0;
					position: absolute;
					right: 0;
					top: 0;
					min-height: 100vh;
					height: 100%;
					//bottom: 0;
					transform: var(--nav-wrap-transform, translateX(100%));
					transition: transform var(--animation-duration) var(--animation-ease);
					visibility: hidden;
					will-change: transform;
					background-color: var(--mobile-menu-bg-color);
					z-index: 2;
					//box-shadow: var(--shadow-100);

						//fix for mobile
						&:after {
							content: "";
							display: block;
							height: 20rem;
						}
				}

				&--sub-child {
					display: none;
					width: 100%;
					order: 1;
					padding: 0;
					border: none;
					//border-top: .1rem solid currentColor;
					//max-height: var(--nav-max-height,0);
					opacity: 0;
					transition: max-height var(--animation-duration) var(--animation-ease), opacity var(--animation-duration) var(--animation-ease);
					font-weight: normal;
					letter-spacing: normal;
					font-family: var(--heading-font-family);
					font-size: var(--small-font-size);
					line-height: var(--small-line-height);
					letter-spacing: var(--small-letter-spacing);

					&.nav__wrap--current {
						/*
						--nav-max-height: 30rem;
						
						*/
						opacity: 1;
						display: block;

					}
				}

				//status

				&--visible {
					visibility: visible;
					display: block;
				}

				&--current {
					--nav-wrap-transform: translateX(0);



				}

				


			}

			&__item {
				--nav-link-width: 100%;
				display: flex;
				gap: 0 1rem;
				border-bottom: .1rem currentColor solid;
				flex-wrap: wrap;
				justify-content: space-between;
				margin: 0 var(--wrapper-gap) 2.6rem;

				&--parent {}

				&--has-children {
					--nav-link-width: calc(100% - 4rem);
				}

				&--sub-child {
					margin: 2rem 0;
					border: none;
				}

				&--back-link {
					border: none;
					background-color: var(--mobile-menu-bg-color);
					position: relative;
					padding: 0 var(--wrapper-gap);
					margin: 0;
					z-index: 1;
					height: var(--header-height, 8rem);
				}

				&--current-link {
					padding: 0 var(--wrapper-gap);
					margin: 0 0 4.5rem;
					border: none;
					box-shadow: var(--shadow-100);
					min-height: 7rem;
				}
			}

			&__link {
				width: var(--nav-link-width, 100%);

				&--parent {}
			}

			&__toggle {
				align-items: center;
				display: flex;
				justify-content: flex-end;
				width: var(--nav-toggle-width);
				color: var(--link-color);

				.icon-minus {
					display: var(--icon-minus-display, none);
				}

				.icon-plus {
					display: var(--icon-plus-display, block);
				}

				&[aria-expanded="true"] {
					--icon-minus-display: block;
					--icon-plus-display: none;
				}

			}
		}
	}

	/* Mega-Menu */
	@include mq.mq($from: large) {
		--icon-width: 2rem;
		--icon-height: 2rem;
		--nav-parent-link-spacing: 1.4rem;
		
		font-size: 1.8rem;
		line-height: 3.4rem;

		.is-active {
			--nav-parent-active-border-bottom-color: var(--color-primary-900);
			color: var(--color-primary-900);
		}

		.nav {

			&__current-link {
				--icon-display: inline;
				--icon-margin: 0 0 0 1rem;
				--icon-vertical-align: middle;

				--link-color: var(--color-primary-900);
				--link-color-focus: var(--color-tertiary-900);

				&:hover,
				&:focus {
					--link-color: var(--link-color-focus);
				}
			

				font-family: var(--heading-font-family);
				font-size: var(--font-size-h6);
				font-weight: var(--heading-font-weight);
				letter-spacing: var(--letter-spacing-h6);
				line-height: 1.2;
			

			}


			&__wrap {
				&--parent {
					display: inline-flex;
					margin-right: calc( var(--nav-parent-link-spacing) * -1 );

				}

				&--child {
					font-size: var(--small-font-size);
					line-height: var(--small-line-height);
					letter-spacing: var(--small-letter-spacing);
					padding: 6rem var(--wrapper-gap);
					display: var(--nav-wrap-child-display, none);
					position: absolute;
					background-color: var(--color-white);
					overflow-x: hidden;
					overflow-y: auto;
					-webkit-overflow-scrolling: touch;
					max-height: 80vh;
					max-height: calc(100vh - 18rem);
					left: 0;
					right: 0;
					z-index: -1;
					box-shadow: var(--shadow-100);
					border-radius: 0 0 2rem 2rem;
					gap: 7.5rem 4%;
					grid-template-columns: repeat(5, minmax(0, 24.6rem));
					justify-content: end;


				}

				&--sub-child {
					font-size: var(--subtitle-font-size);
					line-height: var(--subtitle-line-height);
					letter-spacing: 0;
					font-weight: 500;
					display: grid;
					gap: 1rem;
				}
			}

			&__item {
			
				&--back-link {
					display: none;
				}

				&--parent {
					
					&:hover {
						--nav-wrap-child-display: grid;
						--nav-link-parent-helper: block;
						.nav__link--parent {
							--link-color: var(--link-color-focus);
						}
					}
					&:focus-within {
						//--nav-wrap-child-display: grid;
					}
				}

				&--child {
					
					&:nth-child(7) {
			
						grid-column: 2;
					}
				}
			}

			&__link {
				display: block;

				&--parent {
					font-weight: 600;
					white-space: nowrap;
					padding: 0 var(--nav-parent-link-spacing);
					position: relative;

					&:before {
						content: "";
						display: var(--nav-link-parent-helper, none);
						background-color: transparent;
						z-index: 1;
						height: calc(100% - 2rem);
						top: 2rem;
						left: -8rem;
						right: -8rem;
						border-radius: 50% 50% 0% 100% / 100% 100% 0% 0%;
						position: absolute;
						opacity: .2;
					}

					.nav__text {
						display: block;
						height: 4.7rem;
						border-bottom: .5rem solid var(--nav-parent-active-border-bottom-color, transparent);
					}

					&.open {
						--link-color: var(--link-color-focus);
						~ .nav__wrap {
							--nav-wrap-child-display: grid;
						}
					}
				}

				&--child {
					color: var(--color-primary-900);
					border-bottom: .1rem solid var(--color-grey-100);
					margin-bottom: 1.5rem;
					max-height: 1lh;
					display: flex;
					align-items: end;
				
					&:hover,
					&:focus {
						color: var(--link-color-focus);
					}

				}
			}

			&__toggle {
				display: none;
			}

		}
	}

	// 1400 and greater 
	@include mq.mq($from: menularge) {
		--nav-parent-link-spacing: 1.6rem;
		
		font-size: inherit;
	}
}