/* style for internet-abo form */

.internet-abo {}

[data-offer-description] {
    display: var(--offer-description-display, none);
}

// use the power of has(), no js needed:
[data-toggle-offer-description] {
    @for $i from 1 through 5 {
      &:has([data-option]:nth-child(#{$i}) :checked) [data-offer-description]:nth-child(#{$i}) {
        --offer-description-display: block;
      }
    }
  }