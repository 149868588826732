@use "../mixins/mq";


.channel-teasers {

    &__header {
        text-wrap: balance;
    }

    &__mosaik {
        display: grid;
        gap: 5rem var(--grid-gap);
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &__item {
        //max-width: var(--channel-teasers-max-width);
        margin: 0;
        padding: 0;
        &--auto-mosaik {
            //1
            &:nth-child(1) {
                //--channel-teasers-max-width: calc(100% / 12 * 8);
                --channel-teasers-max-width: calc(100% / 12 * 9.5);
               

                @include mq.mq($from: medium) {
                    --channel-teasers-max-width: unset;
                }
            }

            //2 
            //default
            
            &:nth-child(3) {
                //--channel-teasers-max-width: calc(100% / 12 * 6);
                --channel-teasers-max-width: calc(100% / 12 * 7.5);
                --channel-teasers-margin-top: calc(6 / 10 * 100%);

                @include mq.mq($from: medium) {
                    --channel-teasers-max-width: unset;
                }
            }
        }

        &:hover {
            color: var(--color-accent-01);
        }
    }

    @include mq.mq($from: medium) {
        &__header {
            max-width: 50%;
            padding-right: calc(var(--grid-gap) / 2);
            text-wrap: balance;
        }

        &__mosaik {
            grid-template-columns: 4fr 5fr 3fr;
            grid-template-areas: "a b c";
        }

        &__item {
            &--auto-mosaik {
                max-width: none;
                margin-top: var(--channel-teasers-margin-top);

                &:first-child {
                    grid-area: b;
                }
                &:nth-child(2) {
                    grid-area: a;
                    --channel-teasers-margin-top: calc(9 / 40 * 100%);
                }
                
            }
        }

    }

}