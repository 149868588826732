@use "../mixins/mq";

.action-flex {
    --list-item-padding-left: 0;
    --list-item-margin-top: 0;

    display: flex;
    flex-wrap: wrap;
    justify-content: var(--action-flex-justify-content, center);
    gap: var(--action-flex-gap, 2rem 1.5rem );
    margin: 0;
    padding: 0;
    list-style: none;

    @include mq.mq($from: medium) {
        --action-flex-gap: 2.5rem;
    }
}