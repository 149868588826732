@use "../mixins/mq";
@use "../atoms/grid";

.heading {	
	display: grid;
	gap: 5rem var(--grid-gap);


	&__hgroup {
		color: var(--color-primary-900);
	}

	&__action {
	//	margin-top: 2.5rem;
	}

	&__breadcrumb {
		position: absolute;
		top: -4rem;
		left: 0;
		right: 0;
		&:empty {
			display: none;
		}	
	}

	&__text {
		min-width: 0;
		position: relative;
	
	}

	&__pull-out {
		//pull to edge
		margin-inline: var(--wrapper-gap-negative)
		
	}

	&__aside {
		
		position: relative;
		//height: 43rem;
		aspect-ratio: .9/1;
		//margin: 0 auto;
		width: 100%;
		//max-width: 60rem;
	
	}

	&__cover {
		--img-bg-color: var(--color-skeleton);
		position: absolute;
		width: 100%;
		height: 100%;
		inset: 0;
		max-width: none;
		

			&--mask {
				-webkit-mask-image: var(--mask-clip);
				-webkit-mask-size: auto 100%;
				-webkit-mask-repeat: no-repeat;
				-webkit-mask-position: center center;
			}


	}
 

	@include mq.mq($from: medium) {
		grid-template-columns: repeat(2, 1fr);	
		align-items: center;

		margin-top: calc( var(--section-spacing-top) * -1);

		&__pull-out {
			//pull to left
			order: -1;
			margin: 0 0 auto;
			
		}

		&__aside {
			margin: 0 0 auto var(--wrapper-gap-negative);
			width: auto;
			max-width: none;
			height: 50rem;
			margin-bottom: auto;



		}

	

		&__cover {
			//width: calc( 50vw - 2.5rem);
			//left: auto;

			-webkit-mask-size: auto 120%;
			-webkit-mask-repeat: no-repeat;
			-webkit-mask-position: bottom right;
		}

	}

	@include mq.mq($from: large) {

		&__breadcrumb {
			top: -5rem;
		}

		&__aside {
			height: 70rem;
			aspect-ratio: auto;
		}
		&__cover {
			left: auto;
		}
	}

	@include mq.mq($from: xLarge) {
		&__aside {
			height: 43vw;
			max-height: calc( 100vh - 10rem);
			
		}
		&__cover {
			width: calc( 50vw - 2.5rem);
	
			//max-width: 116rem;
			
		}
	}
}
