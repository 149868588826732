.range__container {
  display: flex;
  flex-direction: column;
  margin: 0;
}

.range__sliders {
  position: relative;
  margin-top: 2rem;
  height: 2.3rem;
}

.range__tooltips {
  position: relative;
  display: flex;
  justify-content: space-between;
}

.range__input::-moz-range-thumb {
  appearance: none;
  pointer-events: all;
  width: 2.4rem;
  height: 2.4rem;
  background-color: var(--color-primary-900);
  border-radius: 50%;
  box-shadow: 0 0 0 0 var(--color-primary-900);
  cursor: pointer;
  transition: box-shadow var(--animation-duration) var(--animation-ease);
}

.range__input::-webkit-slider-thumb {
  appearance: none;
  pointer-events: all;
  width: 2.4rem;
  height: 2.4rem;
  background-color: var(--color-primary-900);
  border-radius: 50%;
  box-shadow: 0 0.4rem 0.6rem 0 rgba(0, 0, 0, 0.25);
  cursor: pointer;
  transition: box-shadow var(--animation-duration) var(--animation-ease);
}

.range__input::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 1rem rgba(0, 104, 180, 0.3);
}

.range__input::-webkit-slider-thumb:active {
  box-shadow: 0 0 0 1rem rgba(0, 104, 180, 0.3);
}

.range__input {
  -webkit-appearance: none;
  appearance: none;
  height: 0.6rem;
  width: 100%;
  position: absolute;
  border-radius: 0.3rem;
  background-color: #C6C6C6;
  pointer-events: none;

  &.from-slider {
    height: 0;
    z-index: 1;
    transform: translateY(.3rem);
  }

  &.to-slider {
    background: var(--range-background);
  }
}


.range__label {
  padding: 0 1rem;
}

.range {
  &__tooltip {
    position: absolute;
    display: flex;
    align-items: flex-end;
    height: 3.4rem;
    padding: 0 1rem;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 3.4rem;
    border-radius: 0.5rem;
    color: var(--color-white);
    overflow: visible;

    &--from {
      left: var(--left-position, 0);
      transform: translateX(-50%);
    }

    &--to {
      left: var(--right-position, 100%);
      transform: translateX(-50%);
    }

    &-text {
      position: relative;
      display: block;
      height: 2.9rem;
      min-width: 4.8rem;
      line-height: 2.9rem;
      border-radius: 0.5rem;
      background-color: var(--color-primary-900);
    }
  }

  &__icon {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    height: 3.4rem;
    width: 4.8rem;
    min-width: unset;
  }
}