@use "../mixins/mq";

.key-value {
    --icon-width: 6rem;
    --icon-height: 6rem;
    --key-value-icon-color: var(--color-white);

    overflow: hidden;
    padding: var(--key-value-padding,3.5rem 2.5rem 2.5rem 2.5rem);
    line-height: 1.5;
    color: var(--color-accent-01);
    background-color: var(--key-value-bg-color, var(--color-accent-06));
    border-radius: var(--rounded-l);

    font-size: 2rem;
    font-family: var(--heading-font-family);

    &--no-box {
        --key-value-padding: 0;
        --key-value-bg-color: none;
        --key-value-icon-color: var(--color-accent-04);
    }

    &--big {
        // big
        display: grid;
        justify-content: center;

    }

    &__list {
        list-style: none;
        margin: 0;
        display: grid;
        gap: 5rem;
        padding: 0;
    }

    &__item {
        margin: 0;
        padding: 0;
    }

    &__counter {
        line-height: 1.3;
        font-size: var(--key-value-counter-font-size, 5rem);
        font-weight: 600;
        display: block;

        &--primary {
            line-height: 1;
            margin-bottom: .5rem;
            font-size: var(--key-value-counter-primary-font-size, 8rem);
        }

        &--big {
            line-height: 1;
            font-size: var(--key-value-counter-big-font-size, 8.7rem);
        }

        &--with-unit {
            --key-value-counter-primary-font-size: 6rem;
            --key-value-counter-big-font-size: 6rem;
        }
    }

    &__icon {
        display: inline-block;
      
        // --icon-color: var(--color-white);
        color: var(--key-value-icon-color);

    }

    &__counter {
        white-space: nowrap;
        display: inline-block;
    }

    &__digits {
        position: relative;
        text-align: right;
        display: inline-block;
        vertical-align: bottom;
        //hack
        font-variant-numeric: tabular-nums;

        &::after {
            content: attr(data-final-value);
            position: relative;
            height: 0;
            display: block;
            overflow: hidden;
        }
    }


    &__description {
        max-width: 70rem;
    }

    @include mq.mq($from: medium) {

        padding: var(--key-value-padding, 4rem 5rem);
        line-height: 1.6;

        &__list {

            &--primary {
                align-items: center;
                grid-template-columns: 1fr 1fr;
            }

        }


    }

    @include mq.mq($from: large) {
        --icon-width: 7rem;
        --icon-height: 7rem;

        padding: var(--key-value-padding, 8rem);
        font-size: 2.2rem;
        //padding-left: 12rem;

        &--big {
            --icon-width: 10rem;
            --icon-height: 10rem;
        }

        &__list {
            &--primary {
                --icon-width: 10rem;
                --icon-height: 10rem;

                grid-template-columns: auto 40%;
            }
        }

        &__icon {
           
        }

        &__digits {
            &:empty {
                display: none;
            }
        }

        &__item {
            &--primary {
                max-width: var(--key-value-item-primary-max-width, 70rem);
            }
        }

        &__counter {
            font-size: var(--key-value-counter-font-size, 10rem);
            line-height: 1.3;

            &--primary {
                font-size: var(--key-value-counter-primary-font-size, 17rem);
                line-height: 1;

            }

            &--big {
                line-height: 1;
                font-size: var(--key-value-counter-big-font-size, 29rem);
            }

            &--with-unit {
                --key-value-counter-font-size: 8rem;
                --key-value-counter-primary-font-size: 12rem;
                --key-value-counter-big-font-size: 18rem;
            }
        }
    }

}