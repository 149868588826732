@use "../mixins/mq";

.section {
	--section-spacing-top: var(--section-spacing);
	--section-spacing-bottom: var(--section-spacing);

	position: relative;
	margin-top: var(--section-spacing-top);
	margin-bottom: var(--section-spacing-bottom);
	background-color: var(--section-bg-color,none);
	padding-block: var(--section-inner-spacing, 0);

	&--spacing-narrow {
		--section-spacing: var(--section-spacing-xs);
	}

	&--spacing-small {
		--section-spacing: var(--section-spacing-s);
	}

	&--spacing-large {
		--section-spacing: var(--section-spacing-l);
	}

	&--overflow-hidden {
		overflow: hidden;
	}

	&--no-spacing {
		--section-spacing: 0;
	}

	&--gradient {
		--section-inner-spacing: var(--section-spacing); 
		overflow: clip;
		position: relative;

	}
	&__gradient-bg {
		position: absolute;
		inset: 0;
		
	}

	&--inner-spacing {
		--section-inner-spacing: var(--section-spacing); 
	}

	&--bg-round {
		--section-inner-spacing: var(--section-spacing);
		--logo-item-background: var(--color-white);
		background-color: var(--color-accent-05);
	}

	&--bg-accent {
		--section-inner-spacing: var(--section-spacing);
		--logo-item-background: var(--color-white);
		background-color: var(--color-accent-06);
	}

	&--full-collapsing {
		//--section-spacing-top: 0;
		//--section-spacing-bottom: 0;
		//experimental
		//padding-bottom: var(--section-spacing-bottom);
		margin-block: 0;

		&:last-child {
			padding-bottom: calc(var(--section-inner-spacing) + var(--footer-border-radius));
			margin-bottom: var(--footer-negative-radius-size);
		}
	}

	&__boxed {
		position: relative;
		background-color: var(--section-boxed-bg-color);
		max-width: var(--section-boxed-max-width);
		border-radius: var(--section-boxed-border-radius, none);
		margin-inline: var(--section-boxed-margin-inline, auto);
		padding-block: var(--section-boxed-padding-block, 0);
		padding-inline: var(--section-boxed-padding-inline, 0);
		//overflow: auto;
		@include mq.mq($from: medium) {
			//border-radius: var(--rounded-l);
			//padding-block: .1rem;
			//margin-inline: 2rem;
		}

		&--primary {
			--section-boxed-bg-color: var(--color-primary-700)
		}

		&:has(+ .section__boxed) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		& + .section__boxed {
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}


	&__sticker {
		position: absolute;
		z-index: 1;
		right: 0;
		transform: translate(var(--wrapper-gap), -50%);


		&--spade {
			transform: none;
			aspect-ratio: 10 / 1.5;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
	
		}
		@include mq.mq($from: medium) {
			transform: translate(0, -50%);
			// transform: translate(-2rem, -44%);

			&--spade {
				transform: translate(0, 50%);
			}
		}

	}

/*
	&--spacing-top {
		--section-spacing-bottom: 0;
	}
	&--spacing-bottom {
		--section-spacing-top: 0;
	}
*/
}