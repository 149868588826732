@use "../mixins/mq";

.logos-section--has-bg {
	--section-inner-spacing: 2.5rem 5rem;

	@include mq.mq($from: large) {
		--section-inner-spacing: 5rem 10rem;
	}
}

.logos-title {
	margin-bottom: 2.5rem;

	@include mq.mq($from: large) {
		margin-bottom: 5rem;
	}
}

.logo {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	padding: 2rem;

	&__img {
		max-height: 6.4rem;
		width: auto;
	}

	&-list {
		--spacing-list-top: 0;
		--list-margin-left: 0;
		--list-item-margin-top: 0;
		--list-item-padding-left: 0;

		display: flex;
		flex-wrap: wrap;
		align-content: center;
		justify-content: center;
		gap: var(--logos-list-gap);
		list-style: none;
	}

	&-item {
		aspect-ratio: 166/94;
		width: calc((100% - (#{var(--logos-list-gap)})) / 2);
		background-color: var(--logo-item-background, none);
		border-radius: var(--rounded-s);
		overflow: clip;
		transition: box-shadow var(--animation-ease) var(--animation-duration);

		@include mq.mq($from: medium) {
			width: calc((100% - (#{var(--logos-list-gap)} * 3)) / 4);
		}

		@include mq.mq($from: large) {
			width: calc((100% - (#{var(--logos-list-gap)} * 5)) / 6);
			aspect-ratio: 196/110;
		}

		&:hover {
			box-shadow: var(--shadow-100);
		}
	}
}
