[data-scroll-shadow] {
    --scroll-shadow-size: 3rem;
    
    background-attachment: scroll, scroll;
    background-repeat: no-repeat;
    background-position: left top, top, right top, bottom;
    background-size: 
        var(--scroll-shadow-size) var(--scroll-shadow-height,100%), 
        var(--scroll-shadow-width,100%) var(--scroll-shadow-size),
        var(--scroll-shadow-size) var(--scroll-shadow-height,100%), 
        var(--scroll-shadow-width,100%) var(--scroll-shadow-size);
    background-image:
        linear-gradient(-90deg, transparent, rgba(0, 0, 0, var(--scroll-shadow-left-opacity,0))),
        linear-gradient(0, transparent, rgba(0, 0, 0, var(--scroll-shadow-top-opacity,0))),
        linear-gradient(90deg, transparent, rgba(0, 0, 0, var(--scroll-shadow-right-opacity,0))),
        linear-gradient(-180deg, transparent, rgba(0, 0, 0, var(--scroll-shadow-bottom-opacity,0)));
}

