@use "../_config/colors";

.gradient {
    position: absolute;
    inset: 0;
    background: var(--color-gradient-300);
    background: linear-gradient(var(--gradient-lg-r,207deg), var(--gradient-bg-start-color) -10.52%, var(--gradient-bg-end-color) 83.67%);
    height: 100%;
    height: 100%;
    min-height: 4rem;
    overflow: hidden;

    --gradient-shape-width: auto;
    --gradient-shape-height: 150%;


    animation: var(--gradient-animation, none);


    &--variante {

        &-teaser {
            --gradient-shape-path-animation: var(--gradient-shape-path-animation-teaser);
            //--gradient-shape-path-animation: none;
            &-0 {
                --gradient-shape-group-transform:  translate(-33%, 0) rotate(-90deg) scale(1.3);
                //--gradient-shape-group-transform-origin: 20% 50%;
            }
            &-1 {
                --gradient-shape-group-transform: translate(33%, 0) rotate(90deg) scale(1.3);
                //--gradient-shape-group-transform-origin: 50% 100%;
            }
        }

    }

    /*
    &--variante-1 {
        --gradient-shape-right: 0;
        --gradient-shape-top: 0;
        --gradient-shape-bottom: 0;
        --gradient-shape-left: 0;
        --gradient-shape-width: auto;
        --gradient-shape-height: 100%;
    }
    */

   

    &__shape {
        position: absolute;
        right: var(--gradient-shape-right);
        top: var(--gradient-shape-top);
        left: var(--gradient-shape-left);
        bottom: var(--gradient-shape-bottom);
        min-width: 100%;
        min-height: 100%;
        width: var(--gradient-shape-width);
        height: var(--gradient-shape-height);
        max-height: 100%;
    }

    &__shape-group {
        transform: var(--gradient-shape-group-transform,none);
        transform-origin: var(--gradient-shape-group-transform-origin,50% 50%);
    }

    &__shape-path {

        animation: var(--gradient-shape-path-animation, none);
        transform-origin: 50% 50%;
    }

    &__stop-start-color {
        stop-color: var(--color-tertiary-900);
    }
    &__stop-end-color {
        stop-color: var(--color-primary-900);
    }

    &__overlay {
        position: absolute;
        inset: 0;
        background: var(--color-gradient-400);
    }
}

.gradient-animation-run {
    --gradient-animation: gradient-lg-rocking 320s ease-in-out infinite;
    --gradient-shape-path-animation: rocking 320s ease-in-out infinite;
    --gradient-shape-path-animation-teaser: rocking-variante-teaser 50s ease-in-out infinite;

    @media (prefers-reduced-motion: reduce) {
        --gradient-animation: none;
        --gradient-shape-path-animation: none;
        --gradient-shape-path-animation-teaser: none;
    }
}



@keyframes rocking {
    0%,100% {
        transform: rotate(0);
    }

    25% {
        transform: rotate(-167deg);
    }


    75% {
        transform: rotate(159deg);
    }

}

@keyframes rocking-variante-teaser {
    0%,100% {
        transform: rotate(10deg);

    }

    50% {
        transform: rotate(-25deg);

    }

}

@keyframes gradient-lg-rocking {
    0%,100% {
        --gradient-lg-r: 0deg;
    }

    50% {
        --gradient-lg-r: 360deg;
    }

}


@property --gradient-lg-r {
    initial-value: 0deg;
    inherits: false;
    syntax: '<angle>';
  }