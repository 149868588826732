@use "../mixins/mq";

.jobs {
  &__container {
    display: grid;


    @include mq.mq(large) {
      grid-template-columns: 40rem 1fr;
      gap: 8.6rem;

      &--simple {
        grid-template-columns: 1fr;
      }
    }
  }

  &__grid {
    min-width: .1em;
  }

  &__pager {
    margin-top: 3rem;

    @include mq.mq($from: large) {
      margin-top: 5rem;
    }
  }

  &__filter {
    --spacing-h5-bottom: 2rem;
    margin-bottom: 4rem;
  }

  &__filter-inner {
    margin-bottom: 1.8rem;
    padding: 2.5rem 2.5rem 7rem;
    background-color: var(--color-primary-800);
    border-radius: var(--rounded-l);
  }

  &__range {
    margin-top: 3.6rem;
  }

  &__sharing_buttons {
    max-width: var(--container-slim-width);
    margin: 0 auto 4rem;

    @include mq.mq($from: large) {
      position: absolute;
      --share-flex-direction: column;
    }
  }
}