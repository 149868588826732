.flatpickr-calendar {
    .flatpickr-day {
        &.endRange,
        &.selected,
        &.startRange {
            &,
            &.inRange,
            &.nextMonthDay,
            &.prevMonthDay,
            &:focus,
            &:hover {
                background: var(--color-primary-900);
                border-color: var(--color-primary-900);

            }
            
        }
        line-height: 3.7rem;
    }

    
    &:after,
    &:before {
        display: none !important;
    }
    .flatpickr-current-month input.cur-year,
    .flatpickr-current-month .flatpickr-monthDropdown-months {
        margin: 0;
        padding: 0 .5rem;
        height: 2.6rem;
        line-height: 1.2;
    }

    .numInputWrapper span,
    .numInputWrapper span:hover,
    .flatpickr-current-month .flatpickr-monthDropdown-months:hover {
        background-color: transparent;
        background-position: right center;
        background-repeat: no-repeat;
        background-size: 1rem 1rem;
        background-image: var(--input-select-arrow-bg);
    }
      


    .numInputWrapper span {
        &:after {
            display: none;
        }

        background-position: center center !important;
        opacity: 1;
        border: 0;

        &.arrowUp {
            transform: scaleY(-1);
        }
    }

    .numInputWrapper {
        padding-right: 2rem;
    }

    .numInputWrapper:hover {
        background: transparent;
    }

    .flatpickr-current-month {
        display: grid;
        grid-template-columns: 1fr 8rem;
        gap: 1rem;
    }

    .flatpickr-months .flatpickr-next-month:hover svg, .flatpickr-months .flatpickr-prev-month:hover svg {
        fill: var(--color-primary-900);
    }

    .flatpickr-current-month input.cur-year,
    .flatpickr-current-month .flatpickr-monthDropdown-months,
    .flatpickr-time input {
        font-size: 1.6rem;
        text-align: center;
    }

}
