.order-overview {
    font-weight: 500;
    
    &__heading {
        color: var(--order-overview-heading-color, var(--color-primary-900));
        margin-bottom: 2rem;
    }

    &__title {
        font-weight: 600;
    }

    &__icon {
        color: var(--color-tertiary-900);
    }

    &__detail {
        --spacing-text-bottom: 1rem;

        display: flex;
        gap: 1rem;
    }

    &__price {
        font-weight: 700;
        margin-left: auto;
        white-space: nowrap;
    }

    &__hr {
        border: none;
        margin: 2rem 0;
        height: 1px;
        background: var(--dashed-line) 0 0 repeat-x;
        background-size: 6px 1px;
    }
}
