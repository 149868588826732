@use "../atoms/field";

.autocomplete {


  &__wrapper {
    position: relative;
  }

  &__input {
    @extend .input;
  }

  &__option {
    --list-item-margin-top: 0;
    --outline-offset: 0;
    padding: 0 2rem;
    cursor: pointer;

    &:hover {
      background-color: var(--color-primary-900);
      color: var(--color-white);
    }
  }

  &__menu {
    --list-margin-left: 0;
    --spacing-list-top: 0;
    position: absolute;
    left: 0;
    top: 100%;
    list-style: none;
    width: 100%;
    padding: 1rem 0;
    max-height: 30rem;
    overflow: auto;
    border: 1px solid var(--input-border-color);
    background-color: var(--color-white);
    z-index: 2;

    &--hidden {
      display: none;
    }
  }
}

