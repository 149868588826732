@use "../mixins/mq";

.contact-box {
    --contact-box-symbol-shift: calc( var(--symbol-size) / 2 );
	position: relative;
	display: flex;
    flex-direction: var(--contact-box-flex-direction, column);
	gap: 0.5rem;
    min-width: var(--contact-box-min-width);
	max-width: var(--contact-box-max-width, 100%);
	border-radius: var(--rounded-m);
	background-color: var(--color-white);
	padding: var(--contact-box-padding-top, 2.5rem) 2.5rem 2.5rem;
    height: var(--contact-box-height, 100%);
    color: currentColor;

    margin-top: var(--contact-box-symbol-shift,0);



    &--has-no-symbol {
        --contact-box-symbol-shift: 0;
        --contact-box-height: auto;
        --symbol-size: unset;
        //--contact-box-padding-top: 2.5rem;

        @include mq.mq($from: medium) {
            --contact-box-padding-top: calc( var(--symbol-size) / 1.5);
            --contact-box-symbol-shift: calc( var(--symbol-size) / 2 );
            --contact-box-height: calc( 100% - var(--contact-box-symbol-shift) );
        }
    }

 
    &--has-symbol {
        //reserve the right space for grid or flex 
        --contact-box-height: calc( 100% - var(--contact-box-symbol-shift) );
        margin-top: var(--contact-box-symbol-shift,0);
        padding-top: calc( var(--symbol-size) / 1.5);
    }

    &__symbol {
        position: absolute;
        top: calc( var(--contact-box-symbol-shift) * -1);
        display: grid;
        align-items: center;
    }

    &__header {
        --spacing-bottom: .5rem;
        //for old browser
        //min-height: calc(var(--line-height-h5) * 1em * 2 );
        min-height: var(--contact-box-header-min-height);
        display: grid;
        align-items: center;
        font-weight: 600;
        color: var(--contact-box-header-color, currentColor);
      
    }

    &__body {
        position: relative;
        z-index: 1;
        color: var(--contact-box-color, currentColor);

        &--jobs {
            --contact-box-header-min-height: unset;
            --spacing-text-top: 0;
            --spacing-bottom: 0;
            --spacing-text-bottom: 0;
            --small-line-height: 1;
        }
    }

    &__footer {
        position: relative;
        z-index: 1;
        --spacing-action-top: 1.5rem;
        --spacing-action-bottom: 1.5rem;
        margin-top: auto;
        padding-top: var(--contact-box-footer-padding-top);
        color: var(--contact-box-footer-color);

        &--jobs {
            --spacing-text-top: 0;
            --spacing-text-bottom: 0;
            --contact-box-footer-padding-top: 2.5rem;
            font-size: 1.8rem;
        }
    }

    &__gradient {
        border-radius: var(--rounded-m);
        z-index: 0;
    }


    &--inverted {
        color: var(--color-white);
        --contact-box-header-color: currentColor;
    }
}
