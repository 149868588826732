@use "../_base/svg";
@use "../_config/colors";
@use "../_base/typography";
@use "../mixins/background-svg";
@use "../utils/visually-hidden";
@use "../mixins/mq";

.action {
	--icon-width: 2.2rem;
	--action-decoration-bg-color: var(--action-bg-color);
	position: relative;
	display: inline-flex;
	gap: 1.6rem;
	justify-content: var(--action-justify-content, center);
	align-items: center;
	max-width: 100%;
	overflow: visible;
	text-decoration: none;
	text-align: var(--action-text-align, center);
	color: var(--action-color);
	border-color: var(--action-border-color, currentColor);
	border-width: var(--action-border-width, .2rem);
	border-style: var(--action-border-style, none);
	border-radius: var(--action-border-radius, .2rem);
	background-color: var(--action-bg-color, none);
	padding: var(--action-padding, 0);
	min-width: var(--action-min-width, none);
	width: var(--action-width, auto);
	min-height: var(--action-min-height, none);
	height: var(--action-height, auto);
	font-size: var(--action-font-size, 1.8rem);
	line-height: var(--action-line-height);
	letter-spacing: var(--action-letter-spacing);
	font-weight: var(--action-font-weight, 500);
	font-family: var(--action-font-family, inherit);
	z-index: 1;
	flex-shrink: 0;
	text-wrap: balance;
	margin-left: var(--action-margin-left);
	margin-right: var(--action-margin-right);
	transition: background-color var(--animation-duration) var(--animation-ease), color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease);
	cursor: pointer;

	&__text {
		vertical-align: middle;
	}

	//for quicklinks
	&.highlighted {
		--action-color: var(--action-highlighted-color);
		--action-border-color: var(--action-highlighted-border-color, none);
		--action-bg-color: var(--action-highlighted-bg-color, none);

		--action-icon-after-transform: var(--action-highlighted-icon-after-transform, none);
	}

	//hover
	a:focus &,
	a:hover &,
	&:focus,
	&:hover,
	&.hover {
		--action-color: var(--action-hover-color);
		--action-border-color: var(--action-hover-border-color, none);
		--action-bg-color: var(--action-hover-bg-color, none);
		--action-icon-after-transform: var(--action-hover-icon-after-transform, none);

	}

	//disabled
	&[disabled],
	&.disabled,
	&:disabled {
		--action-color: var(--action-disabled-color);
		--action-border-color: var(--action-disabled-border-color, none);
		--action-bg-color: var(--action-disabled-bg-color, none);
	}

	//press
	&.active,
	&:active {
		--action-color: var(--action-active-color);
		--action-border-color: var(--action-active-border-color, none);
		--action-bg-color: var(--action-active-bg-color, none);
		--action-icon-after-transform: var(--action-active-icon-after-transform, none);
	}


}



.action-primary {
	--action-color: var(--color-white);
	--action-bg-color: var(--color-primary-900);
	--action-border-radius: var(--button-border-radius);
	--action-padding: var(--button-padding);
	--action-min-height: var(--button-min-height);


	//hover
	--action-hover-color: var(--color-white);
	--action-hover-bg-color: var(--color-tertiary-900);

	//active
	--action-active-color: var(--color-white);
	--action-active-bg-color: var(--color-accent-01);

	//disabled
	--action-disabled-color: var(--color-white);
	--action-disabled-bg-color: var(--color-disabled);

	:where(.inverted) &,
	&--inverted {
		--action-color: var(--color-black);
		--action-bg-color: var(--color-secondary-900);
	}

	//custom for menu
	&--700 {
		--action-color: var(--color-primary-900);
		--action-bg-color: var(--color-primary-700);
		font-weight: bold;
		gap: .8rem;
		font-size: 1.6rem;
		--action-min-height: 4.5rem;
	}

}

.action-secondary {
	--action-color: var(--color-primary-900);
	--action-border-style: solid;
	--action-bg-color: rgba(255, 255, 255, 0);
	--action-border-radius: var(--button-border-radius);
	--action-padding: var(--button-padding);
	--action-min-height: var(--button-min-height);

	//hover
	--action-hover-color: var(--color-white);
	--action-hover-bg-color: var(--color-tertiary-900);
	--action-hover-border-color: var(--color-tertiary-900);

	//active
	--action-active-color: var(--color-white);
	--action-active-bg-color: var(--color-accent-01);
	--action-active-border-color: var(--color-accent-01);

	//disabled
	--action-disabled-color: var(--color-white);
	--action-disabled-bg-color: var(--color-disabled);
	--action-disabled-border-color: var(--color-disabled);

	:where(.inverted) &,
	&--inverted {
		--action-color: var(--color-white);
	}

}


.action-tertiary {
	//use em to scale icon relative to font-size
	--icon-width: 0.834em;
	--action-color: var(--color-primary-900);
	--action-active-color: var(--color-accent-01);
	--action-hover-color: var(--color-tertiary-900);
	--action-disabled-color: var(--color-accent-03);
	--action-icon-after-transform: translateX(0rem);
	--action-hover-icon-after-transform: translateX(.5rem);
	--action-active-icon-after-transform: translateX(.5rem);
	--action-text-align: left;

	:where(.inverted) &,
	&--inverted {
		--action-color: var(--color-white);
		--action-active-color: var(--color-black);
		--action-hover-color: var(--color-tertiary-900);
		--action-disabled-color: var(--color-accent-03);
	}

	:where(.current-color) & {
		--action-color: currentColor;
		--action-active-color: currentColor;
		--action-hover-color: currentColor;
		--action-disabled-color: var(--color-grey-300);
	}
}


.action-quaternary {
	--action-color: var(--color-white);
	--action-bg-color: var(--color-primary-900);
	--action-border-radius: var(--button-border-radius);
	--action-padding: var(--button-padding);
	--action-min-height: var(--button-min-height);
	--action-text-align: left;

	//hover
	--action-hover-color: var(--color-white);
	--action-hover-bg-color: var(--color-tertiary-900);
	--action-hover-border-color: var(--color-tertiary-900);


	//active
	--action-active-color: var(--color-white);
	--action-active-bg-color: var(--color-accent-01);
	--action-active-border-color: var(--color-accent-01);


	//disabled
	--action-disabled-color: var(--color-white);
	--action-disabled-bg-color: var(--color-disabled);
	--action-disabled-border-color: var(--color-disabled);

	:where(.inverted) &,
	&--inverted {
		--action-color: var(--color-primary-900);
		--action-bg-color: var(--color-white);
	}

}

/* only for button in text */
.action-quaternary,
.action-tertiary,
.action-secondary,
.action-primary {
	&:has(+ .action) {
		--action-margin-right: var(--action-inline-margin, 2.5rem);
	}
}

//make anny link a fab link, need icon
.action--fab {
	padding-inline: 0;

	--action-min-width: var(--action-min-height);

	.action__text {
		@extend .visually-hidden;
	}
}

.action-stepper {
	--action-text-align: left;
	--action-decoration-content: "";
	--action-color: var(--color-primary-900);
	--action-active-color: var(--color-accent-01);
	--action-hover-color: var(--color-tertiary-900);
	--action-disabled-color: var(--color-accent-03);
	//bg
	--action-bg-color: var(--color-primary-700);
	--action-hover-bg-color: var(--action-color);
	--action-active-bg-color: var(--action-color);
	--action-disabled-bg-color: var(--action-color);
	--action-line-height: 1.3;

	--action-decoration-base-bg-image: var(--action-stepper-plus);
	--action-decoration-base-bg-image-active: var(--action-stepper-plus-active);

	//bg color only for pesudo element 
	background-color: transparent;


	&--decrease {
		--action-decoration-base-bg-image: var(--action-stepper-minus);
		--action-decoration-base-bg-image-active: var(--action-stepper-minus-active);
	}


	--action-decoration-bg-image: var(--action-decoration-base-bg-image);


	//hover
	a:focus &,
	a:hover &,
	&:focus,
	&:hover,
	&.hover,
	&[disabled],
	&.disabled,
	&:disabled,
	&.active,
	&:active {
		--action-decoration-color: var(--color-white);
		--action-decoration-bg-image-active: var(--action-decoration-base-bg-image-active);
	}

	:where(.inverted) &,
	&--inverted {
		--action-decoration-color: var(--color-black);
		--action-color: var(--color-white);
		--action-active-color: var(--color-accent-00);

	}


	&:before {
		content: "";
		color: var(--action-decoration-color, currentColor);
		display: grid;
		width: 5rem;
		min-width: 5rem;
		aspect-ratio: 1/1;
		background-color: var(--action-decoration-bg-color);
		border-radius: 50%;
		align-items: center;
		justify-content: center;
		font-weight: bold;
		font-size: 3.5rem;
		line-height: 1;
		transition: background-color var(--animation-duration) var(--animation-ease), color var(--animation-duration) var(--animation-ease);
		background-image: var(--action-decoration-bg-image-active, var(--action-decoration-bg-image));
		background-position: center center;
		background-repeat: no-repeat;
		background-size: 2.5rem 2.5rem;
	}
}


.action-label {
	--action-font-weight: 700;
	--action-line-height: var(--subtitle-small-line-height);
	--action-letter-spacing: var(--subtitle-small-letter-spacing);
	--action-font-size: var(--subtitle-small-font-size);
	--action-min-height: 2.9rem;

	--action-color: var(--color-white);
	--action-bg-color: var(--color-black);
	--action-border-radius: 1rem;
	--action-padding: .4rem 1.2rem;

	:where(.inverted) &,
	&--inverted {
		--action-color: var(--color-black);
		--action-bg-color: var(--color-secondary-900);
	}

	//hover
	--action-hover-color: var(--color-white);
	--action-hover-bg-color: var(--color-tertiary-900);

	//active
	--action-active-color: var(--color-white);
	--action-active-bg-color: var(--color-accent-01);

	//disabled
	--action-disabled-color: var(--color-white);
	--action-disabled-bg-color: var(--color-disabled);


}


.action-filter {
	--action-color: currentColor;
	--action-border-style: solid;
	--action-bg-color: rgba(255, 255, 255, 0);
	--action-border-radius: 1.5rem;
	--action-padding: .5rem 1.5rem;
	--action-min-height: 4.4rem;
	--action-line-height: 1.5;
	--action-font-family: var(--font-family-secondary);
	--action-font-weight: 600;
	--action-font-size: var(--font-size-h6);
	//hover
	--action-hover-color: var(--color-white);
	--action-hover-bg-color: var(--color-accent-01);
	--action-hover-border-color: var(--color-accent-01);

	//active
	--action-active-color: var(--color-white);
	--action-active-bg-color: var(--color-black);
	--action-active-border-color: var(--color-black);

	//disabled
	--action-disabled-color: var(--color-white);
	--action-disabled-bg-color: var(--color-disabled);
	--action-disabled-border-color: var(--color-disabled);

	:where(.inverted) &,
	&--inverted {
		//--action-color: var(--color-white);
	}

}


.action-quicklink {
	--action-color: var(--color-accent-01);
	--action-bg-color: var(--color-primary-700);
	--action-border-radius: 1.5rem;
	--action-padding: 1.5rem 1.5rem;
	--action-min-height: 10rem;
	--action-width: 100%;
	--action-font-weight: 600;
	--action-letter-spacing: 0.036rem;
	--action-font-size: auto;
	--action-text-align: left;
	--action-justify-content: start;
	--action-height: 100%;
	//hover
	--action-hover-color: var(--color-white);
	--action-hover-bg-color: var(--color-tertiary-900);

	//active
	--action-active-color: var(--color-white);
	--action-active-bg-color: var(--color-accent-01);

	//active
	--action-highlighted-color: var(--action-active-color);
	--action-highlighted-bg-color: var(--action-active-bg-color);

	//disabled
	--action-disabled-color: var(--color-white);
	--action-disabled-bg-color: var(--color-disabled);

	--icon-width: 3rem;
	--icon-height: 3rem;


	//force color to all svg
	&:hover,
	&:focus,
	&.hover,
	&[disabled],
	&.disabled,
	&:disabled,
	&.highlighted,
	&.active,
	&:active {

		[stroke]:not([stroke=none]) {
			stroke: currentColor;
		}

		[fill]:not([fill=none]) {
			fill: currentColor;
		}

		circle[fill="#fff"]:not([fill=none]),
		circle[fill="#FFFFFF"]:not([fill=none]) {
			fill: var(--action-hover-bg-color);
		}

	}


	@include mq.mq($from: large) {
		--icon-width: 5rem;
		--icon-height: 5rem;
		--action-min-height: 12.5rem;
	}

}


.action__icon {
	&--after {
		--icon-transform: var(--action-icon-after-transform);
	}

	&--end {
		--icon-margin: auto 0 0 auto;
		--icon-width: 2rem;
		--icon-height: 2rem;
	}
}

.action--block {
	width: 100%;
}


.action--break-out {
	position: static;

	&:after {
		content: "";
		position: absolute;
		display: block;
		inset: 0;
	}
}


.action-wrap {
	display: flex;

	&--center {
		justify-content: center;
	}

	// fix issue with 2 actions
	&--flow {
		flex-wrap: wrap;
		--action-margin-left: 0;
		gap: 1.5rem 0;
	}
}

.action-reset {
	--action-font-size: 1.4rem;
	--icon-width: 2rem;
	--action-font-weight: 700;
	--action-color: var(--color-primary-900);
	--action-hover-color: var(--color-tertiary-900)
}

.action-bordered {
	--action-padding: 0.5rem 2rem;
	--action-justify-content: start;
	--action-width: 100%;
	--action-height: 100%;
	--action-text-align: left;
	--action-min-height: 8rem;
	--action-border-width: 0.1rem;
	--action-border-radius: var(--rounded-m);
	--action-border-style: solid;
	--action-bg-color: var(--color-white);
	--action-color: var(--color-accent-01);
	--action-font-weight: 600;
	--action-hover-color: var(--color-white);
	--action-hover-bg-color: var(--color-primary-900);
	--action-border-color: var(--color-primary-900);
	--icon-width: 3rem;
	--icon-color: var(--color-tertiary-900);

	hyphens: auto;

	&:hover,
	&:focus {
		--icon-color: var(--color-white);
	}

	@include mq.mq($from: large) {
		--action-min-height: 10rem;
		--action-padding: 0.5rem 2.5rem;
	}

	.icon {
		color: var(--icon-color);
		//fill: var(--color-tertiary-900);
	}

	[fill="black"] {
		fill: var(--icon-color);
	}

	&:focus,
	&:hover {

		[stroke="#005285"],
		[stroke="#00a1e5"] {
			stroke: var(--icon-color);
		}
	}





}