@use "../_config/colors";
@use "../mixins/mq";

$sizes: h1, h2, h3, h4, h5, h6;

@each $size in $sizes {
  #{$size},
  .#{$size} {
    --spacing-top: var(--spacing-#{$size}-top,0);
    --spacing-bottom: var(--spacing-#{$size}-bottom,0);
    color: var(--color-#{$size}, currentColor);
    display: block;
    font-family: var(--heading-font-family);
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    //font-weight: var(--font-weight-#{$size});
    font-weight: var(--heading-font-weight);
    letter-spacing: var(--letter-spacing-#{$size});
    margin-top: var(--spacing-top);
    margin-bottom: var(--spacing-bottom);
    text-wrap: balance;

    .no-margin & {
      --spacing-top: 0;
      --spacing-bottom: 0;
    }

    &:first-child {
      --spacing-top: 0;
    }

    &:last-child {
      --spacing-bottom: 0;
    }

    .subtitle-small + & {
      --spacing-top: 1rem;
    }
  }



  .font-size-#{$size} {
    font-size: var(--font-size-#{$size});
    line-height: var(--line-height-#{$size});
    letter-spacing: var(--letter-spacing-#{$size});
  }
}

.font-family-secondary {
  font-family: var(--font-family-secondary);
}


//for blog and communication, reduced the heading by one level
.reduced-heading {
  @for $i from 1 through 5 {
    --font-size-h#{$i}: var(--font-size-h#{$i}-reduced);
    --line-height-h#{$i}: var(--line-height-h#{$i}-reduced);
    --letter-spacing-h#{$i}: var(--letter-spacing-h#{$i}-reduced);
    --spacing-h#{$i}-top: var(--spacing-h#{$i}-top-reduced);
    --spacing-h#{$i}-bottom: var(--spacing-h#{$i}-bottom-reduced);
  } 
}

strong,
b {
  font-weight: bold;
  letter-spacing: var(--bold-letter-spacing);
}


.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.text-align-justify {
  text-align: justify;
}


.text-white {
  color: var(--color-white);
}

.align-end {
  align-self: flex-end;
}

.lead-text {
  font-family: var(--lead-text-font-family);
  font-size: var(--lead-text-font-size);
  line-height: var(--lead-text-line-height);
  letter-spacing: var(--lead-text-letter-spacing, normal);
  font-weight: var(--lead-text-font-weight);
}

.small-text {
  font-family: inherit;
  font-size: var(--subtitle-small-font-size);
  line-height: var(--subtitle-small-line-height);
  letter-spacing: var(--subtitle-small-letter-spacing);
}

small,
.subtitle,
.small {

  --spacing-text-top: .5rem;
  --spacing-text-bottom: .5rem;

  font-family: inherit;
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  letter-spacing: var(--small-letter-spacing);
}

.subtitle {
  font-family: inherit;
  font-size: var(--subtitle-font-size);
  line-height: var(--subtitle-line-height);
  letter-spacing: var(--subtitle-letter-spacing);
}


.subtitle-small {
  font-family: inherit;
  font-size: var(--subtitle-small-font-size);
  line-height: var(--subtitle-small-line-height);
  letter-spacing: var(--subtitle-small-letter-spacing);
}

.media {
  display: block;
  container-type: inline-size;
  margin-top: var(--media-spacing-top);
  margin-bottom: var(--spacing-media-bottom);
  &:first-child {
    --media-spacing-top: 0;
  }
  &:last-child {
    --spacing-media-bottom: 0;
  }
  &__caption {
    padding-inline: var(--spacing-media-caption-padding-inline, 0);
    display: var(--spacing-media-caption-display, inline);
    margin-top: var(--spacing-media-caption);
    font-size: var(--subtitle-font-size);
    line-height: var(--subtitle-line-height);
    letter-spacing: var(--subtitle-letter-spacing);
    color: var(--color-grey-400);
    font-weight: 500;
    white-space: normal;

  }
}

.custom-heading {
  max-width: calc(100% - 14rem);
  @include mq.mq($from: medium) {
    max-width: 50%;
    text-wrap: balance;
    padding-right: 1.5rem;
  }
}

.uppercase {
  text-transform: uppercase;
}

.font-color-white {
  color: var(--color-white);
}

blockquote {
  quotes: "«" "»";
  font-size: var(--lead-text-font-size);
  line-height: var(--lead-text-line-height);
  letter-spacing: var(--lead-text-letter-spacing);
  font-weight: var(--lead-text-font-weight);
  color: var(--color-accent-01);
  margin-top: var(--spacing-blockquote-top);
  margin-bottom: var(--spacing-blockquote-bottom);

  &:first-child {
    --spacing-blockquote-top: 0;
  }
  &:last-child {
    --spacing-blockquote-bottom: 0;
  }

  &:before {
    content: open-quote;
  }
  &:after {
    content: close-quote;
  }

  :where(*) {
    display: inline;
  }
}

sup {
  vertical-align: super;
  font-size: x-small;
}

sub {
  vertical-align: sub;
  font-size: x-small;
}

figcaption {

}

.link,
button,
a {
  color: var(--link-color, inherit);
  text-decoration: var(--link-text-decoration, underline);
  transition: color var(--animation-duration) var(--animation-ease);
  will-change: color;
  hyphens: inherit;

  &.focus,
  &:focus,
  &:hover {
    --link-text-decoration: var(--link-text-decoration-active, underline);
    --link-color: var(--link-color-focus);
  }
}

.link {
  &--search {
    --icon-width: 1em;
    --icon-height: 1em;
    
    align-items: center;
    display: inline-flex;
    gap: .5rem;
  }
}


p {
  margin-top: var(--spacing-text-top);
  margin-bottom: var(--spacing-text-bottom);

  &:has(.action-primary),
  &:has(.action-secondary),
  &:has(.action-quaternary){
    --spacing-text-top: var(--spacing-action-top);
    --spacing-text-bottom: var(--spacing-action-bottom);
  }

  &:first-child {
    --spacing-text-top: 0;
  }
  &:last-child {
    --spacing-text-bottom: 0;
  }

}



::marker {
  color: var(--marker-color,currentColor);
  transition: color var(--animation-duration) var(--animation-ease);
  font-weight: 600;
  font-size: 150%;
  line-height: 1;
}


ul, ol {
  margin-top: var(--spacing-list-top,0);
  margin-bottom: var(--spacing-list-bottom,0);

  margin-left: var(--list-margin-left,0);

  &:first-child {
    --spacing-list: 0;
    --spacing-text-top: 0;
  }
  &:last-child {
    --spacing-list-bottom: 0;
  }



  ul, ol {
    --spacing-list-top: .2rem;
    --spacing-list-bottom: .2rem;
  }

}

.unstyled-list {
  list-style: none;
  --list-item-padding-left: initial;
  --list-margin-left: initial;
  --list-item-margin-top: var(--unstyled-list-item-margin-top);
}

.reset-list {
  list-style: none;
  --spacing-list: initial;
  --spacing-list-top: none;
  --list-item-padding-left: initial;
  --list-margin-left: initial;
  --list-item-margin-top: initial;
}


ol {
  //list-style-type: decimal-leading-zero;
  ::marker {
    font-size: inherit;
  }
}

ol ol {
  list-style-type: lower-latin;
}


li {
  min-height: var(--list-item-min-height);
  padding-left: var(--list-item-padding-left,0);
  margin-top: var(--list-item-margin-top,0);
  counter-increment: list-counter;
  border-style: var(--list-item-border-style);
  border-width: var(--list-item-border-width, 0);
  border-color:  var(--list-item-border-color, currentColor);
}

.link {

  &--icon {
    display: inline-flex;
    align-items: center;
    gap: 0 1.2rem;
    --icon-height: 1em;
    --icon-width: 1em;

    --link-color: currentColor;
    --link-color-active: var(--color-brand);
    --link-text-decoration-active: none;
  }
}

.check-list {
  //list-style-image: var(--check-list);
  --list-margin-left: var(--check-list-margin-left,0);
  --list-item-padding-left: 4rem;
  --list-item-before-content: '';
  --list-item-position: relative;
  list-style: none;
}

.list-dashed {
  --list-item-padding-left: 3.5rem;
  --list-item-border-width: 0;
  --list-item-before-top: 50%;
  --list-item-before-transform: translateY(-50%);
  --list-item-margin-top: 0;
  --list-item-padding-vertical: 1rem;
  --list-item-background: var(--dashed-line) 0 0 repeat-x;
  //list-style-position: inside;

  &:has(+ *) {
    background: var(--dashed-line) 0 bottom repeat-x;
    background-size: 6px 1px;
  }

  &--no-sibling {
    background: none;
  }

  &:first-child {
    --list-item-border-width: .1rem 0 .1rem 0;

  }
}

li {
  position: var(--list-item-position);
  padding-block: var(--list-item-padding-vertical);
  background: var(--list-item-background);
  background-size: 6px 1px;

}

li:before {
  position: absolute;
  left: 0;
  top: var(--list-item-before-top);
  display: block;
  content: var(--list-item-before-content);
  width: var(--list-item-before-width,1.1em);
  height: var(--list-item-before-height,1.1em);
  background: var(--check-list) center center no-repeat;
  background-size: contain;
  transform: var(--list-item-before-transform);
}

// font weight
$font-weight-mapping: (
  100: 'thin',
  200: 'extra-light',
  300: 'light',
  400: 'regular',
  500: 'medium',
  600: 'semi-bold',
  700: 'bold',
  800: 'extra-bold',
  900: 'black',
);

@each $weight, $name in $font-weight-mapping {
  .font-weight-#{$weight},
  .font-weight-#{$name} {
    font-weight: $weight;
  }
}

.font-color-primary {
  color: var(--color-primary-900);
}


