.tag {
  --tag-color: var(--color-accent-01);
  --tag-border-color: var(--color-accent-01);
  --tag-background: unset;

  display: inline-flex;
  gap: 1rem;
  min-height: 2.8rem;
  padding: 0.1rem 1rem 0;
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--tag-color);
  border-radius: 1rem;
  background-color: var(--tag-background);
  border: 1px solid var(--tag-border-color);

  &--status {
    --tag-color: var(--color-black);
    --tag-border-color: var(--color-primary-700);
    --tag-background: var(--color-primary-700);
  }

  &__icon {
    width: 1.5rem;
  }
}