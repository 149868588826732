@use "../mixins/mq";

.sticker {
    font-family: var(--heading-font-family);
    display: flex;
    flex-direction: column;
    //align-items: center;
    justify-content: center;
    width: 15rem;
    aspect-ratio: 1;
    isolation: isolate;
    background-color: var(--sticker-bg-color);
    border-radius: var(--sticker-border-radius);
    color: var(--sticker-color, currentColor);
    padding: var(--sticker-padding,1.6rem);
    filter: drop-shadow(var(--sticker-drop-shadow));
    font-weight: 600;
    text-decoration: none;
    overflow: clip;
    transform: var(--sticker-transform);
    animation: var(--sticker-animation);
    animation-play-state: var(--sticker-animation-play-state,paused);
    rotate: 0deg;

    .animation-run & {
        --sticker-animation: stickerSwing 3s;
        --sticker-animation-play-state: running;
    }

    &--has-decoration {
        --sticker-bg-color: transparent;
        --sticker-border-radius: none;
        --sticker-color: var(--color-primary-900);
        --sticker-decoration-color: var(--color-white);
        aspect-ratio: 273/189;
        width: 17.5rem;
        // transform-origin: bottom center;
        padding: 2.2rem 1.5rem 1rem 3.5rem;
    }

    &--secondary {
        --sticker-color:var(--color-withe);
        --sticker-decoration-color:  var(--color-secondary-900);
    }

    &__body {
        isolation: isolate;
        max-height: 100%;
    
    }

    &__title {
        margin: 0;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &--standalone {
            white-space: normal;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
       
    }

    &__text {
        margin: .5rem 0 0;
        display: block;
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        &--standalone {
            -webkit-line-clamp: 3;
        }
    }


    &__decoration {

        --icon-width: 100%;
        --icon-height: 100%;

        position: absolute;
        inset: 0;
        color: var(--sticker-decoration-color);


    }

    &__icon {
        --icon-width: 3rem;
        display: inline-block;
    }


    @include mq.mq($from: large) {
        --sticker-padding: 4rem;
        width: 25rem;

        &--has-decoration {
            width: 27.6rem;
            padding: 2.4rem 3.8rem 1.1rem 6rem;
        }
      
        &__title {
            font-size: var(--font-size-h3);
            line-height: 1.4;

            &--standalone {
                font-size: var(--font-size-h4);
            }
        }

        &__text {
            font-size: var(--font-size-h5);

            &--standalone {
                font-size: var(--font-size-h4);
            }
        }

        &__icon {
            --icon-width: 4rem;
       
        }
    }
}


@keyframes stickerSwing {
    0% {
		opacity: 0;
    }
    16% {
		rotate: 5deg;
        opacity: 1;
    }
    33% {
		
    }
	66% {
		rotate: -5deg;
	}
	100% {
		rotate: 0deg;
	}
}