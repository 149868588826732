.hamburger {
  position: relative;
  display: grid;
  align-items: center;
  width: var(--hamburger-width);
  height: var(--hamburger-width);
  padding: var(--hamburger-padding);
  overflow: hidden;
  color: currentColor;
  transform: translateZ(0);
  border-radius: 50%;
  //background-color: var(--color-white);

  &__inner {
    position: relative;
  }

  &__line {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    display: block;
    height: var(--hamburger-line-height);
    background-color: var(--hamburger-line-color,currentColor);
    border-radius: .2rem;
    transition: 
      transform .3s ease, 
      background-color .3s ease;
    transform-origin: 50% 50%;
    opacity: var(--hamburger-line-opacity, 1);

    transform: var(--hamburger-line-transform);
  
    &:first-child {
      --hamburger-line-transform: rotate(180deg) translateY(.4rem);
    }
  
    &:last-child {
      --hamburger-line-transform: translateY(.4rem);
    }

  
    [aria-expanded="true"] & {
      --hamburger-line-transform: translateX(150%);
      --hamburger-line-opacity: 0;
      --hamburger-line-color: var(--color-white);

      &:first-child {
        --hamburger-line-transform: rotate(45deg);
        --hamburger-line-opacity: 1;
      }
    
      &:last-child {
        --hamburger-line-transform: rotate(-45deg);
        --hamburger-line-opacity: 1;
      }
    }

  }
}

