@use "../mixins/mq";

.contact-list {
    --symbol-color: var(--color-accent-01);
    
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    list-style: none;
    margin: 0 auto;
    padding: 0;
    gap: 5rem;
    max-width: 84rem;


    &__item {
        margin: 0;
        padding: 0;
        flex-grow: 1;
        flex: 0 0 var(--contact-list-item-max-width, 100%);
        max-width: 39.5rem;
        min-width: 0;
   
    }

    @include mq.mq($from: medium) {
        --contact-list-item-max-width: calc( 50% - 2.5rem);
        &__item {
            min-width: 39.5rem;
        }

    }
    @include mq.mq($from: large) {
        --contact-list-item-max-width: calc( 33% - 5rem);
        
        //add space for 3 col
        &--wide {
            max-width: none;
           
        }
       
    }

}