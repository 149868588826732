.status {
    --icon-width: 1.4rem;
    --icon-height: 1.4rem;
    --status-accent-color: var(--color-black);
    --icon-color: var(--status-accent-color);

    display: inline-flex;
    gap: 1rem;
    justify-content: var(--status-justify-content, center);
    align-items: center;
    position: relative;
    max-width: 100%;
    text-decoration: none;
    color: var(--status-color, currentColor);
    border: none;
    border-radius: 5rem;
    overflow: hidden;
    padding: .5rem 1.2rem;
    flex-shrink: 0;
    font-size: var(--subtitle-small-font-size);
	line-height: var(--subtitle-small-line-height);
	letter-spacing: var(--subtitle-small-letter-spacing);
    font-weight: var(--status-font-weight, 500);
    z-index: 1;
    white-space: nowrap;

    &:after {
        content: "";
        position: absolute;
        inset: 0;
        opacity: .1;
        z-index: -1;
  
        background-color: var(--status-accent-color);
    }

    &--progress {
        --status-accent-color: var(--color-secondary-900);
    }

    &--free,
    &--complete {
        --status-accent-color: var(--color-success);
    }

    &--busy,
    &--interruption {
        --status-accent-color: var(--color-error);
    }

    &--upcoming {
        --icon-color: currentColor;
        --status-accent-color: var(--color-accent-03);
    }



    &__dot {
        width: 1.4rem;
        aspect-ratio: 1/1;
        flex-shrink: 0;
        border-radius: 50%;
        background-color: var(--color-error);

        &--free {
            background-color: var(--color-success);
        }
    }
}