@use "../mixins/mq";


.iframe {
	--img-bg-color: var(--color-skeleton);
	display: block;
	position: relative;
	width: 100% !important;
	max-width: 100% !important;


	&--video {
		aspect-ratio: 16/9;
		//hide from screen reader
		--iframe-embed-visibility: hidden;
	}

	&--playing {
		--iframe-poster-z-index: -1;
		--iframe-embed-visibility: visible;
	}

	iframe, object, embed {
		width: 100% !important;
		max-width: 100% !important;
		height: 100% !important;
		position: absolute;
		inset: 0;
		border: none !important;
		visibility: var(--iframe-embed-visibility,visible);
	}

	&__poster {
		position: absolute;

		inset: 0;
		z-index: var(--iframe-poster-z-index,2);
		
	}

	&__img {
		position: absolute;
		inset: 0;
	}
	
	&__play {
		--icon-width: 8rem;
		--icon-height: 8rem;
		display: grid;
		z-index: 2;
		position: relative;
		width: 100%;
		height: 100%;
		align-items: center;
		justify-content: center;
		align-content: center;
		opacity: .98;
	}

	&__wrapper {
		aspect-ratio: var(--iframe-aspect-ratio, unset);

		@include mq.mq(medium) {
			aspect-ratio: var(--iframe-aspect-ratio-large, unset);
		}
	}

	&__wrapper--small {
		aspect-ratio: var(--iframe-aspect-ratio, unset);

		@include mq.mq($from: medium) {
			display: none;
			visibility: hidden;
		}
	}

	&__wrapper--medium {
		display: none;
		visibility: hidden;

		@include mq.mq($from: medium) {
			display: block;
			visibility: visible;
			aspect-ratio: var(--iframe-aspect-ratio-large, unset);
		}
	}

	&__legend--not-on-mobile {
		display: none;

		@include mq.mq($from: medium) {
			display: block;
		}
	}
}

/*.iframe-container {
	position: relative;
	width: 100%;
	aspect-ratio: 16/9;
	

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	iframe,
	div {
		position: absolute !important;
		width: 100% !important;
		height: 100% !important;
	}
}

.iframe__poster {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	opacity: 1;
	transition: all .3s cubic-bezier(.25,.46,.45,.75);

	.iframe--playing & {
		opacity: 0;
		z-index: 0;
	}

	img {
		display: block;
		width: 100%	;
		height: 100%;
		object-fit: cover;
	}
}

.iframe__play {
	position: absolute;
	left: 50%;
	top: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 60px;
	height: 60px;
	padding: 0;
	transform: translate3d(-50%, -50%, 0);
	z-index: 2;
	cursor: pointer;
	will-change: background-color;
	border-radius: 50%;
	transition: background-color 0.25s ease;
	background-color: var(--color-primary-900);
	color: var(--white);

	@include mq.mq($from: large) {
		width: 120px;
		height: 120px;
	}

	.icon {
		transform: translateX(2px);
		color: var(--color-white);

		@include mq.mq($from: large) {
			transform: translateX(5px);
		}
	}

	&:hover {
		background-color: var(--color-secondary-1);
	}
}*/
