@use "../mixins/mq";

.videos {
  &__video {
    --image-width: 100%;
    --image-height: 100%;
    --figure-position: absolute;
    --media-grid-column: var(--media-grid-column-small);

    position: relative;
    grid-column: var(--media-grid-column, span 6);
    aspect-ratio: 14/9;
    border-radius: var(--rounded-s);
    overflow: hidden;

    .figure {
      height: 100%;
      width: 100%;
    }

    @include mq.mq($from: medium) {
      --media-grid-column: var(--media-grid-column-medium);
    }

    @include mq.mq($from: large) {
      --media-grid-column: var(--media-grid-column-large);
    }
  }
}