
.progress {

    display: block;
    background: var(--progress-background);
    font-size: 0;
    white-space: nowrap;
    border-radius: var(--progress-bar-height);
    overflow: hidden;
    box-shadow: 0 .1rem .5rem rgba(0, 0, 0, 0.20) inset;
    width: 100%;
    
    &__bar {
        max-width: 100%;
        height: var(--progress-bar-height);
        background: var(--progress-bar-background);
        color: var(--color-white);
        display: grid;
        align-items: center;
        justify-content: center;
        will-change: width;
        transition: width var(--animation-duration) var(--animation-ease);
        border-radius: var(--progress-bar-height);
    }
}