@use "sass:color";
@use "../_config/colors";
@use "../mixins/mq";


.figure {
	position: var(--figure-position, static);
	overflow: hidden;

	&__play {
		--icon-position: absolute;
		--icon-transform: translate(-50%, -50%);

		left: 50%;
		top: 50%;
		border-radius: 50%;
		will-change: unset !important;
		transition: none;
		z-index: 2;

		// box-shadow: 0 0 0 50rem var(--color-black-transparent);
	}


	&:has(.figure__play) {
		--figure-position: relative;
	}

	&:has(.figure__play):after {
		position: absolute;
		content: '';
		inset: 0;
		display: block;
		background-color: var(--color-black-transparent);
		z-index: 1;
	}
}

picture {
	display: block;
}

img,
video {
	display: block;
	max-width: 100%;
	height: auto;
	object-fit: cover;
}

img {
	transform: var(--img-transform);
	transition: trasform var(--animation-duration) var(--animation-ease);
}

svg {
	max-width: 100%;
	height: auto;
}

video {
	width: 100%;
	object-position: var(--focalpoint);
	object-fit: cover;
}

img {
	transition: transform var(--animation-duration) var(--animation-ease);
	image-rendering: -webkit-optimize-contrast;
	backface-visibility: hidden;
	object-position: var(--focalpoint);
	position: var(--img-position,static);
	object-fit: cover;
	width: var(--image-width, auto);
	height: var(--image-height, auto);
	border-radius: var(--img-border-radius, none);
	background-color: var(--img-bg-color, none);
	inset: 0;
}

video::-webkit-media-controls,
video::video::-webkit-media-controls-enclosure,
video::-webkit-media-controls-overlay-play-button {
	display: none !important;
	-webkit-appearance: none !important;

}
