@use "../_base/typography";

.job-contact {
  &__text {
    @extend .h6;
    font-weight: 400;
    hyphens: none;
    --spacing-h6-bottom: 0;

    b {
      @extend .h3;
      padding-bottom: 0.5rem;
    }
  }
}