@use "sass:color";
@use "../_config/colors";
@use "../_base/typography";
@use "../atoms/action";
@use "../mixins/mq";

.cookie-banner {
  position: fixed;  
  inset: auto 0 0;
  z-index: 100;
  width: auto;
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
  letter-spacing: var(--small-letter-spacing);

  padding: 2.5rem var(--wrapper-gap) 2rem;

  background-color: var(--color-grey-100);

  &__title {
    font-weight: 600;
  }

  &__container {
    max-width: var(--container-width);
    margin: auto;
    display: grid;
    gap: 1.5rem 5rem;
  }


  &__button {
    margin: 0;
  }


  &__settings {
    flex-basis: 100%;
  }

  &__cta {

    display: flex;
    align-items: center;
    gap: 2.5rem;
    font-weight: 500;
    flex-wrap: wrap;
  }

  &__settings {
    display: inline-block;
  }

  @include mq.mq($from: medium) {
    &__cta {
      flex-wrap: nowrap;
    }


  }

  @include mq.mq($from: large) {
 
    &__text {
      max-width: 81rem;
    }
  
    &__container {
      grid-template-columns: 1fr auto;
    }
  }
}



.cookie-choice {
  position: relative;


  &__checkbox {
    position: absolute;
    top: 1rem;
  }



  &__icon {
    display: inline-flex;
    flex-shrink: 0;
    width: 4rem;
    height: 4rem;
    padding: 0.8rem;
    margin-right: 1.5rem;
    transform: translateY(-0.3rem);
    border-radius: 50%;
    background-color: var(--color-success);
    color: var(--color-white);
  }

  &__submit {
    margin-top: 2.4rem;

    @include mq.mq($from: large) {
      margin-top: 4rem;
    }
  }

  &__details {
    border-bottom: .1rem solid var(--color-grey-100);
    margin: 1rem 0 1rem;

    p {
      margin: 2rem 0;
    }
  }

  &__success {
    display: flex;
  }



  &__summary {
    list-style: none;
    color: transparent;
    cursor: pointer;
    min-height: 5.5rem;
    padding-left: 4.5rem;

    &+* {
      margin-top: 0;
    }

    &:before,
    &:after {
      position: absolute;
      right: 0;
      top: 2.75rem;
      display: block;
      content: '';
      width: 2.5rem;
      height: 0.3rem;
      background-color: var(--color-black);
      border-radius: 3px;
    }

    &:after {
      transform: rotate(90deg);
      transition: transform var(--animation-duration) var(--animation-ease);

      .cookie-choice__details[open]>& {
        transform: rotate(0);
      }
    }

    &::marker,
    &::-webkit-details-marker {
      display: none;
    }
  }
}