.text {
  &__figure {
    --figure-position: relative;
    border-radius: var(--rounded-m);

    &--small {
      max-width: 30rem;
    }
  }

  &__wrapper {
    position: relative;
  }

  &-section {
    &:has(+ .media-section) {
      --section-spacing-bottom: var(--media-spacing-top);
    }

    .media-section + & {
      --section-spacing-top: var(--media-spacing-top);
    }
  }
}