@use "../mixins/mq";

.help-finder {
  --section-boxed-border-radius: var(--rounded-l);
  --card-border-radius: var(--rounded-l);
  --card-padding: 5rem 0 0;
  --section-boxed-padding-block: 5rem;
  --section-boxed-padding-inline: var(--wrapper-gap);
  --multi-steps-form-margin-inline: 0;

  padding-inline: var(--help-finder-padding-inline, 0);
  margin-inline: auto;
  max-width: 168rem;

  &-section {

    &:first-child {
      padding-top: .1rem;
    }
  }

  &__text {
    margin-bottom: 5rem;
  }

  &__action {
    font-size: 1.8rem;
    margin-top: 5rem;
  }

  &__faq-title {
    --spacing-h3-bottom: 4rem;
  }

  &__list {
    --spacing-list-top: 3.5rem;
    --spacing-list-bottom: 4rem;

    display: grid;
    grid-template-columns: var(--help-finder-list-grid-template-columns, 1fr);
    gap: var(--help-finder-list-gap, 1.6rem);
  }

  &__container {

    &--disturbances,
    &--constructionProjects {

      .section__boxed {
        --section-boxed-padding-block: 0;
        --section-boxed-padding-inline: 0;
        --wrapper-gap: 0;

        &:first-child {
          --section-boxed-padding-block: 0 var(--section-spacing-s);
        }
      }

    }
  }

  @include mq.mq($from: medium) {
    --help-finder-list-grid-template-columns: repeat(2, 1fr);
    --help-finder-list-gap: 2.4rem;
    --help-finder-padding-inline: 3rem;
    --card-padding: 9.5rem var(--wrapper-gap) 7.5rem;

  }

  @include mq.mq($from: large) {
    --help-finder-list-grid-template-columns: repeat(4, 1fr);
    --help-finder-list-gap: 2.4rem;

  }
  
}