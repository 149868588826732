@use "../mixins/mq";

.offers {


  &__grid {
    --offers-grid-gap: 0 var(--wrapper-gap);

    justify-content: center;
    display: grid;
    padding-top: 2rem;
    grid-template-rows: 1fr;
    gap: var(--offers-grid-gap);
    justify-items: center;

    @include mq.mq($from: medium) {
      --offer-grid-template-rows: subgrid;
      
      display: grid;
      grid-template-columns: repeat(auto-fill, min(38rem, calc(50% - var(--wrapper-gap))));
    }

    @include mq.mq($from: offersLarge) {

      grid-template-columns: repeat(3, 38rem);
    }

    &--even {
      @include mq.mq($from: offersLarge) {
        grid-template-columns: repeat(2, 38rem);
      }
    }
  }

  // for form overview 
  &--pull-to-edge {
    width: 80rem;
    max-width: calc(100vw - calc(var(--wrapper-gap) * 2));
  }

  &__auto-grid {
    justify-content: center;
    display: grid;
    grid-template-rows: 1fr;
    gap: 0 2.5rem;
    justify-items: center;

    @include mq.mq($from: medium) {
      gap: 0 5rem;
      grid-template-columns: repeat(4, 1fr);
      --offer-grid-column-end: span 2;

      .offer:nth-child(odd of :not(.hide)):nth-last-child(1 of :not(.hide)) {
        --offer-grid-column-start: 2;
      }

    }
  }

  &__legend {
    margin-top: 5rem;

    @include mq.mq($from: large) {
      width: 60%;
    }

    &--center {
      margin-inline: auto;
    }
  }
}