@use "../mixins/mq";

.spade-wrapper {
    --color-h1: currentColor;
    position: relative;
    isolation: isolate;
    padding-block: var(--spade-inner-spacin, var(--section-spacing));

    &--inner-spacing-big {
        --spade-inner-spacin: 10rem;
    }

    @include mq.mq($from: medium) {
        &--inner-spacing-big {
            --spade-inner-spacin: 15rem;
        }
    }

    @include mq.mq($from: large) {
        &--inner-spacing-big {
            --spade-inner-spacin: 19rem;
        }
    }
}

.spade {
    color: var(--spade-color);
    position: absolute;
    inset: var(--spade-inset, 0);
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;


    &--solid {
        background-color: currentColor;
        display: block;
    }

    &--secondary {
        --spade-color: var(--color-secondary-900);
    }

    &--flow {
        --spade-color: var(--color-white);
    }


    //need to set new colors for contrast
    .action-tertiary {
        --action-color: currentColor;
        --action-hover-color: currentColor;
    }


    &__blade {
        margin-block: -.1rem;
        flex-grow: 1;
        min-height: 5rem;
        background-color: currentColor;
    }

    &__edge {
        width: 100%;
        max-height: 47rem;
        fill: currentColor;
    }

    &--decoration {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: transparent;
    }


    @include mq.mq($from: medium) {
        &--lean-out-right {
            left: 4rem;

        }
    }

    @include mq.mq($from: large) {

        &--flow,  
        &--stick-right,
        &--stick-left {
            --remaining-white-space: calc(100vw - var(--container-width));
            width: calc(100vw - var(--remaining-white-space) / 2 + 10rem);
            max-width: 100%;
        }

        &--stick-left {
            right: auto;

        }

        &--flow,  
        &--stick-right {
            left: auto;
        }


    }

}