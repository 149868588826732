.quote {
  font-weight: normal;
  quotes: none;
  color: inherit;

  &__icon {
    --icon-width: 1.5em;
    margin-bottom: 2rem;
  }


}