.pagination {
   

    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    font-weight: 600;
    
    &__item {
        display: inline-block;
        vertical-align: middle;
        margin: 0;
        padding: 0;
    }

    &__page {
        cursor: pointer;
        text-decoration: none;
        display: block;
        padding: 0 .15em;
        line-height: 1;

        &--inactive {
            --link-color: currentColor;
        }
        &--active {
            cursor: default;
           // --link-color: var(--color-primary-900);
        }
        &--ellipsis {
            padding: 0;
        }

        &--arrow-previous {
           padding-right: 2rem;
        }
        &--arrow-next {
           padding-left: 2rem;
        }
    }

    &__icon {
        --icon-width: 2rem;
        --icon-height: 2rem;
    }
}