@use "../mixins/mq";

.download {
    --download-icon-bg-color: var(--color-accent-01);
    text-decoration: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr auto;
    min-height: 16.2rem;
    height: 100%;

 
    &__description {
        background-color: var(--color-primary-700);
        padding: 1.5rem;
        border-radius:  var(--rounded-m);
        width: 100%;
        display: flex;
        flex-direction: column;
        min-height: 16rem;
        position: relative;
        z-index: 1;
        text-decoration: none;
    }


    &__title {
        color: var(--color-black);
        margin-top: 0;
   
    }


    &__infos {
        margin-top: auto;
        color: var(--color-black);
        display: block;
    }

    &__link {
        font-weight: 600;
        text-decoration: underline;
    }

    &__icon {
        --icon-width: 3.5rem;
        --icon-height: 3.5rem;
        --icon-padding-inline: 1.2rem;
        display: grid;
        align-items: center;
        color: var(--color-white);
        padding: 1rem var(--icon-padding-inline);
        transition: background-color var(--animation-duration) var(--animation-ease);
        border-radius:  0 var(--rounded-m) var(--rounded-m) 0;
        padding-left: calc(var(--icon-padding-inline) + var(--rounded-m));
        margin-left: calc( var(--rounded-m) * -1 );
        background-color: var(--download-icon-bg-color);

        &:hover,
        &:focus {
            --download-icon-bg-color: var(--link-color-focus);
        }
    
    }

  
    @include mq.mq($from: large) {
        &__description {
            padding: 1.5rem 2.5rem;
        }
        &__icon {
            --icon-width: 5.5rem;
            --icon-height: 5.5rem;
            --icon-padding-inline: 2.2rem;
        }
     
    }

}