@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(5rem);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

//by default all animation are paused

$animate-delay-interval: 300ms;

.animate {
    animation-fill-mode: var(--a-fill-mode,forwards);
    animation-play-state: var(--a-play-state,paused);
    animation-duration: var(--a-duration, 800ms);
    animation-timing-function: var(--a-timing-function,ease-out);
    animation-delay: var(--a-delay,0);

    &--run {
        --a-play-state: running;
    }

    &--slide-in {
        opacity: 0;
        animation-name: slideIn;
    }

    @for $i from 0 to 10 {
        &--delay-#{$i} {
            --a-delay: #{$animate-delay-interval * $i};
        }
    }
    

}



