@use "../mixins/mq";

.shutdown {
  &-section strong {
    font-weight: 600;
  }
  &__response {
    max-width: 79rem;
    margin: 0 auto;
  }

  &__response--negative {
    --spacing-text-top: 0;
    h5 {
      --spacing-bottom: 0;
      color: var(--color-primary-900);
    }
  }

  &__response-schedule {
    width: 100%;

    @include mq.mq($from: medium) {
      width: 50%;
    }

    @include mq.mq($from: large) {
      width: auto;
    }
  }

  &__response-chunk {
    display: block;

    &:first-child {
      font-weight: 500;
    }
  }

  &__response-title {
    width: 100%;
    --spacing-bottom: 1rem;
  }
}