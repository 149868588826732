@use "../mixins/mq";

$columns: 12;

$sizes: (
		'sm',
		'md',
		'lg'
);

.container {
	width: 100%;
	max-width: var(--container-width);
	position: var(--container-position,static);
	margin-inline: auto;

	&--fluid {
		--container-width: none;
	}

	&--skinny {
		--container-width: var(--container-skinny-width);
	}
	//8/12
	&--slim {
		--container-width: var(--container-slim-width);
	}
	//10/12
	&--narrow {
		--container-width: var(--container-narrow-width);
	}
	//add space if narrow and normal container are used
	&--block-space {
		--container-block-space-block: var(--container-block-space);
		--container-block-space-top: var(--container-block-space-block);
		--container-block-space-bottom: var(--container-block-space-block);
	
		margin-top: var(--container-block-space-top);
		margin-bottom: var(--container-block-space-bottom);

		&--small {
			--container-block-space-block: var(--container-block-space-small);
		}

		&:first-child {
			--container-block-space-top: 0;
		}
		&:last-child {
			--container-block-space-bottom: 0;
		}
		

	}

	&--pull-to-edge {
		margin-inline: var(--container-pull-to-edge-margin-inline,0);
		width: auto;
	}

	&--relative {
		--container-position: relative;
	}
}

.grid-center-content {
	display: grid;
    justify-content: center;
}

.wrapper {
	position: var(--wrapper-position,relative);
	width: 100%;
	padding-left: var(--wrapper-gap);
	padding-right: var(--wrapper-gap);

	.wrapper {
		--wrapper-gap: 0;
	}
}

.row-flex {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
}

.row--v-align-center {
	align-items: center;
}

.row--h-align-center {
	justify-content: center;
}

.row {
	display: grid;
	grid-template-columns: repeat($columns, 1fr);
	grid-column-gap: var(--grid-gap);
	justify-content: center;
	grid-template-rows: auto;
	align-items: var(--grid-align);
}


.row--list {
	list-style: none;
	--spacing-list-top: 0;
	--spacing-list: initial;
	--list-item-padding-left: initial;
	--list-margin-left: initial;
	--list-item-margin-top: initial;
	--grid-align: normal;
	gap: 2.5rem var(--grid-gap);
	@include mq.mq($from: medium) {
		gap: var(--grid-gap);
	}
}

.row--stretch {
	align-items: stretch;
}

.row--gutters-y {
	grid-row-gap: var(--grid-gap);
}

.row--gutters-small {
	gap: 1rem;
}

.row--gutters-y-small {
	grid-row-gap: 1rem;
}

.row-align-end {
	align-items: end;
}



.col--half {
	width: 50%;
}



.middle {
	margin-left: auto;
	margin-right: auto;
}

.middle--medium {
	@include mq.mq($from: medium) {
		max-width: 56rem;
	}

	@include mq.mq($from: large) {
		max-width: 77rem;
	}
}

.middle--large {
	@include mq.mq($from: medium) {
		max-width: 105rem;
	}

}

.col {
	grid-column-end: span 12;
}

@mixin generateColumns($columns, $size, $breakpointStart: null, $breakpointEnd: null) {

	@if ($breakpointStart) {

		@include mq.mq($from: $breakpointStart) {

			@for $i from 1 through $columns {
				.col-#{$size}-#{$i} {
					grid-column-end: span $i;
				}

				.offset-#{$size}-#{$i} {
					grid-column-start: $i + 1
				}

				.row-start-#{$size}-#{$i} {
					grid-row-start: $i
				}

			}
		}

	} @else {

		@for $i from 1 through $columns {

			.col-#{$size}-#{$i} {
				grid-column-end: span $i;
			}

			.offset-#{$size}-#{$i} {
				grid-column-start: $i + 1;
			}

		}
	}
}

@each $size in $sizes {

	@if $size == 'sm' {
		@include generateColumns($columns, $size);
	}

	@else if $size == 'md' {
		@include generateColumns($columns, $size, medium, large);
	}

	@else if $size == 'lg' {
		@include generateColumns($columns, $size, large);
	}
}

.offset-md-0 {
	@include mq.mq($from: medium) {
		grid-column-start: 1;
	}
}

.offset-lg-0 {
	@include mq.mq($from: large) {
		grid-column-start: 1;
	}
}
