@use "../mixins/mq";
@use "../mixins/background-svg";
@use "../_config/colors";
@use "../_base/svg";

:root {

  //colors
  --color-black: #{colors.$color-black};
  --color-white: #{colors.$color-white};

  --color-grey-100: #{colors.$color-grey-100};
  --color-grey-200: #{colors.$color-grey-200};
  --color-grey-300: #{colors.$color-grey-300};
  --color-grey-400: #{colors.$color-grey-400};

  --color-primary-900: #{colors.$color-primary-900};
  --color-primary-800: #{colors.$color-primary-800};
  --color-primary-700: #{colors.$color-primary-700};
  --color-secondary-900: #{colors.$color-secondary-900};
  --color-secondary-800: #{colors.$color-secondary-800};
  --color-tertiary-900: #{colors.$color-tertiary-900};
  --color-tertiary-800: #{colors.$color-tertiary-800};

  --color-accent-00: #{colors.$color-accent-00};
  --color-accent-01: #{colors.$color-accent-01};
  --color-accent-02: #{colors.$color-accent-02};
  --color-accent-03: #{colors.$color-accent-03};
  --color-accent-04: #{colors.$color-accent-04};
  --color-accent-05: #{colors.$color-accent-05};
  --color-accent-06: #{colors.$color-accent-06};

  --color-gradient-000: #{colors.$color-gradient-000};
  --color-gradient-100: #{colors.$color-gradient-100};
  --color-gradient-200: #{colors.$color-gradient-200};
  --color-gradient-300: #{colors.$color-gradient-300};
  --color-gradient-400: #{colors.$color-gradient-400};
  --color-gradient-500: #{colors.$color-gradient-500};
  --color-gradient-600: #{colors.$color-gradient-600};

  --color-striped-secondary-900: #{colors.$color-striped-secondary-900};

  --color-error: #{colors.$color-error};
  --color-warning: #{colors.$color-warning};
  --color-success: #{colors.$color-success};
  --color-focus: #{colors.$color-focus};

  --color-skeleton: var(--color-grey-100);

  --color-disabled: var(--color-grey-200);

  --outline-color: var(--color-grey-200);

  --color-black-transparent: #{colors.$color-black-transparent};
  --color-grey-100-transparent: #{colors.$color-grey-100-transparent};
  --color-error-transparent: #{colors.$color-error-transparent};

  --font-family-primary: "Inter Web", sans-serif;
  --font-family-secondary: "Museo Sans Web", sans-serif;

  //body 
  --body-font-family: var(--font-family-primary);
  --body-color: var(--color-black);
  --body-bg-color: var(--color-white);
  --body-font-size: 1.8rem;
  --body-line-height: 1.65;
  --body-font-weight: normal;
  --body-letter-spacing: normal;

  --bold-letter-spacing: 0.036rem;

  //style small  / body 2 /
  --small-font-size: 1.6rem;
  --small-line-height: 1.6;
  --small-letter-spacing: normal;

  //Subtitle 
  --subtitle-font-size: 1.6rem;
  --subtitle-line-height: 1.6;
  --subtitle-letter-spacing: 0.032rem;

  --subtitle-small-font-size: 1.4rem;
  --subtitle-small-line-height: 1.5;
  --subtitle-small-letter-spacing: 0.028rem;

  @include background-svg.to-custom-property(svg.$svg-check-list, colors.$color-accent-01, --check-list);
  @include background-svg.to-custom-property(svg.$svg-check-list, colors.$color-black, --check-list-black);
  @include background-svg.to-custom-property(svg.$svg-check-list, colors.$color-white, --check-list-white);
  @include background-svg.to-custom-property(svg.$svg-check-list, colors.$color-accent-03, --check-list-accent-03);

  @include background-svg.to-custom-property(svg.$svg-dashed-line, colors.$color-accent-03, --dashed-line);


  //--action-font-size: var(--small-font-size);
  --action-line-height: var(--small-line-height);
  --action-letter-spacing: var(--small-letter-spacing);
  --action-margin-left: auto;
  --action-margin-right: auto;

  --button-border-radius: 4rem;
  --button-padding: 0.82rem 2.2rem;
  --button-min-height: 5rem;

  //stepper
  @include background-svg.to-custom-property(svg.$svg-plus, colors.$color-primary-900, --action-stepper-plus);
  @include background-svg.to-custom-property(svg.$svg-plus, colors.$color-white, --action-stepper-plus-active);
  @include background-svg.to-custom-property(svg.$svg-minus, colors.$color-primary-900, --action-stepper-minus);
  @include background-svg.to-custom-property(svg.$svg-minus, colors.$color-white, --action-stepper-minus-active);

  --scroll-behavior: smooth;
  --hyphens-style: auto;

  //layout
  --wrapper-gap: 1.6rem;
  --wrapper-gap-negative: calc( var(--wrapper-gap) * -1);
  --container-width: 142.8rem;
  --container-skinny-width: 69rem;
  --container-slim-width: 93.5rem;
  --container-narrow-width: 118.2rem;
  --container-block-space: 2.5rem;
  --container-block-space-small: 2.5rem;
  --container-pull-to-edge-margin-inline: var(--wrapper-gap-negative);
  --gutter: 1rem;
  --grid-gap: var(--gutter);
  --grid-align: start;



  //scrollbar
  --scrollbar-color: var(--color-primary-900);
  --scrollbar-thumb: var(--color-primary-900);
  --scrollbar-track: var(--color-black-transparent);

  //section
  --section-spacing-s: 5rem;
  --section-spacing-m: 10rem;
  --section-spacing-l: 10rem;
  //default section spacing
  --section-spacing: var(--section-spacing-m);

  --section-boxed-bg-color: var(--color-accent-05);
  --section-boxed-padding-block: 2.5rem;

  //font sizes

  --heading-font-weight: 700;
  --heading-font-family: var(--font-family-secondary);

  --font-size-h1: 3.6rem;
  --font-size-h2: 3rem;
  --font-size-h3: 2.6rem;
  --font-size-h4: 2.4rem;
  --font-size-h5: 2.2rem;
  --font-size-h6: 2rem;

  --line-height-h1: 1.2;
  --line-height-h2: 1.3;
  --line-height-h3: 1.3;
  --line-height-h4: 1.4;
  --line-height-h5: 1.4;
  --line-height-h6: 1.5;

  --letter-spacing-h1: normal;
  --letter-spacing-h2: normal;
  --letter-spacing-h3: normal;
  --letter-spacing-h4: normal;
  --letter-spacing-h5: .044rem;
  --letter-spacing-h6: .04rem;

  --spacing-h1-top: 5rem;
  --spacing-h2-top: 5rem;
  --spacing-h3-top: 5rem;
  --spacing-h4-top: 5rem;
  --spacing-h5-top: 2.5rem;
  --spacing-h6-top: 2.5rem;

  --spacing-h1-bottom: 1rem;
  --spacing-h2-bottom: 1rem;
  --spacing-h3-bottom: 1rem;
  --spacing-h4-bottom: 1rem;
  --spacing-h5-bottom: 1rem;
  --spacing-h6-bottom: .5rem;

  --color-h1: var(--color-primary-900);


  @for $i from 1 through 5 {
    --font-size-h#{$i}-reduced: var(--font-size-h#{$i + 1});
    --line-height-h#{$i}-reduced: var(--line-height-h#{$i + 1});
    --letter-spacing-h#{$i}-reduced: var(--letter-spacing-h#{$i + 1});
    --spacing-h#{$i}-top-reduced: var(--spacing-h#{$i + 1}-top);
    --spacing-h#{$i}-bottom-reduced: var(--spacing-h#{$i + 1}-bottom);
  }



  --mobile-menu-bg-color: var(--color-accent-01);

  // typography spacing
  --spacing-text-top: 1rem;
  --spacing-text-bottom: 1rem;
  --spacing-action-top: 2.5rem;
  --spacing-action-bottom: 2.5rem;
  --spacing-list-top: 1rem;
  --spacing-list-bottom: 1rem;
  --spacing-blockquote-top: 2.5rem;
  --spacing-blockquote-bottom: 2.5rem;

  --media-spacing-top: 5rem;
  --spacing-media-bottom: 5rem;
  --spacing-media-caption: .5rem;


  --lead-text-font-family: var(--font-family-secondary);
  --lead-text-font-size: var(--font-size-h6);
  --lead-text-line-height: var(--line-height-h6);
  //--lead-text-letter-spacing: var(--letter-spacing-h6);
  --lead-text-font-weight: inherit;

  //forms and fields
  --label-font-size: var(--subtitle-font-size, 1.6rem);
  --label-line-height: var(--subtitle-line-height, 1.2);
  --label-letter-spacing: var(--subtitle-letter-spacing);
  --label-padding: 0 .6rem 0;
  --label-color: var(--color-grey-400);

  --label-legend-padding: 0;
  --label-legend-font-size: var(--small-font-size);
  //--label-legend-line-height: var(--small-line-height);
  --label-legend-line-height: 1.2;
  --label-legend-letter-spacing: var(--small-letter-spacing);
  --label-legend-font-weight: 600;
  --label-legend-family: var(--heading-font-family);

  --field-instructions-padding: 0 .6rem 0;
  --field-instructions-font-size: 1.4rem;

  --header-height: 8rem;
  --scroll-padding-top: 130px;
  --header-logo-max-height: 3.5rem;
  --header-outer-box-shadow: var(--shadow-000);

  --header-with-slogan-height: 10rem;
  --header-with-slogan-logo-max-height: 3rem;
  
  --shadow-000: 0 0 0 rgba(0, 0, 0, 0);
  --shadow-100: 0 .5rem 2rem rgba(0, 0, 0, 0.20);
  --shadow-200: 0 -0.5rem 2rem rgba(0, 0, 0, 0.20);
  --shadow-300: 0 0 2rem rgba(0, 0, 0, 0.40);

  --hamburger-line-height: .3rem;
  --hamburger-padding: .85rem 0;
  --hamburger-width: 4.5rem;
  --hamburger-spacing: .1rem;
  --hamburger-line-color: var(--color-primary-900);


  --animation-duration: 0.25s;
  --animation-delay: 0.1s;
  --animation-ease: cubic-bezier(.25, .46, .45, .75);

  --link-color: var(--color-primary-900);
  --link-color-focus: var(--color-tertiary-900);

  --inverted-link-color: currentColor;
  --inverted-link-color-focus: var(--link-color-focus);
  //--link-text-decoration: underline;
  //--link-text-decoration-hover: underline;

  --marker-color: var(--color-accent-01);

  //rounded corner
  --rounded-xs: .5rem;
  --rounded-s: 1rem;
  --rounded-m: 1.5rem;
  --rounded-l: 2rem;
  --rounded-xl: 4rem;

  --list-item-position: static;
  --list-margin-top: 1rem;
  --list-item-padding-left: 0.5rem;
  --list-margin-left: 2.3rem;
  --list-item-margin-top: 1rem;
  --list-item-before-top: 0.3em;
  --list-item-before-transform: unset;
  --list-item-before-content: none;
  --list-item-padding-vertical: 0;
  --list-item-background: none;
  --list-item-min-height: unset;

  //enumerations cards
  --card-bg-color: var(--color-white);
  --card-border-radius: var(--rounded-m);
  --card-gap: 1rem;
  --card-icon-size: 4rem;
  --card-index-color: var(--color-accent-01);
  
  //infobox
  --infobox-icon-size: var(--card-icon-size);

  //symbol
  --symbol-color: var(--color-accent-01);

  //forms and fields
  --form-row-gap: 0 1.5rem;

  --contact-box-header-color: var(--color-primary-900);

  --contact-box-header-min-height: 2lh;
  --contact-box-min-width: unset;
  --contact-box-max-width: 100%;
  --contact-box-footer-padding-top: 1rem;

  /*--label-font-size: 1.6rem;
  --label-line-height: 1.2;*/

  --field-color: var(--color-grey-400);
  --field-margin-top: 2rem;
  //--field-margin-bottom: 2rem;
  --field-error-font-size: var(--subtitle-small-font-size);
  --field-error-line-height: var(--subtitle-small-line-height);
  --field-error-letter-spacing: var(--subtitle-small-letter-spacing);
  --field-error-color: var(--color-error);
  --field-error-margin: .5rem 0 0;


  --input-font-size: var(--small-font-size);
  --input-line-height: var(--small-line-height);
  --input-padding-inline: .9rem;
  --input-padding-block: .9rem;
  --input-border-color: currentColor;
  --input-border-width: .1rem;
  --input-border-radius: var(--rounded-s);
  --input-background-color: transparent;
  --input-transition: color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease);
  --input-caret-color: var(--color-tertiary-900);
  --input-focus-border-color: var(--color-primary-900);
  //--input-height: calc( 1lh + (var(--input-padding-block) * 2));
  --input-height: 5rem;


  --sticker-bg-color: var(--color-accent-04);
  --sticker-border-radius: 50%;
  --sticker-drop-shadow: var(--shadow-300);

  @include background-svg.to-custom-property(svg.$svg-select-chevron-down, colors.$color-grey-400, --input-select-arrow-grey);
  @include background-svg.to-custom-property(svg.$svg-select-chevron-down, colors.$color-error, --input-select-arrow-error);
  //@include background-svg.to-custom-property(svg.$svg-select-chevron-up, colors.$color-primary-900, --input-select-arrow-primary);
  //@include background-svg.to-custom-property(svg.$svg-select-chevron, colors.$color-white, --input-select-arrow-white);

  --input-select-padding-right: 4rem;
  --input-select-arrow-bg-position: right 1.5rem center;
  --input-select-arrow-bg: var(--input-select-arrow-grey);
  //--input-select-active-arrow-bg: var(--input-select-arrow-primary);

  @include background-svg.to-custom-property(svg.$svg-resize-handle, colors.$color-grey-400, --input-textarea-resize-handle);
  

  @include background-svg.to-custom-property(svg.$svg-date-picker, colors.$color-accent-03, --input-aside-date-picker);
  @include background-svg.to-custom-property(svg.$svg-time-picker, colors.$color-accent-03, --input-aside-time-picker);
  
  
  --input-aside-icon-padding-right: 4rem;
  --input-aside-icon-arrow-bg-position: right 1rem center;
  --input-aside-icon-bg-bg-size: 2.5rem 2.5rem;


  //mask
  @include background-svg.to-custom-property(svg.$svg-mask-slogan, colors.$color-black, --mask-slogan);
  @include background-svg.to-custom-property(svg.$svg-mask-clip, colors.$color-black, --mask-clip);
  @include background-svg.to-custom-property(svg.$svg-mask-gradient, colors.$color-black, --mask-gradient);


  --check-size: 3.5rem;
  --check-color: var(--color-primary-900);
  --check-border-width: .2rem;
  --check-bg-color: transparent;
  --check-transition: background-color var(--animation-duration) var(--animation-ease), border-color var(--animation-duration) var(--animation-ease), color var(--animation-duration) var(--animation-ease);
  --label-option-padding-left: calc(var(--check-size) + 1rem);
  --label-option-font-size: 1.6rem;
  //--check-bg-color: var(--color-primary-900);
  --check-checked-color: var(--color-primary-900);
  --check-hover-color: var(--color-tertiary-900);
  --check-checked-bg-color: currentColor;

  @include background-svg.to-custom-property(svg.$svg-check, colors.$color-white, --check-bg-image);
  @include background-svg.to-custom-property(svg.$svg-radio, colors.$color-white, --check-radio-bg-image);
  @include background-svg.to-custom-property(svg.$svg-plus, colors.$color-black, --faq-opener);
  @include background-svg.to-custom-property(svg.$svg-plus, colors.$color-tertiary-900, --faq-opener-hover);
  @include background-svg.to-custom-property(svg.$svg-loader, colors.$color-white, --video-loader);

  //topic check and radio
  --label-topic-option-bg-color: var(--color-primary-700);
  --label-topic-option-border-color: var(--color-accent-01);
  --label-topic-option-color: var(--color-accent-01);

  --check-topic-size: var(--check-size);
  --check-topic-color: currentColor;

  @include background-svg.to-custom-property(svg.$svg-topic-check, colors.$color-white, --check-topic-bg-image);
  @include background-svg.to-custom-property(svg.$svg-topic-radio, colors.$color-white, --check-topic-radio-bg-image);



  //gradient for teaser
  --gradient-start-color: var(--color-tertiary-900);
  --gradient-stop-color: var(--color-primary-900);

  --symbol-size: 10rem;

  --channel-accent-color: var(--color-accent-05);

  --teaser-tag-bg-color: var(--channel-accent-color);

  --spade-color: var(--color-accent-05);

  --footer-border-radius: 2rem;
  --footer-negative-radius-size: calc( var(--footer-border-radius) * -1 );


  --progress-bar-height: 1rem;
  --progress-bar-background: var(--color-gradient-100);
  --progress-background: var(--color-grey-100-transparent);

  // Logos
  --logos-list-gap: 1rem;

  // gradient
  --gradient-bg-start-color: #{colors.$color-primary-900};
  --gradient-bg-end-color: #{colors.$color-tertiary-900};

  @include mq.mq($from: medium) {
    // --wrapper-gap: 4rem;
    --gutter: 2rem;
    --wrapper-gap: 2.5rem;
    --header-outer-box-shadow: var(--shadow-100);

    // Images
    --media-grid-column-small: span 3;
    --media-grid-column-medium: span 6;
    --media-grid-column-large: span 6;

  }

  @include mq.mq($from: large) {

    //body 
    --body-font-size: 2rem;
    --body-line-height: 1.7;
    --body-font-weight: 400;
    --body-letter-spacing: normal;

    --bold-letter-spacing: 0.04rem;

    //small / body 2
    --small-font-size: 1.8rem;
    --small-line-height: 1.65;
    --small-letter-spacing: normal;

    //Subtitle 
    //no changes

    //header
    --font-size-h1: 6.5rem;
    --font-size-h2: 5rem;
    --font-size-h3: 3.6rem;
    --font-size-h4: 2.8rem;
    --font-size-h5: 2.5rem;
    --font-size-h6: 2.2rem;

    --line-height-h1: 1.2;
    --line-height-h2: 1.3;
    --line-height-h3: 1.4;
    --line-height-h4: 1.6;
    --line-height-h5: 1.5;
    --line-height-h6: 1.6;

    --letter-spacing-h1: normal;
    --letter-spacing-h2: normal;
    --letter-spacing-h3: normal;
    --letter-spacing-h4: normal;
    --letter-spacing-h5: normal;
    --letter-spacing-h6: normal;

    --list-margin-left: 3.8rem;
    --check-list-margin-left: 1.5rem;
    
    --container-pull-to-edge-margin-inline: auto;
    --container-block-space: 5rem;
    --gutter: 5rem;
    --section-spacing-xs: 7.5rem;
    --section-spacing-s: 10rem;
    --section-spacing-m: 15rem;
    --section-spacing-l: 20rem;

    --section-boxed-max-width: min( calc(100vw - 7rem) , 167rem);
    --section-boxed-border-radius: var(--rounded-l);
    --section-boxed-padding-block: 5rem;

    --spacing-media-caption: 1.5rem;

    --label-padding: 0 1rem 0;
    --field-instructions-padding: .5rem 1rem .5rem;
    --wrapper-gap: 5.5rem;
    --header-height: 13rem;
    --scroll-padding-top: 180px;
    --header-logo-max-height: 5.5rem;

    --header-with-slogan-height: var(--header-height);
    --header-with-slogan-logo-max-height: var(--header-logo-max-height);

    --card-icon-size: 5rem;
    --card-gap: 3rem;

    --footer-border-radius: 4rem;

    --progress-bar-height: 1.6rem;

    // Logos
    --logos-list-gap: 5rem;

    // Media grid
    --media-grid: var(--grid-gap);

    // Images
    --media-grid-column-small: span 2;
    --media-grid-column-medium: span 2;
    --media-grid-column-large: span 3
  }
}

$body-font-family: "TT Norms", Helvetica, Arial, sans-serif;

@media (prefers-reduced-motion: reduce) {
  :root {
    --animation-duration: 0;
    --animation-delay: 0;
    --animation-ease: none;
    --scroll-behavior: auto;
  }
}