@use "../mixins/mq";

.aside-box {
    --order-overview-heading-color: currentColor;
    --aside-box-bg-color: var(--color-accent-01);

    position: relative;
    display: block;
    background-color: var(--aside-box-bg-color);
    color: var(--color-white);
    padding: 1.5rem;
    border-radius: var(--rounded-l) 0 0 var(--rounded-l);
    min-width: min(38.5rem, calc(100vw - 6rem));
    will-change: transform;
    transition: transform var(--animation-duration) var(--animation-ease);
    transform: var(--aside-box-transform);

    max-width: 100%;

    &__tab {
        position: absolute;
        right: 100%;
        width: 5.5rem;
        aspect-ratio: 1/1;
        border-radius: var(--rounded-m) 0 0 var(--rounded-m);
        color: inherit;
        background-color: var(--aside-box-bg-color);
        display: var(--aside-box-tab-display, grid);
        align-items: center;
        justify-content: center;
        text-decoration: none;
    }

    &__badge {
        background-color: var(--color-tertiary-900);
        border-radius: 5rem;
        line-height: 1.5rem;
        font-size: 1.2rem;
        font-weight: 500;
        min-width: 1lh;
        display: inline-block;
        position: absolute;
        right: -.5lh;
        top: -.5lh;
    }

    &__content {
        position: relative;
    }

    @include mq.mq($from: medium) {

        padding: 2rem;
    }

    @include mq.mq($from: large) {
        --aside-box-tab-display: none;

    }


}