.masked-line {
    overflow: hidden;
    display: inline-grid;

    &__word {
        display: inline-block;
        transition: transform .5s;
        transition-timing-function: ease-in-out;
        transition-delay: var(--masked-line-translate-delay, 0s);
        transform: translateY( var(--masked-line-translate-y, 100%));
        will-change: transform;
    }

    .unmask & {
        --masked-line-translate-y: 0%
    }
}