@use "../mixins/mq";

.documents {
  --documents-grid-gap: 2.5rem var(--grid-gap);

  &__container {
    display: grid;
  }

  &__item { 
    margin: 0;
    padding: 0;
  }
  &__grid {
    gap: var(--documents-grid-gap);
    display: grid;
    margin-inline: 0;
    padding: 0;
    list-style: none;
  
  }
  &__list {
    
    --spacing-h3-bottom: 1.5rem; 
  }

  @include mq.mq($from: large) {
    --documents-grid-gap: var(--grid-gap);
    --spacing-list-bottom: 10rem;
    --spacing-h3-bottom: 2.5rem;
    

    &__grid {
      --spacing-list: 10rem;
      grid-template-columns: 1fr 1fr;
    }
  }
}
