@use "../mixins/mq";

.fullscreen {

	&__image {
		width: 100%;
	}

	&__cover {
		aspect-ratio: 5/8;

		@include mq.mq($from: medium) {
			aspect-ratio: 17/9;
		}
	}
}
