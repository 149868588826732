@use "../mixins/mq";

.nav--social-nav {
	--icon-width: 3rem;
	--icon-height: 3rem;

	.nav {
		&__wrap {
			display: flex;
			gap: 2rem;
			justify-content: flex-start;
			justify-content: space-around;
		
			@include mq.mq($from: medium) {
				gap: 2rem 5rem;
				justify-content: flex-start;
			}
		}
		&__link {
			display: grid;
		}

		&__item {
			flex-shrink: 0;
		}

		@include mq.mq($from: large) {
			--icon-width: 2.8rem;
			--icon-height: 2.8rem;
		}
	}
}
