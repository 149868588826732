@use "../mixins/mq";

.communication {
   
  &__element {
    --year-spacing-top: 5rem;
  }

  &__element--has-year {
    margin-top: var(--year-spacing-top);

    @include mq.mq($from: large) {
      --year-spacing-top: 9rem;
    }

    &:first-child {
      --year-spacing-top: 0;
    }
  }

  &__card {
    --img-bg-color: var(--color-skeleton);
    --spacing-padding-top: 2.5rem;
    --border-top: 1px solid var(--color-grey-100);
    --card-inner-padding-top: 2.5rem;
    --border-top-radius: 0;
    --border-bottom-radius: 0;

    border-top-left-radius: var(--border-top-radius);
    border-top-right-radius: var(--border-top-radius);

    border-bottom-left-radius: var(--border-bottom-radius);
    border-bottom-right-radius: var(--border-bottom-radius);

    padding: 0 2rem var(--spacing-padding-top);
    background-color: var(--color-white);

    .communication__element:has(+ .communication__element--has-year) &,
    .communication__element:last-of-type & {
      --spacing-padding-top: 5rem;
      --border-bottom-radius: var(--rounded-l);
    }

    .communication__element:first-of-type &,
    .communication__year + & {
      --border-top-radius: var(--rounded-l);
    }

  }

  &__figure {
    width: 15rem;
    margin-bottom: 1.5rem;
    grid-area: communicationImage;
    border-radius: var(--rounded-l);

    @include mq.mq($from: large) {
      width: 19.8rem;
    }
  }

 /* &__image {

  }*/

  &__info {
    grid-area: communicationText;
  }

  &__subtitle {
    font-weight: 500;
  }

  &__header {
    transition: color var(--animation-duration) var(--animation-ease);
  }

  &__inner {
    display: block;
    padding-top: var(--card-inner-padding-top);
    color: currentColor;
    text-decoration: none;
    border-top: var(--border-top);

    &:focus,
    &:hover {
      --color-h4: var(--color-tertiary-900);
      --img-transform: scale(1.025);
    }

    .communication__element:first-child &,
    .communication__year + .communication__card & {
      --border-top: 0;

      @include mq.mq($from: large) {
        --card-inner-padding-top: 5rem;
      }
    }

    @include mq.mq(medium) {
      display: grid;
      align-items: start;
      gap: 5rem;
      grid-template-columns: 1fr 15rem;
      grid-template-areas: "communicationText communicationImage";
    }

    @include mq.mq(large) {
      grid-template-columns: 1fr 19.8rem;
    }
  }

  &__year {
    margin-top: 0rem;
    margin-bottom: 2.5rem;

    @include mq.mq($from: large) {
      margin-top: 0rem;
      margin-bottom: 4rem;
    }
  }
}