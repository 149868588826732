@use "../mixins/mq";

.contributor-box {
    --symbol-border-radius: var(--rounded-s);
    --contributor-box-item-border-color: var(--color-grey-100);
    --contributor-box-role-color: var(--color-grey-300);
    --symbol-size: 8rem;
    position: relative;
    display: block;
    margin: 0;
    padding: 0;
    list-style: none;


    &--decoration {
        //for ewl only 
      
    }

    &__item {
        padding: 1rem 0;
        margin: 0;
        border-top: .1rem solid var(--contributor-box-item-border-color, currentColor);
        display: grid;
        gap: 1.5rem;
  
        &:first-child {
            border: none;
            padding-top: 0;
        }
        &:last-child {
            padding-bottom: 0;
        }
    }

    &__role {
        color: var(--contributor-box-role-color, currentColor);
        display: block;
    }

    &__name {
        display: block;
        margin-top: 0.5rem;
        font-weight: 700;
    }

    &__infos {
        margin-right: auto;
    }

    &__position {
        display: block;
        font-weight: 500;
    }

    &__section {
        display: flex;
        gap: 1rem;
        flex-direction: column;
     }


    @include mq.mq($from: medium) {
     
        &__section {
           gap: 1.5rem;
           flex-direction: row-reverse;
        }

        --contributor-box-item-border-color: currentColor;
        --contributor-box-role-color: currentColor;

        &--decoration {
            --contributor-box-item-border-color: var(--color-grey-100);
            --contributor-box-role-color: var(--color-grey-300);

            border-radius: var(--rounded-m);
            background-color: var(--color-white);
            padding: 1.5rem;
        }
    }
}