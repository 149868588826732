@use "../mixins/mq";
@use "../atoms/grid";

.cta {
	--container-position: relative;
	--cta-bg-color:  var(--color-primary-900);
	--cta-decoration-large-width: 34rem;
	--cta-with-sticker-margin-top: 10rem;
	background-color: var(--cta-bg-color);

	@include mq.mq($from: medium) {
		--cta-with-sticker-margin-top: 0;
	}
	

	&--has-decorated {
		--cta-body-margin-bottom: -5rem;
		--cta-sticker-right: -1.2rem;
	}

	&--has-sticker {
		--cta-body-margin-top: 2.4rem;
		margin-top: var(--cta-with-sticker-margin-top);
	}

	//animation
	&--has-animation {
		--cta-decoration-small-transform: scaleY(.1);
		--cta-decoration-large-transform: scaleX(.06);
		--cta-decoration-transition: transform 200ms 300ms ease-out;

		--cta-sticker-inner-animation: ctaSticker 3s;
	}
	// animation run
	&--animation-run {
		--cta-decoration-small-transform: scaleY(1);
		--cta-decoration-large-transform: scaleX(1);
		--cta-sticker-inner-animation-play-state: running;
	}

	&__content {
		--wrapper-position: static;
		position: relative;
		overflow: hidden;
	}

	&__sticker {
		position: absolute;
		top: 0;
		right: var(--cta-sticker-right, 0);
		z-index: 1;
		transform: translate(var(--wrapper-gap), -50%);
		max-width: var(--container-width);
		
		
	}	

	&__sticker-inner {
		animation: var(--cta-sticker-inner-animation, none);
		animation-play-state: var(--cta-sticker-inner-animation-play-state,paused);

	}

	&__body {
		margin-top: var(--cta-body-margin-top,0);
		margin-bottom: var(--cta-body-margin-bottom,0);
		padding: 5rem 2rem;
		color: var(--color-white);
		z-index: 2;
		position: relative;
	}

	&__aside {
		//margin: 0 var(--wrapper-gap-negative);
	}

	&__cover {
		--img-bg-color: var(--color-skeleton);
		width: 100%;
		aspect-ratio: 47/40;

	}

	&__decoration {

		display: block;
		width: 100%;
		position: absolute;
		
		aspect-ratio: 375/81;
		max-width: none;
		height: auto;
		color: var(--cta-bg-color);
		margin: -.2rem 0 0 0;
		max-height: 15rem;
		z-index: 1;
		transition: var(--cta-decoration-transition);
		will-change: transform;

		&--small {
		
			//animation
			transform-origin: top;
			transform: var(--cta-decoration-small-transform, none);	

		}

		&--large {
			display: none;
			aspect-ratio: 323/650;
			height: 100%;
			
			width: auto;
			left: calc(50% - var(--cta-decoration-large-width));
			margin: 0;
			max-height: none;
			max-width: var(--cta-decoration-large-width);

			//animation
			transform-origin: left;
			transform: var(--cta-decoration-large-transform, none);	
		}
	}


	@include mq.mq($from: medium) {
	
		&--has-decorated {
			--cta-sticker-right: 0;
		}

		&__body {
			padding: 7.6rem 0;
		}

		&__cover {
			aspect-ratio: 83/60;
			
		}
	}
	
	@include mq.mq($from: large) {


		&__sticker {
			transform: translate(-2rem, -44%);
		}
	
		&__content {
			min-height: 65rem;
			display: flex;
			align-items: center;
		}

		&--has-decorated {
			--cta-cover-width: calc( 50% + var(--cta-decoration-large-width));
		}

		&__body {
			
			margin: 0 auto;

			&--half {
				width: 40%;
				position: relative;
				margin: 0;
				z-index: 2;
			}
		}

		&__cover {
			position: absolute;
			right: 0;
			top: 0;
			left: auto;
			bottom: 0;
			aspect-ratio: auto;
			width: var(--cta-cover-width,50%);
			height: 100%;
		}

		&__decoration {
			display: none;
	
			&--large {
				display: block;
			}
		}

	}
}



@keyframes ctaSticker {
    0%,40% {
		opacity: 0;
		rotate: 5deg;
    }
    60% {
		opacity: 1;
    }
	80% {
		rotate: -5deg;
	}
	100% {
		rotate: 0deg;
	}
}