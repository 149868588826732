@use "../mixins/mq";
@use "../_config/colors";

.disturbances {
  --cell-padding-block: 1.5rem;
  --cell-background: var(--dashed-line) 0 bottom repeat-x;
  --cell-background-size: 6px 1px;
  --cell-background-before-last: var(--cell-background);
  --cell-status-position: absolute;
  --cell-status-right: 2.2rem;
  --cell-status-top: 2.7rem;
  --disturbances-grid-columns: 1fr;
  --disturbances-grid-gap: 0;
  --disturbances-grid-margin-bottom: 2.5rem;
  --disturbances-grid-padding: 0.7rem 2.5rem;
  --disturbances-grid-border-radius: var(--rounded-m);


  &__tag {
    --spacing-text-top: 0;
    --spacing-text-bottom: 0.8rem;
    display: var(--disturbances-tag-display, block);
  }

  @include mq.mq($from: large) {
    --disturbances-grid-columns: 8fr 8fr 15fr;
    --disturbances-grid-gap: 0 4rem;
    --disturbances-grid-margin-bottom: 1rem;
    --disturbances-tag-display: none;
    --cell-status-position: relative;
    --cell-status-right: 0;
    --cell-status-top: 0;
  }

  &__grid {
    display: grid;
    padding: var(--disturbances-grid-padding);
    margin-bottom: var(--disturbances-grid-margin-bottom);
    grid-template-columns: var(--disturbances-grid-columns);
    grid-gap: var(--disturbances-grid-gap);
    border-radius: var(--disturbances-grid-border-radius);

    &--interruptions {
      --cell-background-before-last: none;

      @include mq.mq($from: large) {
        --disturbances-grid-columns: 8fr 8fr 10fr 16rem;
      }
    }
  }

  &__header {
    display: none;
    font-size: 1.8rem;
    font-weight: 700;
    background-color: #{colors.$color-accent-02}20;
    padding-block: 0;

    --cell-padding-block: 1rem;

    @include mq.mq($from: large) {
      display: grid;
    }
  }

  &__row {
    position: relative;
  }

  &__cell {

    padding-block: var(--cell-padding-block);
    background: var(--cell-background);
    background-size: var(--cell-background-size);

    @include mq.mq($from: large) {
      --cell-background: none;
      --cell-background-before-last: none
    }

    &:nth-last-child(2) {
      background: var(--cell-background-before-last);
      background-size: var(--cell-background-size);
    }

    &:last-child {
      --cell-background: none;
    }

    &--status {
      --cell-padding-block: 0;

      @include mq.mq($from: large) {
        --cell-padding-block: 1.5rem;
      }


    }
  }

  &__title {
    --spacing-text-top: 0;
    --spacing-text-bottom: 0.5rem;
  }

  &__row {
    padding: 0.7rem 2.5rem;
    background-color: var(--color-white);
  }

  .status {
    position: var(--cell-status-position);
    top: var(--cell-status-top);
    right: var(--cell-status-right);
  }
}