@use "../mixins/mq";

.job-card {
  display: grid;
  grid-template-columns: 7.5rem 1fr 2rem;
  align-items: center;
  gap: 1rem;
  padding: 2.5rem 0;
  color: var(--color-primary-900);
  border-bottom: 2px solid var(--color-grey-100);
  text-decoration: none;

  @include mq.mq($from: large) {
    grid-template-columns: 13rem 1fr 3rem;
    gap: 3.5rem;
  }


  &:first-child {
    border-top: 2px solid var(--color-grey-100);
  }

  &__title {
    font-size: 1.8rem;
    --spacing-h5-bottom: .75rem;
    word-break: auto-phrase;

    @include mq.mq($from: large) {
      font-size: 2.5rem;
    }
  }

  &__icon {
    width: 2rem;
  }

  &__info {
    font-weight: 500;
    font-size: 1.4rem;
  }

  &__figure {
    background-color: var(--color-skeleton);
    aspect-ratio: 1/1;
    border-radius: var(--rounded-l);
  }

  &:hover {
    color: var(--color-tertiary-900);
  }
}