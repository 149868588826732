
.tippy-box[data-animation=fade][data-state=hidden] {
    opacity: 0
}

[data-tippy-root] {
    max-width: calc(100vw - 10px)
}

.tippy-box {
    outline: 0;
    transition-property: transform, visibility, opacity
}
/*
.tippy-box[data-placement^=top]>.tippy-arrow {
    bottom: 0
}

.tippy-box[data-placement^=top]>.tippy-arrow:before {
    bottom: .5rem;
    left: 0;
    transform-origin: center top
}

.tippy-box[data-placement^=bottom]>.tippy-arrow {
    top: 0
}

.tippy-box[data-placement^=bottom]>.tippy-arrow:before {
    top: .5rem;
    left: 0;
    border-width: 0 8px 8px;
}

.tippy-box[data-placement^=left]>.tippy-arrow {
    right: 0
}

.tippy-box[data-placement^=left]>.tippy-arrow:before {
    right: .5rem;
    transform-origin: center left
}

.tippy-box[data-placement^=right]>.tippy-arrow {
    left: 0
}

.tippy-box[data-placement^=right]>.tippy-arrow:before {
    left: .5rem;
    transform-origin: center right
}
*/

.tippy-box[data-inertia][data-state=visible] {
    transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11)
}

.tippy-content {
    position: relative;
    z-index: 1
}
