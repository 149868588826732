.symbol {
    --img-bg-color: var(--color-skeleton);
    --icon-width: 5.5rem;
    --icon-height: 5.5rem;

    display: grid;
    color: var(--symbol-color,currentColor);
    background-color: var(--color-secondary-900);
    border-radius: var(--symbol-border-radius,50%);
    overflow: hidden;
    width: var(--symbol-size);
    min-width: var(--symbol-size);
    margin-bottom: auto;
    aspect-ratio: 1/1;
    position: relative;
    align-items: center;
    justify-content: center;
    align-content: center;
    
}