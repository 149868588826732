.visibility-toggle {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    transition: color var(--animation-duration) var(--animation-ease);
    width: 100%;

    &:hover {
        color: var(--color-tertiary-900);
        --visibility-toggle-color: currentColor;
    }

    &:has(:checked) {
        --icon-on-color: transparent;
        --icon-off-color: currentColor;
        --visibility-toggle-label-opacity: .8;
        --visibility-toggle-label-color: var(--color-black-transparent);
    }

    &__control {
        color: var(--visibility-toggle-color, var(--color-primary-900));
        width: 2rem;
        aspect-ratio: 1/1;
        position: relative;
        margin-left: auto;
    }

    &__checkbox,
    &__icon {
        position: absolute;
        inset: 0;
        width: auto;
        height: auto;
    }

    &__checkbox {
        appearance: none;
 
    }


    &__label {
        cursor: pointer;
        opacity: var(--visibility-toggle-label-opacity, 1);
    }


    &__icon {
        &--on {
            color: var(--icon-on-color);
        }

        &--off {
            color: var(--icon-off-color, transparent);
        }

    }

}