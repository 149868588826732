.share {
    display: inline-flex;
    flex-direction: var(--share-flex-direction);
    gap: 2rem 3.4rem;
    list-style: none;
    margin: 0;
    padding: 0;

    &__item {
        margin: 0;
        padding: 0;
    }

    &__button {
        display: grid;
        color: currentColor;
        width: var(--share-button-size, 3rem);
        padding: var(--share-button-padding, 0.75rem);
        aspect-ratio: 1/1;
        background-color: transparent;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
        transition: background-color var(--animation-duration) var(--animation-ease);

        &:hover,
        &:focus {
            color: var(--color-white);
            background-color: var(--color-accent-01);
        }
    }
    &__icon {
        --icon-width: 100%;
        --icon-height: 100%;
        
    }
}