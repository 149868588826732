@use "../mixins/mq";
@use "../mixins/col";
@use "../_base/typography";
@use "../atoms/action";


.footer {

    --container-width: 131rem;

    //add logic
    //margin-top: var(--footer-negative-margin-top);
    position: relative;
    z-index: 1;

    &__content {
        border-radius: var(--footer-border-radius) var(--footer-border-radius) 0 0;
        background-color: var(--color-accent-01);
        color: var(--color-white);
        padding: 5rem 0;
    }

    &__grid {
        display: grid;
        gap: 3.5rem;

        &--bottom {
            gap: 3rem 0;
        }
    }

    &__address {
        margin-top: 1.5rem;
        font-weight: bold;
        font-family: var(--font-family-secondary);
        font-size: 2.2rem;
        line-height: 1.6;
    }

    &__link {
        --link-text-decoration: none;
        --link-text-decoration-active: underline;
        display: inline-block;
    }

    &__copyright {
        white-space: nowrap;

    }

    &__slogan {
        margin-top: 4.5rem;
        margin-bottom: -8.5rem;

        filter: drop-shadow(var(--shadow-100));

        &-mask {
            --icon-width: 14rem;
            height: 19rem;
            width: 21.5rem;
            padding-right: 2.5rem;
            justify-content: center;
            align-content: center;
            display: grid;
            background: var(--color-white);
            -webkit-mask-image: var(--mask-slogan);
            mask-image: var(--mask-slogan);
            -webkit-mask-size: cover;
            mask-size: cover;
        }


    }


    &__col {
        &--social {
            padding: 2.5rem 0;
            border-bottom: .1rem solid var(--color-grey-100);
        }

    }

    &__bottom {
        color: var(--color-accent-01);
        --link-color: currentColor;
        --link-text-decoration: none;
        font-size: var(--subtitle-font-size);
        line-height: var(--subtitle-line-height);
        letter-spacing: var(--subtitle-letter-spacing);
        font-weight: bold;
        padding-bottom: 2.5rem;
        background-color: var(--color-white);
        box-shadow: var(--shadow-200);
        position: relative;
    }

    &__copyright {
        margin-bottom: 2.5rem;
    }

    @include mq.mq($from: medium) {

        &__grid {
            &--content {
                gap: 4rem 8rem;
                grid-template-columns: 4fr 5fr;
                grid-template-rows: auto 1fr;
                grid-template-areas:
                    "col col-nav"
                    "col col-nav";
            }
            &--bottom {
                gap: 2.5rem 0;
            }
        }

        &__address {
            margin-top: 0;
        }

        &__col {
            &--nav {
                grid-area: col-nav;
            }
        }

        &__meta {
            margin-top: -.5rem;
        }

        &__slogan {
            margin-top: 5.6rem;

            &-mask {
                --icon-width: 15rem;
                height: 22rem;
                width: 26.5rem;
                padding: 1rem 3rem 0 0;
               
            }
        }

    }

    @include mq.mq($from: large) {
       

        //h4 as h3 for large
        font-size: var(--font-size-h3);
        line-height: var(--line-height-h3);
        letter-spacing: var(--letter-spacing-h3);

        &__slogan {
            margin-top: -10rem;
            position: absolute;
            &-mask {
                --icon-width: 17.5rem;
                height: 22rem;
                width: 30rem;
                padding: 4.5rem 4.5rem 0 0;
            }
        }

        &__content {
            padding: 9rem 0 12rem;
        }

        &__meta {
            margin-top: 1.5rem;
            font-size: var(--font-size-h3);
            line-height: var(--line-height-h3);
            letter-spacing: var(--letter-spacing-h3);
        }

        &__grid {
            &--content {
                gap: 2.5rem 11%;
                grid-template-columns: 5fr 4fr;
            
            }
            &--bottom {
                grid-template-columns: 1fr auto;
                align-items: center;
            }
        }

        &__address {
            &--save-space-for-decoration {
                margin-bottom: 10rem;
            }
        }


        &__copyright {
            display: inline-block;
            margin-bottom: 0;
            &:after {
                content: "|";
                padding: 0 .5ch;
            }
        }

        &__bottom {
            padding: 3.1rem 0;
        }

        &__last-line {
            display: inline-flex;
            flex-wrap: wrap;
            gap: 1.5rem 0;
        }

        &__col {
            &--social {
                padding: 0;
                order: 2;
                border-bottom: none;
            }
        }

    }


}