@use "../mixins/mq";

.contact {
    --spacing-contact-block: 2.5rem;
    --spacing-contact-top: var(--spacing-contact-block);
    --spacing-contact-bottom: var(--spacing-contact-block);
    display: flex;
    gap: 1.5rem;
    font-size: 1.6rem;
    font-weight: 500;
    align-items: center;

    margin-block: var(--spacing-contact-top) var(--spacing-contact-bottom);

    &:first-child {
        --spacing-contact-top: 0;
    }

    &:last-child {
        --spacing-contact-bottom: 0;
    }

    &__name {
        letter-spacing: 0.032rem;
    }

    @include mq.mq($from: medium) {
        --spacing-contact-block: 5rem;
        gap: 2.4rem;
    }
}