@use "../mixins/mq";

.offer {
    --spacing-action-bottom: 1.5rem;
    --spacing-action-top: 1.5rem;
    --action-min-width: 100%;
    --offer-body-bg-color: var(--color-tertiary-900);
    --offer-symbol-size: 10rem;
    --offer-symbol-size-half: calc(var(--offer-symbol-size) / 2);
    --offer-cost-color: var(--color-primary-900);
    --offer-bg-color: var(--color-primary-700);
    --offer-border-bottom: 1px solid var(--color-primary-900);
    --element-border-radius: var(--rounded-l) var(--rounded-l) 0 0;

    --transform: unset;
    --transform-hover: unset;
    --transform-hover-default: translateY(-16px) translateZ(0) scale(1.05);

    transition: transform var(--animation-duration) var(--animation-ease);
    will-change: transform;

    &:hover,
    &:focus {
        --transform-hover: var(--transform-hover-default);
    }

    &--has-subtitle {
        --element-border-radius: 0;
    }

    position: relative;
    margin-top: var(--offer-symbol-size-half);
    margin-bottom: 5rem;
    transform: var(--transform);

    width: 100%;
    max-width: 38rem;

    display: grid;
    grid-template-rows: var(--offer-grid-template-rows);
    grid-row: auto / span 5;

    padding: 0 1.5rem 2.5rem;
    color: var(--offer-color, currentColor);
    flex-direction: column;
    //align-items: center;
    text-align: center;
    background-color: var(--offer-bg-color);
    border: .1rem solid var(--color-tertiary-900);
    border-radius: var(--rounded-xl);
    border-bottom: var(--offer-border-bottom);
    box-shadow: var(--shadow-300);
    gap: 0;
    grid-column-end: var(--offer-grid-column-end);
    grid-column-start: var(--offer-grid-column-start);

    &:nth-last-child(2),
    &:last-child {
        @include mq.mq($from: medium, $until: offersMedium) {
            .offers__grid--odd & {
                --transform: translateX(calc(50% + #{var(--grid-gap)}/2));

                &:hover,
                &:focus {
                    --transform-hover: translateX(calc(50% + #{var(--grid-gap)}/2)) translateY(-16px) translateZ(0) scale(1.05);
                }
            }
        }
    }

    &:last-child {
        @include mq.mq($from: medium, $until: offersLarge) {
            .internet-availability-check__offers.offers__grid--odd & {
                --transform: translateX(calc(50% + #{var(--grid-gap)}/2));

                &:hover,
                &:focus {
                    --transform-hover: translateX(calc(50% + #{var(--grid-gap)}/2)) translateY(-16px) translateZ(0) scale(1.05);
                }
            }
        }
    }

    &:nth-last-child(2) {
        @include mq.mq($from: medium) {
            --transform: unset;

            &:hover,
            &:focus {
                --transform-hover: var(--transform-hover-default);
            }

            .offers__grid--odd &,
            .offers__grid--three & {
                --transform: unset;

                &:hover,
                &:focus {
                    --transform-hover: var(--transform-hover-default);
                }
            }
        }
    }

    &:nth-last-child(2),
    &:last-child {
        @include mq.mq($from: offersLarge) {
            .offers__grid--odd & {
                --transform: translateX(calc(50% + #{var(--grid-gap)}/2));

                &:hover,
                &:focus {
                    --transform-hover: translateX(calc(50% + #{var(--grid-gap)}/2)) translateY(-16px) translateZ(0) scale(1.05);
                }
            }

            .offers__grid--three & {
                --transform: unset;

                &:hover,
                &:focus {
                    --transform-hover: translateY(-16px) translateZ(0) scale(1.05);
                }
            }
        }
    }

    /*&:nth-last-child(2) {
        @include mq.mq($from: 1350px) {
            .offers__grid--odd & {
                --transform: none;
            }
        }
    }*/


    &__grid {
        /*isolation: isolate;
        position: relative;
        display: flex;
        min-height: calc( 100% - var(--offer-symbol-size-half) );
        padding: 0 1.5rem 2.5rem;
        color: var(--offer-color, currentColor);
        flex-direction: column;
        //align-items: center;
        text-align: center;
        background-color: var(--offer-bg-color);
        border: .1rem solid var(--color-tertiary-900);
        border-radius: var(--rounded-xl);
        border-bottom: var(--offer-border-bottom);
        box-shadow: var(--shadow-300);
        transform: var(--transform-inner);
        transition: transform var(--animation-duration) var(--animation-ease);
        will-change: transform;*/
    }

    &.inverted {
        --offer-body-bg-color: var(--color-white);
        --offer-cost-color: currentColor;
        --offer-color: var(--color-white);
        --offer-bg-color: var(--color-primary-900);
        --offer-border-bottom: 3px solid var(--color-primary-900);
    }

    &--has-subtitle {
        --offer-body-padding: 4.5rem 1.5rem 1.5rem;
        
        //height: calc(100% + 20px);
        margin-top: 3.2rem;
    }

    &__symbol {

        --icon-width: 6rem;
        --icon-height: 6rem;

        background-color: var(--color-white);
        border-radius: 50%;
        box-shadow: var(--shadow-300);
        width: var(--offer-symbol-size);
        aspect-ratio: 1/1;
        display: grid;
        align-items: center;
        justify-content: center;
        margin-inline: auto;
        margin-top: calc(var(--offer-symbol-size-half) * -1);
    }



    &__title {
        margin: 1rem 0;

        &-wrap {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            &--no-subtitle {
                padding-top: 3rem;
                align-self: center;
            }

            &--has-subtitle {
                padding-bottom: 3rem;
            }
        }
    }

    &__dividing-rule {
        border: none;
        height: .1rem;
        background: var(--dashed-line) 0 0 repeat-x;
        background-size: 6px 1px;
        margin: 0 0 1.5rem;
    }

    &__bottom {
        position: relative;
        padding: 0 1.6rem 1.5rem;
        border-radius: 0 0 var(--element-border-radius);
        overflow: hidden;
        border-radius: 0 0 var(--rounded-l) var(--rounded-l);

        &:after {
            content: "";
            position: absolute;
            background-color: var(--offer-body-bg-color);
            display: block;
            inset: 0;
            opacity: .1;
            z-index: 0;
        }
    }

    &__top {
        position: relative;
        padding: 1.5rem 1.6rem 2.5rem;
        border-radius: var(--element-border-radius);
        overflow: hidden;

        &:after {
            content: "";
            position: absolute;
            background-color: var(--offer-body-bg-color);

            display: block;
            inset: 0;
            opacity: .1;
            z-index: 0;
        }
    }

    &__body {
        display: grid;
        grid-template-rows: subgrid;
        grid-row: 3;
        text-align: left;
        position: relative;
        padding: var(--offer-body-padding, 1.5rem);

        border-radius: var(--rounded-l);
        overflow: hidden;


        &:after {
            content: "";
            position: absolute;
            background-color: var(--offer-body-bg-color);

            z-index: -1;
            display: block;
            inset: 0;
            opacity: .1;
        }
    }

    &__subtitle {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background-color: var(--color-black);
        display: block;
        margin: 0;
        text-align: center;
        line-height: 1;
        padding: 0.8rem 1.5rem;
        font-weight: 500;
        color: var(--color-white);
        border-radius: var(--rounded-l) var(--rounded-l) 0 0;

    }

    //body content

    &__cost {
        color: var(--offer-cost-color);
        text-align: center;
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: end;
        justify-content: center;
        vertical-align: baseline;
        gap: 1rem;
        white-space: nowrap;
        margin-bottom: 1.5rem;

    }

    &__price {
        display: inline;
        margin: 0;
        // font-size: 5rem;
        line-height: 1;
        white-space: nowrap;
    }

    &__price-suffix {
        margin-right: auto;
        font-weight: 500;
    }

    &__price-prefix {
        margin-left: auto;
        font-weight: 500;
    }

    &__benefits {
        --list-margin-left: 0;
        --list-item-min-height: 7rem;
        --list-item-padding-vertical: 0.5rem;
        --spacing-list-top: 0;
        --spacing-list-bottom: 1.5rem;
        text-align: left;


        &:empty {
            margin: 0;
        }

        &-item {
            display: flex;
            line-height: 1.3;
            align-items: center;
        }


    }

    &__disclaimer {
        align-self: self-end;
        text-align: left;
        font-weight: 500;
    }

    &__footer {
        font-size: 1.8rem;
        font-weight: 500;

        padding-top: 2.5rem;

        &:not(:empty) {
            min-height: 6.5rem;
        }
    }

    @include mq.mq($from: medium) {
        --offer-symbol-size: 12.5rem;

        &__symbol {
            --icon-width: 8rem;
            --icon-height: 8rem;

        }
    }

    &:focus,
    &:hover {
        transform: var(--transform-hover);
    }
}