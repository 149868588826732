@use "../mixins/mq";

.text-image {
	--spacing-media-caption-padding-inline: var(--wrapper-gap);
	--spacing-media-caption-display: block;

	display: grid;
	gap: 2.6rem 5rem;
	align-items: center;
	
	&__icon {
	
		margin-bottom: 1.5rem;
	}

	&__content {
		min-width: 0;
	}

	&__aside {
		margin-inline: var(--wrapper-gap-negative);
		align-self: start;
	}

	&__media {
		--img-bg-color: var(--color-skeleton);
		width: 100%;
	}

	@include mq.mq($from: medium) {
		--spacing-media-caption-padding-inline: 0;
		
		--img-border-radius: var(--rounded-l);

		grid-template-columns: var(--text-image-grid-template-columns, 7fr 5fr);

		&--reversed {
			--text-image-aside-margin-inline: 0 -3.6rem;
			--text-image-aside-order: 1;
			--text-image-grid-template-columns: 5fr 7fr;
		}

		&__aside {
			order: var(--text-image-aside-order, 0);
			margin: 0;
		}
	}

	@include mq.mq($from: large) {
		&__aside {
			margin-inline: var(--text-image-aside-margin-inline,-3.6rem 0);
		}
	}
}

