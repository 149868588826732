@use "../mixins/mq";
@use "../_base/typography";

.nav--footer-nav {
	display: inline-block;

	.nav {
		&__wrap {
			display: flex;
			gap: 2.5rem;
			flex-direction: column;
		}


		@include mq.mq($from: medium) {
			&__wrap {
				flex-direction: row;
				flex-wrap: wrap;
				gap: 0;
			}

			&__item {
				&:not(:last-child):after {
					content: "|";
					padding: 0 .5ch;
				}

			}

		}
		
	}
}